import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NameValueDto, PagedResultDtoOfNameValueDto, ProductCategoryServiceProxy, VendorPricePointServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { Observable, fromEvent } from 'rxjs';
import { merge as _merge } from 'lodash-es';
import { debounceTime, finalize } from 'rxjs/operators';
import * as _ from 'lodash'

export interface ICommonLookupModalOptions {
    title?: string;
    isFilterEnabled?: boolean;
    dataSource: (skipCount: number, maxResultCount: number, filter: string, tenantId?: number) => Observable<PagedResultDtoOfNameValueDto>;
    canSelect?: (item: NameValueDto) => boolean | Observable<boolean>;
    loadOnStartup?: boolean;
    pageSize?: number;
    AMPLookupOptions?: IAMPLookupOptions<any>; ///MODIFICACION AMP PARA HABILITAR BOTON DE GUARDADO ACTUALMENTE PARA VENDORPRICEPOINT
    pricePointModal?: boolean,
    revenueModal?: boolean,
}

///MODIFICACION AMP PARA HABILITAR BOTON DE GUARDADO ACTUALMENTE PARA VENDORPRICEPOINT
export interface IAMPLookupOptions<T> {
    withSave: boolean;
    params: T;
    entity?: string
}
///MODIFICACION AMP PARA HABILITAR BOTON DE GUARDADO ACTUALMENTE PARA VENDORPRICEPOINT
//For more modal options http://valor-software.com/ngx-bootstrap/#/modals#modal-directive

@Component({
    selector: 'commonLookupModal',
    templateUrl: './common-lookup-modal.component.html'
})
export class CommonLookupModalComponent extends AppComponentBase implements AfterViewInit {

    static defaultOptions: ICommonLookupModalOptions = {
        dataSource: undefined,
        canSelect: () => true,
        loadOnStartup: true,
        isFilterEnabled: true,
        pageSize: AppConsts.grid.defaultPageSize,
        AMPLookupOptions: undefined,
        pricePointModal: false

    };

    @Input() customTop = '';

    @Output('on-close') onClose: EventEmitter<boolean> = new EventEmitter<any>(false);
    @Output() itemSelected: EventEmitter<NameValueDto> = new EventEmitter<NameValueDto>();


    @ViewChild('modal', { static: true }) modal: ModalDirective;
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;source: any;
    @ViewChild("filterTextInput") filterTextInput:ElementRef; 

    options: ICommonLookupModalOptions = _merge({});

    isShown = false;
    isInitialized = false;
    filterText = '';
    excludeCurrentUser = false;
    tenantId?: number;



    constructor(
        injector: Injector,
        private _vendorPricePointsServiceProxy: VendorPricePointServiceProxy,///MODIFICACION AMP PARA HABILITAR BOTON DE GUARDADO ACTUALMENTE PARA VENDORPRICEPOINT
        private _productsCategoryServiceProxy: ProductCategoryServiceProxy  ///MODIFICACION AMP PARA HABILITAR BOTON DE GUARDADO ACTUALMENTE PARA PRODUCTcATEGORY
    ) {
        super(injector);

    }
    configure(options: ICommonLookupModalOptions): void {
        this.options = _merge({}, CommonLookupModalComponent.defaultOptions, { title: this.l('SelectAnItem') }, options);
    }

    ngAfterViewInit() {
        if(this.filterTextInput){
            this.source = fromEvent(this.filterTextInput.nativeElement, 'keyup');
            this.source.pipe(debounceTime(1500)).subscribe(c => this.refreshTable());
        }
    }

    show(): void {
        if (!this.options) {
            throw Error('Should call CommonLookupModalComponent.configure once before CommonLookupModalComponent.show!');
        }

        this.modal.show();
    }

    refreshTable(): void {
        this.paginator.changePage(this.paginator.getPage());
    }

    close(): void {
        this.modal.hide();
        this.modal.onHide.emit(this.modal);
        // this.onClose.emit();
    }

    hide(){
        this.onClose.emit();
    }

    shown(): void {
        this.isShown = true;
        this.getRecordsIfNeeds(null);
    }

    getRecordsIfNeeds(event?: LazyLoadEvent): void {
        if (!this.isShown) {
            return;
        }

        if (!this.options.loadOnStartup && !this.isInitialized) {
            return;
        }

        this.getRecords(event);
        this.isInitialized = true;
    }

    getRecords(event?: LazyLoadEvent): void {
        const maxResultCount = this.primengTableHelper.getMaxResultCount(this.paginator, event);
        const skipCount = this.primengTableHelper.getSkipCount(this.paginator, event);
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }
        

        this.primengTableHelper.isLoading = true;
        this.options
        .dataSource(skipCount, maxResultCount, this.filterText, this.tenantId)
        .pipe(finalize(() => this.primengTableHelper.isLoading = false))
        .subscribe(result => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            if (this.options.revenueModal) {
                var items: any[] = result.items;

                this.primengTableHelper.records = items;
            } else {
                this.primengTableHelper.records = result.items;
            }
                ///MODIFICACION AMP PARA HABILITAR BOTON DE GUARDADO ACTUALMENTE PARA VENDORPRICEPOINT
                if (this.options.AMPLookupOptions) {
                    this.showSaveBtn(result.items);
                }
                ///MODIFICACION AMP PARA HABILITAR BOTON DE GUARDADO ACTUALMENTE PARA VENDORPRICEPOINT

            });
    }


    selectItem(item: NameValueDto) {
        const boolOrPromise = this.options.canSelect(item);
        if (!boolOrPromise) {
            return;
        }

        if (boolOrPromise === true) {
            this.itemSelected.emit(item);
            this.close();
            return;
        }

        //assume as observable
        (boolOrPromise as Observable<boolean>)
            .subscribe(result => {
                if (result) {
                    this.itemSelected.emit(item);
                    this.close();
                }
            });
    }

    ///MODIFICACION AMP PARA HABILITAR BOTON DE GUARDADO ACTUALMENTE PARA VENDORPRICEPOINT



    showAddBtn: boolean = false;
    showSaveBtn(items: Array<any>): void {


        if (this.filterText == "" || items.map(i => i.name).includes(this.filterText)) {
            this.showAddBtn = false;
            return;
        }
        this.showAddBtn = true;


    }

    createEntity(){
        if (this.options.AMPLookupOptions.entity == 'productCategory') {
            this.createProductCategory();
        } else {
            this.createVendorPricePoint();
        }
    }

    createProductCategory(){
        let input = this.options.AMPLookupOptions.params;
        input.name = this.filterText;
        let existPricePoint = _.findIndex(this.primengTableHelper.records, (producCategory) => producCategory.name == input.name);
        if (existPricePoint != -1) {
            this.message.error(`${this.l('Category')} ${this.l('AlreadyExist')}`)
        } else {
            // this.showMainSpinner();
            this._productsCategoryServiceProxy.createOrUpdateProductCategory(input).subscribe(data => {
                this.message.success(`${this.l('Category')} ${this.l("CreatedSuccessfully")}`);
                this.showAddBtn = false;

                this.refreshTable();
            }, error => {
                console.log(error);
                // this.hideMainSpinner();
            })
        }

    }
    createVendorPricePoint() {
        let input = this.options.AMPLookupOptions.params;
        input.name = this.filterText;
        let existPricePoint = _.findIndex(this.primengTableHelper.records, (pricepoint) => pricepoint.name == input.name);
        if (existPricePoint != -1) {
            this.message.error(this.l('PricePointAlreadyExist'))
        } else {
            // this.showMainSpinner();
            this._vendorPricePointsServiceProxy.createVendorPricePoint(input).subscribe(data => {
                this.message.success(this.l(`PricePointCreatedSuccessfully`));
                this.showAddBtn = false;

                this.refreshTable();
            }, error => {
                // this.hideMainSpinner();
            })
        }
    }
}


import { Component } from '@angular/core';
import { AmpGlobalSpinnerService } from '@app/shared/amp-global-spinner.service';

@Component({
    selector: 'app-root',
    template: `
        <amp-new-version></amp-new-version>
        <router-outlet></router-outlet>
        <ng-container *ngIf="ampSpinnerService.customIsActive$ | async">
            <amp-global-spinner></amp-global-spinner>
        </ng-container>
        <ng-container *ngIf="ampSpinnerService.normalIsActive$ | async">
            <amp-spinner [global]="true" [text]="ampSpinnerService.normalTextSpinner$ | async| localize"></amp-spinner>
        </ng-container>
    `
})
export class RootComponent {
    constructor(public ampSpinnerService: AmpGlobalSpinnerService) { }
}
import { Directive, Input, HostBinding, OnInit } from '@angular/core'
import { SettingService } from 'abp-ng2-module';
import { AmpBaseUrls } from '@shared/AppBaseUrls';
@Directive({
    selector: 'img[default]',
    host: {
        '(error)': 'updateUrl()',
        '(load)': 'load()',
        '[src]': 'src'
    }
})

export class ImagePreloadDirective {
    @Input() src: string;
    @Input() default: string;    
    @Input('product-id') productId: string | undefined;      
    @HostBinding('class') className

    setting:SettingService;
    ampBaseUrls;
    constructor(){
        this.ampBaseUrls = new AmpBaseUrls();
    }
    
    updateUrl() {      
        if(this.productId != undefined)
        {
            this.src =  `${this.ampBaseUrls.urls.bucket}product/${this.productId}/images/logo.png`;
           
            this.productId = undefined
        }else{
            this.src = this.default;        
        }
    }

    load() {
        this.className = 'image-loaded';
    }
}
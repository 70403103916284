import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-filter-date-range-picker',
  templateUrl: './filter-date-range-picker.component.html',
  styleUrls: ['./filter-date-range-picker.component.css']
})
export class FilterDateRangePickerComponent extends AppComponentBase {

  date: Date;
  selectedDateRange: DateTime[] = [DateTime.local().minus({days: 7}).startOf('day'), DateTime.local().endOf('day')];
  constructor(injector: Injector) {
    super(injector);
  }

  onChange() {
    abp.event.trigger('app.dashboardFilters.dateRangePicker.onDateChange', this.selectedDateRange);
  }
}

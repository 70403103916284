import { Component, Injector, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';

import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import { Location } from '@angular/common';

@Component({
    selector:'amp-back-btn',
    template: `
    <div class="amp-back-btn-container">
        <div class="awg-back-arrow" (click)="goBack()">
            <i class="fas fa-arrow-left"></i>
        </div>
    </div>

    `,
    animations: [appModuleAnimation()],
    styleUrls: ['./amp-back-btn.css']
})
export class AmpBackBtn  {

    constructor(
        private location: Location
    ) {
      
    }

    goBack():void {
        this.location.back();
    }
    
}

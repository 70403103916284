import { Component, Injector, ViewChild, OnInit, Input, Output, SimpleChanges, OnChanges } from '@angular/core';

import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import { EntityContactDto, ContactDto } from '@shared/service-proxies/service-proxies';
import { entityContactComponent } from '@app/shared/common/entityContact/common-entity-contact-modal.component';

import { EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

import {DomSanitizer} from '@angular/platform-browser';


@Component({
    selector: 'amp-contact-item',
    template: `
        <mat-list-item role="listitem" class="amp-contact-item">
            <div class="item-avatar">
                <ngx-avatar [name]="contact.name"></ngx-avatar>
            </div>
            <div class="item-name">
                {{contact.name}}
            </div>
            <div class="item-icons">
                <a *ngIf="contact.workPhone" href="https://wa.me/5491133145216" target="_blank" matTooltip="Send message">
                    <i class="contact-item-icon fab fa-whatsapp-square"></i>
                </a>
                
                <a *ngIf="contact.skypeId" [href]="skypeLink" target="_blank" matTooltip="Open skype">
                    <i  class="contact-item-icon fab fa-skype"></i>
                </a>
                <a *ngIf="contact.email" href="mailto:{{contact.email}}"  target="_blank" matTooltip="Send mail">
                    <i  class="contact-item-icon fas fa-envelope-square"></i>
                </a>
                <a *ngIf="contact.linkedInProfile" [href]="contact.linkedInProfile"  target="_blank" matTooltip="Go to profile">
                    <i  class="contact-item-icon fab fa-linkedin"></i>
                </a>
                
            </div> 
            
            <div class="item-type">
                <div *ngIf="contact.type ==  0" class="contact-type-badge">tech</div>
                <div *ngIf="contact.type ==  1" class="contact-type-badge">buss</div>
            </div>
            <div class="item-action">
                <div dropdown container="body" class="contact-arrow-actions" >
                    <i class="fas fa-chevron-circle-down" dropdownToggle matTooltip="Actions"></i>
                    <ul class="dropdown-menu"  *dropdownMenu>
                        <li>
                            <a (click)="showEntityContactModal('view')">Ver</a>
                        </li>
                        <li>
                            <a (click)="showEntityContactModal('edit')">Editar</a>
                        </li>
                        <li>
                            <a (click)="deleteContact(contact)">Eliminar</a>
                        </li>
                    </ul>
                </div>
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <commonEntityContactModal [entity-id]="entityId" [action-type]="actionType"  [entity-name]="entityName" [string-contact]="stringContact" #entityContactModal (itemSelected)="contactSelected($event)"></commonEntityContactModal>
    `,
    animations: [
        appModuleAnimation(),
    ],
    styleUrls: ['./amp-contact-item.css']
})
export class AmpContactItem extends AppComponentBase implements OnChanges, OnInit {

    @Input('item') item: any;
    @Input() pos : number;
    @Output('id-to-delete') idToDelete = new EventEmitter();
    @Input('entity-name') entityName;
    @Input('entity-id') entityId;
    contact: ContactDto = new ContactDto();
    stringContact: string;
    actionType: string = 'view'
    @ViewChild('entityContactModal', {static:true}) entityContactModal: entityContactComponent;
    id: number;
    skypeLink

    constructor(
        injector: Injector,
        private sanitizer:DomSanitizer

    ) {
        super(injector);
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes);
        if (changes.firstChange) {
            return false;
        }
        this.stringContact = changes.item.currentValue;
        this.contact = JSON.parse(changes.item.currentValue);
        this.skypeLink = this.sanitize(`skype:live:skype.${this.contact.skypeId}?chat`);

    }

    ngOnInit(): void {
    }

    sanitize(url:string){
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    showEntityContactModal(actionType): void {
        this.actionType = actionType;
        this.entityContactModal.actionType = actionType;
        this.entityContactModal.show();

        this.entityContactModal.entityContactDto = new EntityContactDto();
        this.entityContactModal.entityContactDto.contact = new ContactDto();
        this.entityContactModal.entityContactDto.entityId = this.id;
        this.entityContactModal.entityContactDto.provisionerId = this.id;
    }

    contactSelected(contact: ContactDto) {
        this.contact = contact;
    }

    deleteContact(contact: ContactDto): void {
        let id = String(contact.id);
        let pos = String(this.pos);
        this.idToDelete.emit({id:id, pos:pos});
    }
}

import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, Injector, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'; 
import { FileUploader } from 'ng2-file-upload';
import { ActivatedRoute } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as _ from 'lodash';
import { AcquisitionFieldType, AcquisitionFlowServiceProxy, AcquisitionOperationOption, AddOrUpdateAcquisitionFlowOperationInput, ServiceServiceProxy } from '@shared/service-proxies/service-proxies';
import { AcquisitionFlowOperationView, OperationAcquisitionOption, LayoutElementType, IAllElements, IFormAndLayout } from '../../models';
import { IOutputFile } from '../../directives/input-file.component';
import { ParserService } from '@app/shared/common/parsers/custom-parsers.service';
import { MatFormField } from '@angular/material/form-field';
import { FieldType } from '../../../../../../shared/service-proxies/service-proxies';


@Component({
    templateUrl: "./acquisition-operation-form.component.html", 
    styleUrls: ['acquisition-operation-form.component.less'],
    selector: 'acq-operaton-form'
})
export class AcquisitionOperationForm extends AppComponentBase implements OnInit {
    @Input() operation: AcquisitionFlowOperationView;
    @Input('service-id') serviceId: number;
    @Output('dirty-form') dirtyForm = new EventEmitter<boolean>(true);
    @Input('flow-id') flowId: number;
    @Output('remove-operation') removeOperation = new EventEmitter<AcquisitionFlowOperationView>(null);
    @Input('external-save') externalSave: any;
    @Output('form') formValues = new EventEmitter<IFormAndLayout>(null);
    @Output('saved') saved = new EventEmitter<boolean>(false);
    @Output('on-change-render-case') onChangeRenderCase = new EventEmitter<IFormAndLayout>(null);
    @ViewChild('dropdown') dropdown: MatFormField;
    bodyOptions: OperationAcquisitionOption[] = [];
    noBodyOptions: OperationAcquisitionOption[] = [];
    layout : string = null;
    uploadersLayout: FileUploader[] = [];
    fake = {};
    saving:boolean=false;
    baseCDN = this.getAmpBaseUrlsForEnviroment.acquisition;
    uploaders: any[] = [];
    form: FormGroup;
    options: string;
    selectOptions : any[] = [];
    vendorId : number;
    layoutBlob;
    pathForm;
    blobForm;
    counter: number = 0;
    arr :any[] = [];
    changeImageInput:boolean = false;
    background:string = null;
    allElements: IAllElements = {
        header: [],
        footer: []
    };
    layoutObject : any[];
    selectedCase?:string;
    formWithLayoutPanels:boolean = true;
    newElement;


    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        public _acquisitionFlowServiceProxy: AcquisitionFlowServiceProxy,
        public _serviceProxy: ServiceServiceProxy,
        private paserService: ParserService,
        injector: Injector,
    ) {
        super(injector);
    }   

    
    ngOnInit() {

        this.route.params.subscribe(params => {
            if (params && params.vendorId) {
                this.vendorId = params.vendorId;
                this.formWithLayoutPanels = false;
            }
        })
        
        this.form = this.fb.group({});
        if (this.operation.id != null) {
            this.allElements = this.getLayoutElementList(this.operation);
        }
        this.layout = this.convertLayout(true, 'basicFile');
        this.layoutBlob = this.convertLayout(true, 'blob');
        if (this.operation.name == "RenderResult") {
            this.noBodyOptions = this.operation.renderResultForm;

            this.operation.options.forEach(option => {
                if (option.slug.match('result-') == null && 
                    option.slug != "background-image" 
                    && option.slug != "background-color"
                    && option.slug != "meta-description"
                    && option.slug != "title"
                    && option.slug != "primary-color") {
                    this.bodyOptions.push(option)
                }

                if (option.acquisitionFieldType == 6) {
                    option.value = this.paserService.parseBooleanToStr(option.value);
                }
                               
                this.form.addControl(option.slug, new FormControl(option.value))
            })

            if (this.operation.renderResultCasesAcq.length > 0) {

                this.selectOptions = this.operation.selectOptions;
                this.operation.selectedCaseAcq = this.selectOptions[0].slug;

            } else {
                this.operation.selectedCaseAcq = this.l('NotCaseSavedInVendor');
            }

            var message = [];
            var btnMessage = [];
            var action = [];
            this.noBodyOptions.forEach(field =>{               
                if (field.slug.match('result-message')) {
                    message.push(field);
                }else if (field.slug.match('result-button-text')) {
                    btnMessage.push(field);
                } else {
                    action.push(field);
                }
            });

            var total = [...message,...btnMessage,...action];
            this.noBodyOptions = total;
        } 
        else 
        {
            this.operation.options.forEach(option => {
                if(option.fieldType === AcquisitionFieldType.FileScript || option.fieldType === AcquisitionFieldType.FileStylesheet)
                {
                    option.isFileField = true;
                }

                if (this.formWithLayoutPanels) {
                    if (option.isLayoutOption)
                        this.bodyOptions.push(new OperationAcquisitionOption(option, option.value, "/Upload/UploadAcquisitionControlElement", false));
                    else
                        this.noBodyOptions.push(new OperationAcquisitionOption(option, option.value, "/Upload/UploadAcquisitionControlElement", false));
                }
                else 
                {
                        this.noBodyOptions.push(new OperationAcquisitionOption(option, option.value, "/Upload/UploadAcquisitionControlElement", false));
                }
                
                if (option.value == "true" || option.value  == "false") {
                    option.value = JSON.parse(option.value);
                }
                
                this.form.addControl(option.slug, new FormControl(option.value));
            });
        }
    }

    ngAfterViewInit(): void {
        this.form.valueChanges.subscribe(val => {
            this.arr.push(val);
            if (val['background-image'] && val['background-image'] != null) {
                if (this.arr.length == 2) {
                    if (this.arr[0]['background-image'] != this.arr[1]['background-image']) {
                        this.background = this.arr[0]['background-image'];
                        this.pathForm = this.arr[0];
                    }
                    this.arr = [];
                }
            } 
            else
            {
                this.background = null;
                if (this.pathForm){
                    this.pathForm['background-image'] = null;
                }
                this.arr = [];
            }
            this.operation.stringOptions = JSON.stringify(this.form.value);
            this.operation.isDirty = true;
            this.dirtyForm.emit(true);
            this.formValues.emit({form: this.form.value, layout: this.layoutBlob, operationName:this.operation.name, isBlob:this.changeImageInput, operation:this.operation});

        });
        this.operation.stringOptions = JSON.stringify(this.form.value);
        this.operation.stringOperationOptions = this.form.value;
    }

    ngOnChanges(externalSave: SimpleChanges) {
        if (externalSave.externalSave.currentValue) {        
            this.save();
        }
    }
    
    getFilePath($event){
        this.changeImageInput = true;
    }

    onChangeCase($event) {
        let operationFormInfo: IFormAndLayout = {form: this.form.value, layout: this.layoutBlob, operationName:this.operation.name, isBlob:this.changeImageInput, operation:this.operation}
        this.onChangeRenderCase.emit(operationFormInfo)
    }


    getImageFile($event: IOutputFile) {
        switch ($event.fileType) {
            case FieldType.FileImage:
                this.uploaders['image'] = $event.uploader;
                this.operation.options.forEach(op => {
                    if (op.slug === 'background-image') {
                        op.value = $event.filename || null;
                        op.isFileField = true;
                        op.value ? this.form.controls['background-image'].setValue(`${op.value}`) : this.form.controls['script'].setValue(null);
                    }
                });
                this.operation.stringOperationOptions = this.form.value;
                this.operation.stringOperationOptions['background-image'] = $event.filename;
                break;
    
            case FieldType.FileScript:
                this.uploaders['js'] = $event.uploader;
                this.operation.options.forEach(op => {
                    if (op.slug === 'script') {
                        op.value = $event.filename || null;
                        op.isFileField = true;
                        op.value ? this.form.controls['script'].setValue(`${op.value}`) : this.form.controls['script'].setValue(null);
                    }
                });
                this.operation.stringOperationOptions = this.form.value;
                break;
    

            case FieldType.FileStylesheet:
                this.uploaders['css'] = $event.uploader;
                this.operation.options.forEach(op => {
                    if (op.slug == "stylesheet") {
                        op.value = $event.filename || null;
                        op.isFileField = true;
                        op.value ? this.form.controls['stylesheet'].setValue(`${op.value}`) : this.form.controls['stylesheet'].setValue(null);
                    }
                })
                this.operation.stringOperationOptions = this.form.value;
                break;
    
            default:
                break;
        }
    }

    getImagesPromises(uploaders: FileUploader[]): Promise<any>[] {
        let promisedArray = uploaders.map(val => {
            let promise = new Promise((resolve, reject) => {
                val.onSuccessItem = (item, response) => {
                    resolve(item);
                }
                val.onErrorItem = (item, response) => {
                    reject(response);
                }
            })
            val.uploadAll();
            return promise;
        })
        return promisedArray;
    }

    async save(): Promise<any> {
        var uploaderWithFile: FileUploader[] = [];
        this.saving = true;
        for (let val in this.uploaders) {
            if (this.uploaders[val].queue.length > 0) {
                uploaderWithFile.push(this.uploaders[val]);
            }
        }

        uploaderWithFile.forEach((uploader, index) =>{
            let currentQueue = [uploader.queue[uploader.queue.length-1]];
            uploader.queue = currentQueue;
        })

        if (uploaderWithFile.length > 0) {
            try {
                await Promise.all(this.getImagesPromises(uploaderWithFile));
            } catch (err) {
                console.log(err);
                this.message.error("An error ocurrend uploading some files");
                this.saving = false;
                return false;
            }
        }

        if (this.background != null) {
            this.form.value['background-image'] = this.background;
        }

         if (this.layout != null && this.layout  != "") {
            let playout = JSON.parse(this.layout);
            for (var i in this.fake){
                for (var j in playout){
                    if (this.fake[i] && i  ==  j) {
                        playout[j] = this.fake[i];
                    }
                }
            }
            this.layout =  JSON.stringify(playout);
        }
        this.form.value;

        let formWithValues = {};
        for (let i in this.form.value) {
            if (this.form.value[i] != null && this.form.value[i] != "") {
                formWithValues[i] = this.form.value[i]; 
            }
        }
        
        let newOperation = new AddOrUpdateAcquisitionFlowOperationInput({
            id: this.operation.id,
            flowId: this.flowId,
            operationId: this.operation.operationId,
            options: JSON.stringify(formWithValues),
            layout: this.layout,
            order: this.operation.order,
            isActive: true
        })
        
        this._acquisitionFlowServiceProxy.addOrUpdateOperation(newOperation).subscribe(data => {
            this.operation.id = data;
            this.operation.isDirty = false;
            this.operation.externalSave = false;
            this.dirtyForm.emit(false);
            this.notify.success("Saved Successfully");
            this.uploaders = [];
            this.saving = false;
            this.saved.emit(true);
        })
    }

    getElements(elements: LayoutElementType[], parent: string) {
        this.allElements[parent] = elements;
        this.layout = this.convertLayout(false, 'basicFile');
        this.layoutBlob = this.convertLayout(false, 'blob');
        this.operation.isDirty = true;
        this.dirtyForm.emit(true);
        let form = this.form.value;
        if (this.pathForm && this.background != null) {
            form = JSON.parse(this.operation.stringOptions)
        }
        
        this.formValues.emit({form: form, layout: this.layoutBlob, operationName:this.operation.name, isBlob:this.changeImageInput, operation:this.operation});
    }

    getNewElement(element: LayoutElementType) {
        this.newElement = Object.assign({}, element);
    }

    convertLayout(fromInit:boolean, fileType:string): string {
        let header = {};
        let footer = {};

        if (this.allElements["header"].length > 0) {
            header = this.getLayoutDataFormat(this.allElements["header"], 'header', fromInit, fileType);
        }
        if (this.allElements["footer"].length > 0) {
            footer = this.getLayoutDataFormat(this.allElements["footer"], 'footer', fromInit, fileType);
        }

        let layout = Object.assign(header, footer);
        let returnedLayout: string;
        if (Object.values(layout).length == 0) {
            returnedLayout = "";
        } else {
            returnedLayout = JSON.stringify(layout);
        }
        return returnedLayout;
    }

    getLayoutDataFormat(parentElement: Array<any>, parentName: string, fromInit:boolean, fileType:string): any {
        let layoutFormat = {};
        let fakeElement;
        for (let childElement of parentElement) {
            let elementContent = childElement.type == "p" ? childElement.paragraphText : childElement[fileType];
            layoutFormat[`${parentName}-${childElement.type}-${childElement.name}`] = elementContent;

            if (childElement.styles && childElement.styles.length > 0) {
                layoutFormat[`${parentName}-${childElement.type}-${childElement.name}-class`] = childElement.styles.toString();
            }
            if (!fromInit && childElement.type == "img") {
                this.fake[`${parentName}-${childElement.type}-${childElement.name}`] = fakeElement
            }
        }
        
        return layoutFormat;
    }

    convertStringToLayoutElement(stringLayout: {[key: string]: string}) {
        var layout = stringLayout;
        var keys = Object.keys(layout);
        var values = Object.values(layout);
        var i = 0;
        var next = 1;
        var layoutElements = [];

        while (i < keys.length) {
            var layoutE = keys[i].split("-");
            var obj = {
                owner: layoutE[0],
                type: layoutE[1],
                name: layoutE[2],
            }
            if (layoutE[1] == "p") {
                obj["paragraphText"] = values[i];
            } else {
                obj["basicFile"] = values[i];
            }

            if (next < keys.length) {
                if (keys[next].indexOf(keys[i]) != -1) {
                    obj["styles"] = values[next];
                    obj["styles"] = obj["styles"].split(',');
                    layoutElements.push(obj)
                    i = i + 2;
                    next = next + 2;
                } else {
                    layoutElements.push(obj);
                    i++;
                    next++;
                }
            } else {
                layoutElements.push(obj);
                i++;
                next++;
            }
        }
        return layoutElements;
	}

	getLayoutElementList(operation: AcquisitionFlowOperationView):IAllElements {
        let layoutObject;
        let allElements: IAllElements = {
            header : [],
            footer : []
        };
		if (operation.layout != null) {
            layoutObject = this.convertStringToLayoutElement(operation.layout);
            if (layoutObject != null) {
                for (var layoutElement of layoutObject) {
                    allElements[layoutElement.owner].push(layoutElement);
                }
            }
		}
        return allElements;
    }
    
    getToggleValue(event): void {
        this.form.controls[event.slug].setValue(event.checked);
        
    }

    toCamelCase(dashCaseString: string): string {
        return dashCaseString.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
    }

    async saveImages(uploaders: FileUploader[]) {
        var uploaderWithFile = [];
        for (let val of uploaders) {
            if (val.queue.length > 0) {
                uploaderWithFile.push(val);
            }
        }
  
        if (uploaderWithFile.length > 0) {
            try {
                await Promise.all(this.getImagesPromises(uploaderWithFile));
                return true;
            } catch (err) {
                console.log(err);
                this.message.error("An error ocurrend uploading some files");
                return false;
            }
        }
    }   

    getLayoutImgFile(event: any) {
        if (!event.isText) {
            
            this.uploadersLayout.push(event);
            this.saveImages(this.uploadersLayout).then(data => {
                console.log(data);
            })
           
        }
    }
    
}

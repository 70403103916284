import { Injectable } from '@angular/core';
import { FieldType, OverrideLevel } from '@shared/service-proxies/service-proxies';

@Injectable({
  providedIn: 'root'
})
export class PathService {
  getAcquisitionBasePathForScriptAndStyleSheet(flowId: number, operationName: string, type: number, objectId: number, override?: number): string {
    let result = '';

    switch (type) {
      case FieldType.FileScript:
        if (override === OverrideLevel.Service) {
          result = `service/${objectId}/${flowId}/${operationName}/js/`;
        }
        if (override === OverrideLevel.Vendor) {
          result = `vendor/${objectId}/${flowId}/${operationName}/js/`;
        }
        break;

      case FieldType.FileStylesheet:
        if (override === OverrideLevel.Service) {
          result = `service/${objectId}/${flowId}/${operationName}/css/`;
        }
        if (override === OverrideLevel.Vendor) {
          result = `vendor/${objectId}/${flowId}/${operationName}/css/`;
        }
        break;

      case FieldType.FileImage:
        if (override === OverrideLevel.Service) {
          result = `service/${objectId}/`;
        }
        if (override === OverrideLevel.Vendor) {
          result = `vendor/${objectId}/`;
        }
        break;

      default:
        break;
    }
    return result;
  }

  getAcquisitionBasePathForImage(objectId : number, override: number) : string
  {
      let result = '';

      switch(override) {
        case OverrideLevel.Service:
          result = `service/${objectId}/images/`
        break;

      case OverrideLevel.Vendor:
        result = `vendor/${objectId}/images/`
        break;
        default:
          break;
    }

    return result;
  }

  getAcquisitionBackupPath(flowId: number, operationName: string, type: number, objectId: number, override?: number): string {
    let result = '';
  
    if (override === OverrideLevel.Service) {
      result = `acq/service/${objectId}/${flowId}/${operationName}/backup/`;
    }
    if (override === OverrideLevel.Vendor) {
      result = `acq/vendor/${objectId}/${flowId}/${operationName}/backup/`;
    }

    return result;
  }

  getAcquisitionFileImageBackupPath( objectId: number, override?: number): string {
    let result = '';
  
    if (override === OverrideLevel.Service) {
      result = `acq/service/${objectId}/images/backup/`;
    }
    if (override === OverrideLevel.Vendor) {
      result = `acq/vendor/${objectId}/images/backup/`;
    }

    return result;
  }
}



import {
    OperationAcquisitionOptionListDto,
    OperationOptionListDto,
    AccessControlPreviewDto
} from '@shared/service-proxies/service-proxies';

import { FormControl, FormBuilder } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';


export interface IflowWithOperation<T> {
    flow: T;
    operations : AcquisitionFlowOperationView[],
    inFlowOperations : AcquisitionFlowOperationView[]
}


export interface LayoutElementType {
	paragraphText?: string;
	basicFile?: string;
    name: string;
    type: string;
    styles?: string[];
	owner?:string;
    file? : any;
    blob? : string;
}


export interface IAllElements {
    header?: LayoutElementType[],
    footer?: LayoutElementType[]
}

export interface IFormAndLayout {
    form: any;
    layout: {[key: string]: string;};
    operationName:string;
    isBlob:boolean;
    operation : AcquisitionFlowOperationView | undefined
}

export interface IFormAndLayoutAccess {
    form: any;
    layout: string;
    operationName:string;
    isBlob:boolean;
    operation : AcquisitionFlowOperationView | undefined
}

// interface IOptionFileUploaderItem {
//     type:number;
//     fileUploader:FileUploader
// }

// export interface IOptionUploder {
//     optionUploader : IOptionFileUploaderItem
// }

// export class OptionUploader {
//     name:string;
//     uploader:FileUploader;

//     constructo(name:string, uploader:FileUploader){
//         this.name = name;
//         this.uploader = uploader;
//         return 
//     }
// }

export class OperationAcquisitionOption extends OperationAcquisitionOptionListDto {
    value:any;
    urlEndPoint! : string;
    isBlob:boolean;
    hide:boolean;
    fieldType:any;
    renderResultCase?:string;
    externalValue?:any;
    renderResultCaseAcq?:string;
    isFileField: boolean;
    fromVendor: boolean;

    constructor(OperationOptions: OperationAcquisitionOptionListDto, value:any, urlEndPoint:string, isBlob:boolean, renderResultCase?:string, externalValue?:any, renderResultCaseAcq?:string){
        super(OperationOptions);
        this.value = value;
        this.urlEndPoint = urlEndPoint;
        this.isBlob = isBlob || false;
        this.fieldType = this.acquisitionFieldType;
        this.hide = false;
        this.renderResultCase = renderResultCase;
        this.externalValue = externalValue || null;
        this.renderResultCaseAcq = renderResultCaseAcq;

    }
}

export class OperationOptions extends OperationOptionListDto {
    value:any;
    urlEndPoint! : string;
    isBlob:boolean;
    hide:boolean;
    acquisitionFieldType:any
    overrideLevel : number;
    id: number
    
    constructor(OperationOptions:OperationOptionListDto, value:any, urlEndPoint:string, isBlob:boolean){
        super(OperationOptions);
        this.value = value;
        this.urlEndPoint = urlEndPoint;
        this.isBlob = isBlob || false;
        this.hide = this.fieldType == 1 || this.fieldType == 2;
        this.acquisitionFieldType = this.fieldType;

    }
}



export class AcquisitionFlowOperationView   {
    id : number | null | undefined;
    operationId : number;
    bodyEnabled : boolean;
    layoutEnabled : boolean;
    name : string;
    type : number;
    options : OperationAcquisitionOption[] | OperationOptions[] | null | any;
    inFlow : boolean;
    order?: number;
    layout : {[key: string]: string} | null;
    isActive: boolean;
    disabledDrag!: boolean;
    isDirty!:boolean;
    externalSave!:boolean;
    previewRender!: AccessControlPreviewDto | undefined | null
    stringOptions! : string | undefined | null;
    stringOperationOptions! : string | undefined | null;
    isDeleted?:boolean | undefined | null;
    selectedCaseAcq! : string | undefined | null;
    renderResultCasesAcq?: Array<any>;
    selectOptions?= [];
    fb: FormBuilder;
    renderResultForm = [];

    constructor(id : number | null | undefined,operationId : number,bodyEnabled : boolean,layoutEnabled : boolean,name : string,type : number,options : OperationAcquisitionOption[],inFlow : boolean, order: number, layout:{[key: string]: string} | null, isActive:boolean, disabledDrag:boolean, isDirty:boolean, externalSave:boolean, previewRender: AccessControlPreviewDto | undefined | null, stringOptions: string | undefined | null, stringOperationOptions: string | undefined | null, isDeleted: boolean | undefined | null, selectedCaseAcq? : string | undefined | null, renderResultCasesAcq?: Array<any>){
        this.id = id;
        this.operationId = operationId
        this.bodyEnabled = bodyEnabled;
        this.layoutEnabled = layoutEnabled;
        this.name = name;
        this.type = type;
        this.options = options;
        this.inFlow = inFlow;
        this.order = order;
        this.layout = layout;
        this.isActive = isActive
        this.disabledDrag = disabledDrag;
        this.isDirty = isDirty || false;
        this.externalSave = externalSave || false;
        this.previewRender = previewRender;
        this.stringOptions = stringOptions || undefined;
        this.stringOperationOptions = stringOperationOptions || null;
        this.isDeleted = isDeleted || null
        this.selectedCaseAcq = selectedCaseAcq || null;
        this.renderResultCasesAcq = renderResultCasesAcq || [];
        this.fb = new FormBuilder();


        if (this.name == "RenderResult") {
            
            this.setRenderResultCases();
        }
    }
    
    
    setRenderResultCases() {
        // nuevo feature de opciones de renderresult
        let options = {};
        this.options.forEach(option=>{
            options[option.slug] = option.value;
        })

        // let options = JSON.parse(this.acquisitionFlowOperation.options); // opciones de string a objeto

        let optionsMessages: string[] = []; // array para guardar las propiedades "result-message-"
        let optionsMessagesField: string[] = []; // array para guardar las propiedades sin los prefijos ej: con pre=>result-message-subscribe, sin pre => suscribed
        let optionsButtons: string[] = []; // array para guardar las propiedades "result-button-text-"
        let optionsButtonsField: string[] = [];  // array para guardar las propiedades sin los prefijos ej: con pre=>result-button-text-subscribe, sin pre => suscribed

        console.log(options);

        for (var op in options) {
            // console.log(op);
            if (op.match('result-message-')) { // busco propiedades con el prefijo "result-message-"
                let splitedOption: string[] = op.split('-'); // creo un nueno array en base al '-' de la llave
                let name = splitedOption.splice(2, splitedOption.length - 1); // obtengo el nombre sin prefijo
                optionsMessagesField.push(name.join('-')); // agrego el nombre coin guion al array de optionsMessagesField;
                if (options[op] != "") {
                    this.selectOptions.push({ name: name.join(' '), slug: name.join('-') }); // agrego al array del select su opcion
                }
                optionsMessages.push(op);
            }
            if (op.match('result-button-')) {
                let splitedOption: string[] = op.split('-');
                let name = splitedOption.splice(3, splitedOption.length - 1);
                optionsButtonsField.push(name.join('-'));
                optionsButtons.push(op);
            }
        }

      

        for (let i = 0; i < optionsMessagesField.length; i++) {
            let form = this.fb.group({});
            let formOption = {
                form: [],
                formgroup: form
            }
            let opMessage = optionsMessagesField[i];
            let pos = optionsButtonsField.indexOf(opMessage);
            
            formOption['case'] = optionsMessagesField[i];
            formOption['name'] = 'result-message-' + opMessage;
           
            let formArray = [];

            // creo la isntancia del formulartio
            formOption.formgroup.addControl('result-message-' + opMessage, new FormControl(options['result-message-' + opMessage]));
            formOption.formgroup.addControl('show-button', new FormControl(pos != -1));
            formOption.formgroup.addControl('result-button-text-' + opMessage, new FormControl(options['result-button-text-' + opMessage]));
            formOption.formgroup.addControl('result-action-' + opMessage, new FormControl(options['result-action-' + opMessage]));
            // creo la isntancia del formulartio

            //creo objecto con valores del formulario para itera en el html
            // if (options['result-message-' + opMessage]!= null) {
                formArray.push({
                    name: 'result-message-' + opMessage,
                    value: options['result-message-' + opMessage]
                })

                formArray.push({
                    name: 'show-button',
                    value: pos != -1
                })

                formArray.push({
                    name: 'result-button-text-' + opMessage,
                    value: options['result-button-text-' + opMessage]
                })


                formArray.push({
                    name: 'result-action-' + opMessage,
                    value: options['result-action-' + opMessage]
                })

                formOption.form = formArray;
                this.renderResultCasesAcq.push(formOption);
         
            
            
            //creo objecto con valores del formulario para itera en el html
        }
      
        this.renderResultCasesAcq.forEach(c => {
            this.options.forEach(option => {
                c.form.forEach(field => {
                    if (field.name == option.slug) {
                        this.renderResultForm.push(new OperationAcquisitionOption(option, options[option.slug], "", false, c.case))
                    }
                })
            })
        })
        // console.log(this.formOptions);
        // nuevo feature de opciones de renderresult


    }
}

export class GenericPreviewForm<T> {
    formResult : T;
    constructor(TCreator: { new (form:any): T; }, form:any) {
        this.formResult = new TCreator(form);
    }
}

export interface IBuildedPreview {
    name: string;
    preview : AccessControlPreviewDto
}




// ACCESS CONTROL OLD CLASES E INTERFACES 
export interface LayoutElementType {
	paragraphText?: string;
	basicFile?: string;
    name: string;
    type: string;
    styles?: string[];
	owner?:string;
	file? : any;
}


interface IOperationList {
	baseCDN: string;
	origin: string;
	name: string;
	operationId: number;
	id?: number | null;
	options: string;
	layoutEnabled: boolean;
	bodyEnabled: boolean;
	layout: string;
}




export class AccessControlFlowOperation {
	baseCDN: string;
	origin: string;
	name: string;
	operationId: number;
	id: number | null;
	options: string;
	layoutEnabled: boolean
	bodyEnabled: boolean;
	layout: string;



	constructor(data: IOperationList) {
		this.baseCDN = data.baseCDN;
		this.name = data.name;
		this.origin = data.origin;
		this.id = data.id || null;
		this.operationId = data.operationId;
		this.options = data.options;
		this.layoutEnabled = data.layoutEnabled
		this.bodyEnabled = data.bodyEnabled || false;
		this.layout = data.layout || "";
	}
}

export interface LayoutElementType {
    paragraphText?: string;
    basicFile?: string;
    name: string;
    type: string;
    styles?: string[];
    owner?: string;
}

export interface IAllElements {
    header?: LayoutElementType[],
    footer?: LayoutElementType[]
}

interface IFilePathAndName {
    path: string;
    name: string;
}

interface IShowFormTumbnails {
    image: boolean;
    icon: boolean;
}

export interface IOutputFile {
    uploader:FileUploader;
    filename: string;
    fileType: string;
    blob?:string;
}


// uploadType fileuploader

export enum UploadType {
    FileImage,
    FileScript,
    FileStylesheet,
    VendorLogo,
    ProductLogo,
    AggregatorLogo,
    ProvisionerLogo,
    AgencyLogo,
    ServiceLogo
   }
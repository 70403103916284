import { Component, Injector, ViewEncapsulation, ViewChild, OnInit, Input } from '@angular/core';

import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import { Location } from '@angular/common';

@Component({
    selector:'entity-comonent-header',
    template: `
        
        <div class="kt-subheader kt-grid__item">
            <div class="kt-container kt-container--fluid ">
                <div class="kt-subheader__main">
                    <amp-back-btn  *ngIf="showElement"></amp-back-btn>
                    <h3 class="kt-subheader__title">
                        <ng-content select="[title]"></ng-content>
                    </h3>
                    <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                    <span class="kt-subheader__desc">
                        <ng-content select="[titleInfo]"></ng-content>
                    </span>
                    <span class="kt-subheader__separator kt-subheader__separator--v" ></span>
                    <span class="kt-subheader__desc">
                        <ng-content select="[titleInfoName]"></ng-content>
                    </span>
                </div>
                <div class="kt-subheader__toolbar">
                    <div class="kt-subheader__wrapper">
                        <ng-content ></ng-content>
                    </div>
                </div>
            </div>
        </div>
    `,
    animations: [appModuleAnimation()],
    styleUrls: ['./entity-component-header.css']
})
export class EntityComponentHeader implements OnInit  {

    @Input('back-btn') backBtn : string ;
    showElement: boolean = true;

    constructor(
        private location: Location
    ) {
       
        
    }

    ngOnInit(){
        if (this.backBtn == "false") {
            this.showElement = false;
        }
    }
}

import { Component, Injector, ViewChild, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';


import * as _ from 'lodash';
import { MatSort, Sort, SortDirection } from '@angular/material/sort/';
import { MatPaginator, PageEvent } from '@angular/material/paginator/';
import { TableDataSource } from './table-data-source'
import { UrlFiltersPersist } from '@app/shared/common/url-filters-persist.ts/url-filter-persis.service';
import { AMPColumnType, AMPFilter, FilterType, AMPFilterData } from './amp-table-entities';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '../app-component-base';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CommonLookupModalComponent } from '@app/shared/common/lookup/common-lookup-modal.component';
import { CommonLookupServiceProxy, FindAgenciesInput, FindChannelsInput, FindLicenseProvidersInput, FindProductsInput, FindServicesInput, FindVendorsInput } from '@shared/service-proxies/service-proxies';
import { Subscription } from 'rxjs';
import { MatInput } from '@angular/material/input';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { FilterParams } from '@app/shared/common/url-filters-persist.ts/filter-params.enum';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';


@Component({
    selector: 'amp-table',
    templateUrl: './amp-table.html',
    styleUrls: ['./amp-table.less'],
    providers:[UrlFiltersPersist]
})
export class AmpTable extends AppComponentBase implements OnInit, OnDestroy {

    @Input('proxy') proxy: any;
    @Input('entityName') entityName: string;
    @Input('dataSource') dataSource: TableDataSource;
    @Input('permissionTo') permissionTo: string;
    @Input('displayedColumns') displayedColumns: any[];
    @Input('displayedRows') displayedRows: string[];
    @Input('viewLogoSearch') viewLogoSearch: string;
    @Input('filterText') filterText: string;
    @Input('sorteableItems') sorteableItems: Array<string>;
    @Input('filterList') filterList: Array<AMPFilter>;
    @Input() persistFiltersInUrl: boolean = true;
    @Input('showCaseLicenseList') public showCaseLicenseList: boolean = false; // es para mostrar los badges como se requirio en Licencias

    @Input() buttonDialog: Function;

    @Output() onLoadData = new EventEmitter<boolean>();
    @Output() onChangeParams = new EventEmitter<Params>();


    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild('filterInput', { static: true }) filterInput: MatInput;

    @ViewChild('agencyModal', { static: true }) agencyModal: CommonLookupModalComponent;
    @ViewChild('serviceModal', { static: true }) serviceModal: CommonLookupModalComponent;
    @ViewChild('vendorModal', { static: true }) vendorModal: CommonLookupModalComponent;
    @ViewChild('channelModal', { static: true }) channelModal: CommonLookupModalComponent;
    @ViewChild('productModal', {static: true}) productModal: CommonLookupModalComponent;
    @ViewChild('licenseProviderModal', {static: true}) licenseProviderModal: CommonLookupModalComponent;

    filterValue: string;

    totalCount: number;
    isFiltering: boolean = false;
    hasNoResult: boolean = false;

    filterType = FilterType;
    lookUpFilter: object = {};
    filtersData: AMPFilterData[];

    imageFallback = AppConsts.appBaseUrl + '/assets/common/images/image-24px.svg';
    ampColumnType = AMPColumnType;
    permissionGranted: boolean;
    constructedColumns: any[];
    clearLookUpButton: object = {};

    totalCountSub: Subscription;
    paramsSub: Subscription;

    licenseDetailsSelected: boolean = false;

    filterToggle: {[key:string]: boolean} = {};
    
    constructor(
        public injector: Injector,
        private _activatedRoute: ActivatedRoute,
        private _commonLookup: CommonLookupServiceProxy,
        private urlFiltersPersistService: UrlFiltersPersist,
        public router: Router
    ) {
        super(injector);
        this.displayedRows = [];
    }

    ngOnInit() {
        if(!this.persistFiltersInUrl){
            this.urlFiltersPersistService.disablePersistInUrl();
        }

        this.permissionGranted = this.permission.isGranted(this.permissionTo);

        if (this.displayedRows.length == 0) {
            this.displayedColumns.forEach(column => {
                this.displayedRows.push(column.name);
            });
        }
        this.configureLookUps();

        setTimeout(() => {
            this.initParamsListener();
        },0)

    }

    ngAfterViewInit() {
        if (this.permissionGranted) {
            this.initTotalListener();
        }
    }

    private initTotalListener() {
        this.totalCountSub = this.dataSource.totalCount$.subscribe(value => {
            if (value != null) {
                this.totalCount = value;
                this.hasNoResult = value == 0;
                this.onLoadData.emit(this.hasNoResult);
            }
        });
    }

    private initParamsListener() {
        this.paramsSub = this.urlFiltersPersistService.params$
            .subscribe((params: Params) => {
                this.isFiltering = UrlHelper.hasParams(params);

                this.setParamsToFilters(params);

                this.onChangeParams.emit(params);
            });
    }

    private configureLookUps() {
        this.filterList.forEach(filter => {
            if (filter.type == FilterType.lookUp) {
                this.lookUpFilter[filter.name] = {}
                this.clearLookUpButton[filter.name] = true
            }
            if(filter.type == this.filterType.toggle && !this._activatedRoute.snapshot.queryParams[filter.name]){
                this.filterToggle[filter.name] = true;
                this.router.navigate(['.'], {relativeTo: this._activatedRoute, queryParams: {[filter.name]:true}});
            }
        })
        this.configureAgencyLookupModal();
        this.configureServiceLookupModal();
        this.configureChannelLookupModal();
        this.configureVendorLookupModal();
        this.configureLicenseProviderModal();
        this.configureProductModal();
    }

    configureLicenseProviderModal() {
        this.licenseProviderModal.configure({
            title: this.l('LicenseProvider'),
            dataSource: (skipCount: number, maxResultCount: number, licenseProviderFilter: string) => {

                let input = new FindLicenseProvidersInput({
                    filter:  licenseProviderFilter,
                    maxResultCount,
                    skipCount
                });

                return this._commonLookup.findLicenseProviders(input);
            }
        });
    }
    configureProductModal() {
        this.productModal.configure({
            title: this.l('Product'),
            dataSource: (skipCount: number, maxResultCount: number, filter: string) => {

                let input = new FindProductsInput({
                    filter,
                    maxResultCount,
                    skipCount,
                    name: '',
                    vendorId: undefined
                });

                return this._commonLookup.findProducts(input);
            }
        });
    }

    cleanFilter(filter: AMPFilter): void {
        this.urlFiltersPersistService.removeParams([
            filter.name.toLocaleLowerCase(),
            filter.name.toLocaleLowerCase() + '_name',
            FilterParams.PAGE_INDEX_NAME,
            FilterParams.PAGE_SIZE_NAME
        ])
    }

    onChangePaginator(pageEvent: PageEvent){
        this.urlFiltersPersistService.addPaginatorParam(pageEvent);
    }

    removeFilter(filter: AMPFilter): void {
        if(filter.type == FilterType.lookUp) {
            this.lookUpFilter[filter.name[0].toUpperCase() + filter.name.slice(1)]['name'] = "";
            this.lookUpFilter[filter.name[0].toUpperCase() + filter.name.slice(1)]['id'] = undefined;
            this.clearLookUpButton[filter.name[0].toUpperCase() + filter.name.slice(1)] = true
        }

    }


    configureAgencyLookupModal(): void {
        this.agencyModal.configure({
            title: this.l('SelectAgency'),
            dataSource: (agencySkip: number, agencyMaxResultCount: number, agencyFilter: string, agencyTenantId?: number) => {
                let input = new FindAgenciesInput();
                input.filter = agencyFilter;
                input.maxResultCount = agencyMaxResultCount;
                input.skipCount = agencySkip;

                return this._commonLookup.findAgencies(input);
            }
        });
    }

    configureServiceLookupModal(): void {
        this.serviceModal.configure({
            title: this.l('SelectService'),
            dataSource: (serviceSkip: number, serviceMaxResultCount: number, serviceFilter: string, serviceTenantId?: number) => {
                let input = new FindServicesInput();
                input.filter = serviceFilter;
                input.maxResultCount = serviceMaxResultCount;
                input.skipCount = serviceSkip;
                return this._commonLookup.findServices(input);
            }
        });
    }

    configureVendorLookupModal(): void {
        this.vendorModal.configure({
            title: this.l('SelectVendor'),
            dataSource: (serviceSkip: number, serviceMaxResultCount: number, serviceFilter: string, serviceTenantId?: number) => {
                let input = new FindVendorsInput();
                input.filter = serviceFilter;
                input.maxResultCount = serviceMaxResultCount;
                input.skipCount = serviceSkip;
                return this._commonLookup.findVendors(input);
            }
        });
    }

    configureChannelLookupModal(): void {
        this.channelModal.configure({
            title: this.l('SelectChannel'),
            dataSource: (channelSkip: number, channelMaxResultCount: number, channelFilter: string, channelTenantId?: number) => {
                let input = new FindChannelsInput();
                input.filter = channelFilter;
                input.maxResultCount = channelMaxResultCount;
                input.skipCount = channelSkip;
                return this._commonLookup.findChannels(input);
            }
        });
    }


    onSortChange(sort: Sort){
        this.urlFiltersPersistService.addSortParam(sort);
    }

    showFilterModal(filter: string): void {
        let name = filter[0].toLowerCase() + filter.slice(1)
        this[`${name}Modal`].show();
    }

    setParamsToFilters(params: Params) {
        //Set filter input
        this.filterValue = params[FilterParams.FILTER_NAME];

        //Set lookups filters
        this.filterList.forEach( filter => {
            const filterName = filter.name.toLocaleLowerCase();
            const filterInParam = params[filterName ];
            const filterNameInParam = params[filterName + '_name'];
            if(filter.type == FilterType.lookUp){
                if( this.lookUpFilter[filter.name] && !!filterInParam && !!filterNameInParam)  {
                    this.lookUpFilter[filter.name]['name']= filterNameInParam;
                    this.clearLookUpButton[filter.name] = false
                } else if (this.lookUpFilter[filter.name]){
                    this.lookUpFilter[filter.name]['name']= '';
                    this.clearLookUpButton[filter.name] = true
                }
            }

            if(filter.type == FilterType.toggle){
                this.filterToggle[filter.name] = params[filterName] == 'false' ? false : true;
            }
        })

        //Set paginator params
        this.setValuesToPaginator(params[FilterParams.PAGE_INDEX_NAME], params[FilterParams.PAGE_SIZE_NAME]);

        //Set sort params
        if(params[FilterParams.SORT_NAME]){
            const active = params[FilterParams.SORT_NAME].split(' ')[0] as string;
            const direction = params[FilterParams.SORT_NAME].split(' ')[1] as string;
            this.sort.active = active;
            this.sort.direction = direction as SortDirection;
        }
    }

    private setValuesToPaginator(pageIndex: number, pageSize: number){
        if(!!!this.paginator) return;
        this.paginator.pageIndex = pageIndex;
        this.paginator.pageSize = pageSize;
    }

    getLogoUrl(id) {
        return `${this.getAmpBaseUrlsForEnviroment.generalbaseCdn}${this.viewLogoSearch}/${id}/images/logo.png`;
    }

    goToUrl(action, element) {
        let permission = action.permission;
        let route: string[];
        if (action.edit) {
            route = [action.goTo, element.id, "edit"];
        } else {
            route = [action.goTo, element.id];
        }
        if (!this.validatePermissionAction(permission)) return false;
        this.router.navigate(route);
    }

    ngOnDestroy(): void {
        this.filterList.map(f => {
            this.removeFilter(f);
        });

        this.totalCountSub?.unsubscribe();
        this.paramsSub?.unsubscribe();
    }

    inputChange(value: string) {
        this.urlFiltersPersistService.addFilterParam(value);
    }

    lookUpInputChange(params: {name: string, value: any}, filter:string) {
        this.urlFiltersPersistService.addParamWithMerge({
            [filter.toLowerCase()]: params.value,
            [filter.toLowerCase() + '_name'] : params.name,
            [FilterParams.PAGE_INDEX_NAME]: '0',
            [FilterParams.PAGE_SIZE_NAME]: '10'
        })

        this.clearLookUpButton[filter[0].toUpperCase() + filter.slice(1)] = false
        this.setFilterValues(filter, params.name, params.value)
    }

    toggleChange(event: MatSlideToggleChange, filter: string){
        this.urlFiltersPersistService.addParamWithMerge({
            [filter.toLowerCase()]: event.checked,
            [FilterParams.PAGE_INDEX_NAME]: '0',
            [FilterParams.PAGE_SIZE_NAME]: '10'
        })
    }

    setToggleValues(filterName: string, value: boolean){
        this.filterToggle[filterName] = value;
    }

    setFilterValues(filter: string, name: string, id: number) {
        this.lookUpFilter[filter[0].toUpperCase() + filter.slice(1)]['name'] = name;
        this.lookUpFilter[filter[0].toUpperCase() + filter.slice(1)]['id'] = id;
    }

    refresh(){
        this.urlFiltersPersistService.refreshWithCurrentParams()
    }

    clearFilter(){
        this.filterInput.value = '';
    }
}

import { Injectable } from '@angular/core';


@Injectable()
export class ParserService {

    constructor() {

    }

    parseBooleanToStr(str:string): boolean {
        
        if (!str || str != 'true') {
            return false;
        }
        return true;
    }
}

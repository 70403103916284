import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CustomDateRange } from "../date-picker-month-range/date-picker-month-range.component";
import * as moment from "moment";

@Component({
    selector: "date-picker-day-range",
    templateUrl: "./date-picker-day-range.component.html",
    styleUrls: ["./date-picker-day-range.component.css"],
})
export class DatePickerDayRangeComponent implements OnInit {
    @Input() valueSetted: CustomDateRange;

    @Input() minDate: Date;
    @Input() maxDate: Date;

    range: FormGroup;

    firstDateSelected: boolean = false;

    @Output() rangeDateChange = new EventEmitter<{
        start: string;
        end: string;
    }>();

    constructor(private _fb: FormBuilder) {
        this.initForm();
    }

    ngOnInit() {
        if (this.valueSetted) {
            this.range.get("start").setValue(this.valueSetted.from);
            this.range.get("end").setValue(this.valueSetted.to);
        }
    }

    initForm() {
        this.range = this._fb.group({
            start: ["", Validators.required],
            end: ["", Validators.required],
        });
    }

    changeDateRange(event: any) {
        if (event.value && this.range.valid) {
            this.rangeDateChange.emit({
                start: moment(new Date(this.range.get("start").value)).format(
                    "YYYY-MM-DD"
                ),
                end: moment(new Date(this.range.get("end").value)).format(
                    "YYYY-MM-DD"
                ),
            });
        }
    }
}

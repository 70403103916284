import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class DateTimeService {
    currentLanguage: string;

    constructor(){
        this.currentLanguage = abp.localization.currentLanguage.name
    }

    public getFormat(): string {
        switch (this.currentLanguage) {
            case "en":
                return "MM/DD/YYYY";
            case "de":
                return "D.M.YYYY";
            case "es":
                return "DD/MM/YYYY";
            case "pt-BR":
                return "DD/MM/YYYY";
            default:
                return "MM/DD/YYYY";
        }
    }
    
    public getLocale(): string {
        switch (this.currentLanguage) {
            case "en":
                return "en-US";
            case "de":
                return "de-DE";
            case "es":
                return "es-ES";
            case "pt-BR":
                return "pt-BR";
            default:
                return "en-US";
        }
    }
}

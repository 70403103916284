import { Component, Injector } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    templateUrl: './layout-iframe.component.html',
    animations: [appModuleAnimation()],
    styleUrls: ['./layout-iframe.component.css']
})

export class LayoutIframeComponent {
    constructor(
    ) {
    }
}
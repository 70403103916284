import { Component, OnInit, Injector, Output, EventEmitter, Input, Renderer2} from '@angular/core';

import { MatDialog } from '@angular/material/dialog/';

import { AppComponentBase } from '@shared/common/app-component-base';


import { IAllElements, LayoutElementType, OperationAcquisitionOption } from '../../models';
import { CardAcquisitionFlowOperation, ICommonOptions, ICommonOptionsDialogs } from '../../models-basic';
import { OperationAcquisitionOptionListDto } from '@shared/service-proxies/service-proxies';
import { CommonOptionsModal } from '../../basic/common-options-modal.component';


// import { AddLayoutConfigModal } from '../../awg-add-layout-config-modal.component';
// import { IAllElements, LayoutElementType } from '../models';




@Component({
    selector: 'layout',
    template: `
        <div cdkDropListGroup >
            
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Header
                    </mat-panel-title>
                    </mat-expansion-panel-header>
                    <layout-elements-service 
                        connectedTo="toList" 
                        listName="fromList"
                        (list)='getElements($event, "header")'
                        (new-element)="getNewElement($event)"
                        owner="header"
                        [from-db-list]='allElements["header"]'
                        [baseCDN]="baseCDN"
                        flow-type="acq"
                        [service-id]="serviceId"
                        [service-operation]="serviceOperation"
                        [common-flow-options]="commonFlowOptions"
                    ></layout-elements-service>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{l('Body')}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="render-result-container layout-body-container" *ngIf="serviceOperation.acquisitionFlowOperation.operation.name == 'RenderResult' ">
                    <mat-form-field class="col-md-6 acq-render-result-select"  *ngIf="serviceOperation.renderResultCases.length > 0">
                        <mat-label>Select Case</mat-label>
                        <mat-select [(value)]="serviceOperation.selectedCase" (selectionChange)="onChangeCase($event)">
                            <mat-option class="case-options" *ngFor="let case of serviceOperation?.selectOptions;"
                                [value]="case.slug">{{case.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div  *ngIf="serviceOperation.renderResultCases.length == 0">
                        {{l('NoRenderResultCases')}}
                    </div>
                    <div  *ngFor="let field of serviceOperation?.bodyValues;" class="render-result-inputs">
                        
                        <div  [ngClass]="{'hide':field.renderResultCase != serviceOperation.selectedCase}">
                            <ng-container 
                                ampInput
                                [option]="field"
                                [group]="serviceOperation.form"
                                [baseCDN]="baseCDN"
                                [serviceId]="serviceId"
                                (on-text-change)="getRenderResultValue($event)"
                                (searchOptionEvent)="openDialog(field, serviceOperation.form)"
                                
                            ></ng-container>
                            <i class="fas fa-search search-flow-options render-result-flow-option" (click)="openDialog(field, serviceOperation.form)"></i>
                        </div>
                    </div>
                </div>

                <div class="no-render-result-container layout-body-container" *ngIf="serviceOperation.acquisitionFlowOperation.operation.name != 'RenderResult' ">
                  
                    <div  *ngFor="let field of serviceOperation.bodyValues;" class="render-result-inputs">
                        
                        <ng-container 
                        ampInput
                        [option]="field"
                        [group]="serviceOperation.form"
                        [baseCDN]="baseCDN"
                        [serviceId]="serviceId"
                        ></ng-container>
                        <i class="fas fa-search search-flow-options render-result-flow-option" (click)="openDialog(field, serviceOperation.form)"></i>
                    </div>
                </div>

            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Footer
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <layout-elements-service 
                    connectedTo="fromList" 
                    listName="toList"
                    (list)='getElements($event, "footer")' 
                    (new-element)="getNewElement($event)"
                    owner="footer"
                    [from-db-list]='allElements["footer"]'
                    [baseCDN]="baseCDN"
                    flow-type="acq"
                    [service-id]="serviceId"
                    [service-operation]="serviceOperation"
                    [common-flow-options]="commonFlowOptions"
                >
                </layout-elements-service>
                
            </mat-expansion-panel>
        </div>
    `,
    styleUrls: ['./layout-basic.components.less']
})
export class LayoutBasicComponent extends AppComponentBase implements OnInit {

    @Input('service-operation') serviceOperation : CardAcquisitionFlowOperation;
    @Input('common-flow-options') commonFlowOptions : ICommonOptions;
    @Input('service-id') serviceId : number;
    @Output('new-layout') newLayout = new EventEmitter<string>();
    @Output('on-change-render-case') onChangeRenderCase = new EventEmitter<CardAcquisitionFlowOperation>(null);

    @Output('layouts') layouts = new EventEmitter<ILayout>(null);


    baseCDN = this.getAmpBaseUrlsForEnviroment.acquisition;
    pathForm;
    newElement;
    background
    allElements: IAllElements = {
        header: [],
        footer: []
    };
    bodyOptions:OperationAcquisitionOptionListDto[] = [];

    selectOptions : any[] = [];
    selectedCase : string;
    
    constructor(
        injector: Injector,
        public dialog: MatDialog,
        private renderer: Renderer2,

    ) {
        super(injector);
    }

    onChangeCase($event) {
        // console.log(this.selectedCase);
        this.onChangeRenderCase.emit(this.serviceOperation)
        
    }


    getRenderResultValue($event){
        
    }

    openDialog(field:OperationAcquisitionOption, form): void {
        let values = Object.values(form.value);
        let strings = this.commonFlowOptions.strings.filter(option=> values.indexOf(option.value) == -1);
        this.commonFlowOptions.strings = strings;
        let dialogData:ICommonOptionsDialogs = {
            commonOptions : this.commonFlowOptions,
            field : field,
            baseCDN:this.baseCDN,
            serviceId:this.serviceId
        }
		const dialogRef = this.dialog.open(CommonOptionsModal, {data: dialogData});

		dialogRef.afterClosed().subscribe(result => {
            if (result.data) {
                form.controls[field.slug].setValue(result.data.value);
            }
		});
	}
  


    ngOnInit(){
        this.allElements = this.serviceOperation.allElements;

        if (this.serviceOperation.acquisitionFlowOperation.operation.name ==  "RenderResult") {
            this.selectOptions = this.serviceOperation.selectOptions;
            if (this.selectOptions.length > 0) {
                this.serviceOperation.selectedCase = this.selectOptions[0].slug;
            } else {
                this.serviceOperation.selectedCase = this.l('NotCaseSavedInVendor');
            }
        }
    }
    

     // FUNCIONALIDADES LAYOUT
    
    getElements(elements: LayoutElementType[], parent: string) {
        elements.forEach(el => {
            if (el.type =="img" && !el.basicFile.match("images/")) {
                el.basicFile = "images/"+el.basicFile;
            }
        })
        this.allElements[parent] = elements;

        let emit: ILayout = {serviceOperation:this.serviceOperation, allElements:this.allElements}
        this.layouts.emit(emit);
    }

    getNewElement(element: LayoutElementType) {
        this.newElement = Object.assign({}, element);
    }
    
}



export interface ILayout {
    serviceOperation: CardAcquisitionFlowOperation,
    allElements : IAllElements
}

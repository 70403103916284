import { Component, Output, EventEmitter, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'input-text',
    styleUrls: ['./input-component.css'],
    template: `
        <mat-form-field [formGroup]="group">
            <input matInput [placeholder]="l(config.slug)" [formControlName]="config.slug" (keyup)="getValue()" (blur)="onblur()">
        </mat-form-field>
    `
})
export class InputTextComponent extends AppComponentBase {
    config;
    group: FormGroup;
    @Output() output = new EventEmitter();
    @Output() onBlurEvent? = new EventEmitter();

    constructor(injector:Injector) {
        super(injector);
    }

    ngOnInit() {
        this.config;
        this.group;
    }

    getValue(){
        let valueToEmit = {
            isBodyConfig:true,
            isText : true, 
            input : {
                name:this.config.slug,
                value: this.group.controls[this.config.slug].value
            }
        }
        this.output.emit(valueToEmit);
    }

    onblur(){
        this.onBlurEvent.emit();
    }
}


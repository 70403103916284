import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, forwardRef } from '@angular/core';

import * as ace from 'ace-builds';
import { AceEditorMode } from './modes.types';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'amp-ace-editor',
    templateUrl: './amp-ace-editor.component.html',
    styles: [
        `
            :host {
                display: block;
                width: 100%;
                height: 100%;
                min-height: 250px;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AmpAceEditorComponent),
        },
    ],
})
export class AmpAceEditorComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    @ViewChild('editor') private editor: ElementRef<HTMLElement>;

    @Input() mode: AceEditorMode = 'html';

    private  aceEditor: ace.Ace.Editor;
    value: string = '';
    disabled: boolean;

    get codeAnnotations() {
        return this.aceEditor?.getSession()?.getAnnotations() || [];
    }
    constructor() {}

    writeValue(value: any): void {
        setTimeout(() => {
            this.aceEditor.session.setValue(value);
            this.value = value;
        }, 0);
    }

    onChange: (value?: any) => void;

    onTouch: (event: any) => void;

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.aceEditor.setReadOnly(true);
        } else {
            this.aceEditor.setReadOnly(false);
        }
    }

    ngAfterViewInit(): void {
        ace.config.set('fontSize', '14px');
        this.aceEditor = ace.edit(this.editor.nativeElement);
        //See all other themes https://github.com/ajaxorg/ace/tree/master/src/theme
        this.aceEditor.setTheme('ace/theme/monokai');
        this.aceEditor.session.setMode(`ace/mode/${this.mode}`);

        this.aceEditor.on('input', () => {
            this.value = this.aceEditor.getValue();
            this.onChange(this.value);
        });

    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.aceEditor.destroy();
    }

    onTouched(value) {
        if (this.onTouch) {
            this.onTouch(value);
        }
    }
}

import { AfterViewInit, Component, EventEmitter, Injector, OnInit, Input, Output, ViewChild, ViewEncapsulation, NgZone, HostBinding, ElementRef, HostListener, Inject, LOCALE_ID, SimpleChanges } from '@angular/core';
import { CommonLookupModalComponent } from '@app/shared/common/lookup/common-lookup-modal.component';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DomHelper } from '@shared/helpers/DomHelper';
import { HttpClient } from '@angular/common/http';
import { FileUpload } from 'primeng/fileupload';
import { finalize, shareReplay } from 'rxjs/operators';
import { LocalStorageService } from '@shared/utils/local-storage.service';
import { filter as _filter, map as _map, forEach as _forEach, min as _min, reduce as _reduce, round } from 'lodash-es';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { MatFormField } from '@angular/material/form-field';
import { MatMenu } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleAppearance } from '@angular/material/button-toggle';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeRatesDailyDto, FinanceServiceProxy } from '@shared/service-proxies/service-proxies';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { I, T } from '@angular/cdk/keycodes';
import { AppCommonModule, DatepickerComponent } from '@app/shared/common/app-common.module';



interface monedaObjectInterface {
    name: string,
    code: string,
    exchange: number
}



@Component({
    templateUrl: './calculator.component.html',
    selector: 'calculator',
    styleUrls: ['./calculator.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class CalculatorComponent extends AppComponentBase implements OnInit, AfterViewInit {

    @ViewChild("input1") firstInput: MatFormField;
    @ViewChild("input2") secondInput: MatFormField;
    @ViewChild("menu1") menu1;
    menu1Name: string;
    menu2Name: string;
    input1: number;
    input2: number;
    monthAverage: number;
    dateType: string = "single";
    dateGranularity: string = "day";
    date: DateTime = DateTime.now().minus({day:1});
    monedas: Observable<Array<ChangeRatesDailyDto>>;
    firstCurrency: string = "";
    secondCurrency: string = "";
    firstExchangerate: number;
    firstMonthAvgExchangeRate: number;
    secondExchangerate: number;
    secondMonthAvgExchangeRate: number;
    exchange: number;
    @Output() onProgress: EventEmitter<any> = new EventEmitter();

    public progress = 0;
    uploadUrl: string;
    isFileSelected = false;

    @HostBinding('attr.ktOffcanvas')

    @HostBinding('style.overflow') styleOverflow: any = 'hidden';

    mQuickSidebarOffcanvas: any;

    serverClientTimeDifference = 0;
    
    _isOpen: boolean;
    _pinned = true;

    get isOpen(): boolean {
        return this._isOpen;
    }

    get pinned(): boolean {
        return this._pinned;
    }
    
    constructor(
        private el: ElementRef,
        injector: Injector,
        private _localStorageService: LocalStorageService,
        private _financeServiceProxy: FinanceServiceProxy,
        private _httpClient: HttpClient,
        private _dateTimeService: DateTimeService,
        public activateRouter: ActivatedRoute,
        public router: Router,
        public _zone: NgZone
    ) {
        super(injector);
        this.uploadUrl = AppConsts.remoteServiceBaseUrl + '/Chat/UploadFile';
    }
    
    ngOnInit(): void {
        this.registerEvents();
        this.monedas = this._financeServiceProxy.getChangeRateDayInfo(this.date.toFormat("yyyy-MM"), String(this.date.get("day"))).pipe(shareReplay(1));
        this.monedas.subscribe(element =>{
            let argentineCurrency = element.find(currency => currency.currencyId == "ARS");
            let USDCurrency = element.find(currency => currency.currencyId == "USD")
            this.menu1Name = USDCurrency.currency;
            this.menu2Name = argentineCurrency.currency;
            this.firstCurrency = USDCurrency.currencyId
            this.secondCurrency = argentineCurrency.currencyId;
            this.firstExchangerate = USDCurrency.dailyAverage;
            this.secondExchangerate = argentineCurrency.dailyAverage;
            this.exchange = this.secondExchangerate / this.firstExchangerate;
            this.firstMonthAvgExchangeRate = USDCurrency.monthlyAverage;
            this.secondMonthAvgExchangeRate = argentineCurrency.monthlyAverage
            this.monthAverage = round(this.firstMonthAvgExchangeRate / this.secondMonthAvgExchangeRate,2);
        })
    }

    changeFirstCurrency(id){
        this.monedas.subscribe(element => {
            element.forEach(moneda => {
                if (moneda.currencyId == id){
                    this.firstCurrency = moneda.currencyId;
                    this.firstExchangerate = moneda.dailyAverage;
                    this.firstMonthAvgExchangeRate = moneda.monthlyAverage;
                    this.exchange = this.secondExchangerate / this.firstExchangerate;
                    this.monthAverage = round(this.firstMonthAvgExchangeRate / this.secondMonthAvgExchangeRate,2);
                    this.secondInput._control.value = this.firstInput._control.value != "" ? this.firstInput._control.value / this.exchange : null;
                    this.menu1Name = moneda.currency
                }
            })
        })
        
    }

    changeSecondCurrency(id){
        this.monedas.subscribe(element => {
            element.forEach(moneda => {
                if (moneda.currencyId == id){
                    this.secondCurrency = moneda.currencyId;
                    this.secondExchangerate = moneda.dailyAverage;
                    this.secondMonthAvgExchangeRate = moneda.monthlyAverage;
                    this.exchange = this.secondExchangerate / this.firstExchangerate;
                    this.monthAverage = round(this.firstMonthAvgExchangeRate / this.secondMonthAvgExchangeRate,2);
                    this.secondInput._control.value = this.firstInput._control.value != "" ? this.firstInput._control.value / this.exchange : null;
                    this.menu2Name = moneda.currency;
                }
            })
        })
    }

    firstInputEvent(){
        this.secondInput._control.value = this.firstInput._control.value / this.exchange
        if(this.firstInput._control.value == ""){
            this.secondInput._control.value = null
        }
    }

    secondInputEvent(){
        this.firstInput._control.value = this.secondInput._control.value * this.exchange
        if(this.secondInput._control.value == ""){
            this.firstInput._control.value = null
        }
    }

    selectDate(event){
        let date = DateTime.fromFormat(event.date, "yyyy-MM-dd")
        this.monedas = this._financeServiceProxy.getChangeRateDayInfo(date.toFormat("yyyy-MM"), String(date.get("day")))
        this.monedas.subscribe(element =>{
            let firstCurrency = element.find(currency => currency.currencyId == this.firstCurrency);
            let secondCurrency = element.find(currency => currency.currencyId == this.secondCurrency);
            this.firstExchangerate = firstCurrency.dailyAverage;
            this.firstMonthAvgExchangeRate = firstCurrency.monthlyAverage;
            this.secondExchangerate = secondCurrency.dailyAverage;
            this.secondMonthAvgExchangeRate = secondCurrency.monthlyAverage;
            this.exchange = this.secondExchangerate / this.firstExchangerate;
            this.monthAverage = this.firstMonthAvgExchangeRate / this.secondMonthAvgExchangeRate;
            this.secondInput._control.value = this.firstInput._control.value != "" ? this.firstInput._control.value / this.exchange : null
        })
    }

    quickSideBarBackClick(): void {
    }

    ngAfterViewInit(): void {
        this.mQuickSidebarOffcanvas = new KTOffcanvas('pt_quick_sidebar', {
            overlay: false,
            baseClass: 'offcanvas',
            placement: 'left',
            closeBy: 'pt_quick_sidebar_close',
            toggleBy: 'pt_quick_sidebar_toggle'
        });

        this.mQuickSidebarOffcanvas.events.push({
            name: 'afterHide',
            handler: () => {
            }
        }, {
            name: 'afterShow',
            handler: () => {
            }
        });
    }

    registerEvents(): void {
        const self = this;
        function onConnected() {
        }

        abp.event.on('app.show.quickUserPanel', () => {
            this.mQuickSidebarOffcanvas.hide();
        });
    }

    showCalculatorPanel(): void {
        document.body.className += ' offcanvas-on';
        document.getElementById('pt_quick_sidebar').className += ' offcanvas-on';
    }

    onWindowResize(event): void {
    }
}

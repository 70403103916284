import { Injectable } from "@angular/core";
import {IAjaxResponse, TokenService} from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class UploadFileService {
    public uploader: FileUploader;
    private _uploaderOptions: FileUploaderOptions = {};
    private pendingFiles: { file: File, path: string }[] = [];
    private backupFiles: { originalPath: string, backupPath: string }[] = [];
    private restoredBackupFiles: { originalPath: string, backupPath: string }[] = [];
    constructor(private _tokenService: TokenService, private http: HttpClient){

    }
    initFileUploader(): FileUploader {

        this.uploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + '/Upload/UploadAccessControlElement' });
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            form.append('FileType', fileItem.file.type);
            form.append('FileName', 'ProfilePicture');
            form.append('FileToken', this.guid());
        };

        this.uploader.onSuccessItem = (item, response, status) => {
            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                console.log('subida')
                // se subio correctamente la imagen
            } else {
                console.log('fallo')
                //no se subio 
            }
        };

        this.uploader.setOptions(this._uploaderOptions);

        return this.uploader;
    }

    initFileUploaderAcquisitionFlow(): FileUploader {
        this.uploader = new FileUploader({});
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
    
        this.uploader.setOptions(this._uploaderOptions);
    
        return this.uploader;
    }


    queueFileForUpload(file: File, path: string) {
        this.pendingFiles.push({ file, path });
    }

    queueFileForBackup(originalPath: string, backupPath: string) {
        this.backupFiles.push({originalPath, backupPath });
    }

    removeFileFromQueueForBackup(fileName : string)
    {
      const indexToDelete = this.backupFiles.findIndex(item => item.originalPath.match(fileName));
      if (indexToDelete !== -1) {
        this.backupFiles.splice(indexToDelete, 1);
      }
    }

    queueFileForRestoredBackupFiles(originalPath: string, backupPath: string) {
      this.restoredBackupFiles.push({originalPath, backupPath });
    }

    removeFileFromQueueForRestoredBackup(fileName : string)
    {
      const indexToDelete = this.restoredBackupFiles.findIndex(item => item.originalPath.match(fileName));
      if (indexToDelete !== -1) {
        this.restoredBackupFiles.splice(indexToDelete, 1);
      }
    }


    cleanFile(tempId: number)
    {
        this.pendingFiles = this.pendingFiles.filter(item => item.file.lastModified !== tempId);
    }

    uploadPendingFiles() : Promise<any>
    {
      if (this.pendingFiles.length === 0) {
          return Promise.resolve({
            success: true,
            message: 'No files to upload',
          });
      }
    
      if (this.pendingFiles.length > 0) {
          for (const fileWithPath of this.pendingFiles) {
              const uploadUrl = AppConsts.remoteServiceBaseUrl + `/Upload/UploadAcquisitionControlElementFileScriptOrStylesheet?filePath=${fileWithPath.path}`;
              const fileItem = new FileItem(this.uploader, fileWithPath.file, this._uploaderOptions);
              fileItem.withCredentials = false;
              fileItem.url = uploadUrl;
              
              this.uploader.queue.push(fileItem)
          }
        let promise = new Promise((resolve, reject) => {
            this.uploader.onSuccessItem = (item, response) => {
                const result = {
                    success: true,
                    message: 'File uploaded successfully',
                    item: item,
                    response: response
                };
                resolve(result);
            };
            this.uploader.onErrorItem = (item, response) => {
                const result = {
                    success: false,
                    message: 'File upload failed',
                    item: item,
                    response: response
                };
                reject(result);
            };
        });

        this.uploader.uploadAll();
        this.pendingFiles = [];
        return promise;
        }
      }
   
   async uploadBackupFiles(): Promise<any> {
    if (this.backupFiles.length > 0) {
      const promises: Promise<any>[] = [];
  
      for (const fileWithPaths of this.backupFiles) {
        const originalPath = encodeURIComponent(fileWithPaths.originalPath);
        const backupPath = encodeURIComponent(fileWithPaths.backupPath);
  
        const uploadUrl = AppConsts.remoteServiceBaseUrl + `/Upload/MoveFileToBackup?originalPath=${originalPath}&backupPath=${backupPath}`;
  
        const promise = new Promise<any>((resolve, reject) => {
          this.http.get(uploadUrl).subscribe(
            () => {
              console.log(`File backup successfully: ${fileWithPaths.originalPath} to ${fileWithPaths.backupPath}`);
              resolve({
                success: true,
                message: 'File backup successful',
              });
            },
            (error) => {
              console.error(`File backup failed: ${fileWithPaths.originalPath} to ${fileWithPaths.backupPath}`, error);
              reject({
                success: false,
                message: 'File backup failed',
                error: error,
              });
            }
          );
        });
  
        promises.push(promise);
      }
  
      this.backupFiles = [];
  
      try {
        const results = await Promise.all(promises);
        const allSuccessful = results.every((result) => result.success === true);
        const message = allSuccessful ? 'All files backed up successfully' : 'Some files failed to backup';
        return {
          success: allSuccessful,
          message: message,
          results: results, 
        };
      } catch (error) {
        throw error;
      }
    } else {
      return {
        success: true,
        message: 'No files to backup',
      };
    }
  }

  async restoreFileFromBackup(): Promise<any> {
    if (this.restoredBackupFiles.length > 0) {
      const promises: Promise<any>[] = [];
  
      for (const fileWithPaths of this.restoredBackupFiles) {
        const destinationPath = encodeURIComponent(fileWithPaths.originalPath);
        const backupPath = encodeURIComponent(fileWithPaths.backupPath);
  
        const uploadUrl = AppConsts.remoteServiceBaseUrl + `/Upload/MoveFileFromBackup?destinationPath=${destinationPath}&backupPath=${backupPath}`;
  
        const promise = new Promise<any>((resolve, reject) => {
          this.http.get(uploadUrl).subscribe(
            () => {
              console.log(`File backup successfully: ${fileWithPaths.originalPath} to ${fileWithPaths.backupPath}`);
              resolve({
                success: true,
                message: 'File backup successful',
              });
            },
            (error) => {
              console.error(`File backup failed: ${fileWithPaths.originalPath} to ${fileWithPaths.backupPath}`, error);
              reject({
                success: false,
                message: 'File backup failed',
                error: error,
              });
            }
          );
        });
  
        promises.push(promise);
      }
  
      this.restoredBackupFiles = [];
  
      try {
        const results = await Promise.all(promises);
        const allSuccessful = results.every((result) => result.success === true);
        const message = allSuccessful ? 'All files moved from back up successfully' : 'Some files failed to move from backup';
        return {
          success: allSuccessful,
          message: message,
          results: results, 
        };
      } catch (error) {
        throw error;
      }
    } else {
      return {
        success: true,
        message: 'No files to backup',
      };
    }
  }

    GetFilesInFolder (folderPath : string, fileExtension : string) : Observable<string[]>
    {
      const uploadUrl = AppConsts.remoteServiceBaseUrl + `/Upload/GetFileNamesInFolder?folderPath=${folderPath}&fileExtension=${fileExtension}`;
      return this.http.get<string[]>(uploadUrl);
    }
      

    guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        // return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        return '';
    }

    uploadFile(file){
        let files: File[] = [file];
        this.uploader.clearQueue();
        this.uploader.addToQueue(files);
        this.uploader.uploadAll();
    }
}
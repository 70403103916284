import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'amp-entity-redirect',
  templateUrl: './entity-redirect.component.html',
  styleUrls: ['./entity-redirect.component.css']
})
export class AmpEntityRedirectComponent implements OnInit {


  redirectPath: string
  @Input() resolvedUrlPath: string[];

  @Input() showAsBlueLink: boolean = false;
  @Input() name: string;
  @Input() id: number;

  @Input() set entityType(value: string) {
    const entity = value.toLowerCase()
    switch (entity){
      case 'service':
      case 'services':
        this.redirectPath = `/app/biz-dev/services`;
        break;
      case 'channel':
      case 'channels':
        this.redirectPath = '/app/growth/channels';
        break;
      case 'channeltype':
      case 'channeltypes':
        this.redirectPath = '/app/growth/channelType';
        break;
      case 'agency':
      case 'agencies':
        this.redirectPath = '/app/growth/agencies';
        break;
      case 'product':
      case 'products':
        this.redirectPath = '/app/product/products';
        break;
      case 'vendor':
      case 'vendors':
        this.redirectPath = '/app/biz-dev/vendors';
        break;
      case 'campaign':
      case 'campaigns':
        this.redirectPath = '/app/growth/campaigns';
        break;
      case 'product-provider':
        this.redirectPath = '/app/product/providers';
        break;
      case 'license-type':
        this.redirectPath = '/app/product/license-types';
        break;
      case 'license-providers':
        this.redirectPath = '/app/product/license-providers';
        break;
      default :
        console.log('Entity does not exist');
    }
  }
  
  constructor() { }

  ngOnInit() {
  }

}

import { Component, Injector, ViewChild, Input, Output, SimpleChanges } from '@angular/core';

import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import { EntityContactDto, ContactDto, ProvisionerContactServiceProxy, AggregatorContactServiceProxy, VendorContactServiceProxy, AgencyContactServiceProxy, ProductContactServiceProxy, EntityContactsServiceProxy } from '@shared/service-proxies/service-proxies';
import { entityContactComponent } from '@app/shared/common/entityContact/common-entity-contact-modal.component';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Observable } from 'rxjs';


@Component({
    selector: 'amp-contacts-list',
    template: `
        <mat-list role="list">
            <div *ngIf="stringContacts.length == 0">
                No contacts registered yet
            </div>
            <div *ngIf="stringContacts.length > 0">
                <amp-contact-item  *ngFor="let c of stringContacts; let i = index" [item]="c" [pos]="i" (id-to-delete)="getIdToDelete($event)" [entity-name]="proxyName" [entity-id]="id"></amp-contact-item>
            </div>
            <commonEntityContactModal [proxy]="proxy" [entity-name]="proxyName" [action-type]="'create'" #entityContactModal (itemSelected)="contactSelected($event)" [entity-id]="id"></commonEntityContactModal>
        </mat-list>
        <div class="contact-list-footer">
            <button type="button" mat-flat-button color="primary" (click)="showEntityContactModal()">
                <i class="fas fa-user-plus"></i>
                <span>
                    Create
                </span>
            </button>
        </div>
        
    `,
    animations: [
        appModuleAnimation(),
    ],
    styleUrls: ['./amp-contacts-list.css']
})
export class AmpContactsList extends AppComponentBase {

    @Input('id') id: number;
    @Input('type') type: string = 'input';
    @Input('proxy-name') proxyName: string;
    @Output('proxy') proxyT = new EventEmitter();

    proxy: any;
    proxyFunctionName: string;
    contacts: any;
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    stringContacts: string[] = [];
    @ViewChild('entityContactModal', {static:true}) entityContactModal: entityContactComponent;


    constructor(
        injector: Injector,
        public _provisionerContactService: ProvisionerContactServiceProxy,
        public _aggregatorContactService: AggregatorContactServiceProxy,
        public _vendorsrContactService: VendorContactServiceProxy,
        public _agencyContactService: AgencyContactServiceProxy,
        public _productContactService: ProductContactServiceProxy,
        private entityContactsAppService: EntityContactsServiceProxy,
    ) {
        super(injector);
    }

    getFunctionProxyName() {

        
        let fnName: string;
        switch (this.proxyName) {
            case 'agency':
                fnName = 'getAgencyContacts';
                break;
            case 'provisioner':
                fnName = 'getProvisionersContacts';
                break;
            case 'vendor':
                fnName = 'getVendorContacts';
                break;
            case 'product':
                fnName = 'getProductContacts';
                break;
            case 'aggregator':
                fnName = 'getAggregatorContacts';
                break;
        }

        return fnName;
    }

    ngOnInit(): void {
        let proxyName = `_${this.proxyName}ContactService`;
        let proxyFunctionName = this.getFunctionProxyName();
        this.proxy = this[proxyName];
        let getEntityContacts$: Observable<EntityContactDto[]> = this.proxy[proxyFunctionName](this.id);
        if (this.id) {
            getEntityContacts$.subscribe(contacts => {
                this.contacts = contacts;
                for (let contact of contacts) {
                    this.stringContacts.push(JSON.stringify(contact.contact));
                }
            })
        }
       
    }


    contactSelected(contact: ContactDto) {
        this.stringContacts.push(JSON.stringify(contact));
    }

    showEntityContactModal(): void {
        this.entityContactModal.show();

        this.entityContactModal.entityContactDto = new EntityContactDto();
        this.entityContactModal.entityContactDto.contact = new ContactDto();
    }

    getIdToDelete($event) {
        
        let id = parseInt($event.id);
        let pos = parseInt($event.pos);
        this.message.confirm(
            '','', 
            (isConfirmed) => {
                if (isConfirmed) {
                    this.entityContactsAppService.deleteEntityContact(id)
                        .subscribe(() => {
                            this.notify.success(this.l('SuccessfullyDeleted'));
                            this.stringContacts.splice(pos, 1);
                        });
                }
            }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes);
        
    }

}



import { ChangeDetectorRef, Component, Inject, Injector, Input, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ServicePricingModelListDto, ServicePricingModelServiceProxy } from '@shared/service-proxies/service-proxies';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
    selector: 'services-pricing-models-table',
    styleUrls: ['services-pricing-model-table.component.less'],
    templateUrl: 'services-pricing-model-table.component.html',
})
export class ServicesPricingModelTable extends AppComponentBase {

    displayedColumns: string[] = ['id', 'name', 'startDate','endDate'];
    dataSource : Array<ServicePricingModelListDto> = [];
    @ViewChild('pricePointsTable', { static: true }) table: MatTableDataSource<any>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @Input() data: Array<ServicePricingModelListDto> = [];
    @Input('parent-services-id') parentServicesIds: number[] = [];
    @Input('with-actions') withActions: boolean = false;
    @Output('on-delete') onDelete = new EventEmitter<boolean>(false);
    constructor(
        // public dialogRef: MatDialogRef<ServicesPricingModelTable>,
        // @Inject(MAT_DIALOG_DATA) public data: Array<ServicePricingModelListDto>,
        public _servicePricingModelServiceProxy: ServicePricingModelServiceProxy,
        private changeDetectorRefs: ChangeDetectorRef,
        public router: Router,
        injector: Injector
    ) {
        super(injector);
        this.data = [];
        
    }
    
    ngOnInit() {

        if (this.withActions) {
            this.displayedColumns = ['id', 'name','startDate', 'endDate', 'actions']
        }
    }

    // ngAfterViewInit() {
       
    // }



    goToUrl(permission: string, route: string[], queryParams?) {

        if (!this.validatePermissionAction(permission)) {
            return false
        } else {
            if (queryParams) {
                this.router.navigate(route, { queryParams: queryParams });
            } else {
                this.router.navigate(route);
            }
        }
    }

    deleteServicePricingModel(spm: ServicePricingModelListDto): void {

        if (!!this.parentServicesIds.find(sId=> sId == spm.serviceId)) {
            this.message.error(this.l('ServiceWithParent'));
            return;
        }
        const isActive = spm.startDate < DateTime.now()//.isBefore(new Date());
        
        if (this.data.length == 1) {
            this.message.error(this.l('SerivcesPricingModelCannotEmpty'));
            return;
        }
        if (isActive) {
            this.message.error(this.l('CannotDeleteServicePricingModel'));
            return;
        } else {
            
            this.message.confirm(
                '',
                this.l('ConfirmDeleteServicePricingModel'),
                isConfirmed => {
                    if (isConfirmed) {
                        this._servicePricingModelServiceProxy.deleteServicePricingModel(spm.id).subscribe(data => {
                            this.removeServicePricingModel(spm.id);
                            this.notify.success(this.l('SuccessfullyDeleted'));
                            console.log(data);
                            console.log(this.data);
                            // this.loadServicePricingModelTable()
                        })
                    }
                }
            );
            
        }


    }

    removeServicePricingModel(id :number):void {
        let tempDataSource: ServicePricingModelListDto[] = _.clone(this.data);
        _.remove(tempDataSource, (spm) => spm.id == id);
        this.data = tempDataSource;
        this.changeDetectorRefs.detectChanges();
        this.onDelete.emit(true);
    }
    
}


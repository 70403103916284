import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { AmpCommonModule } from '@shared/common/common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule, BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { PieChartModule, AreaChartModule, LineChartModule, BarChartModule } from '@swimlane/ngx-charts';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { CustomizableDashboardComponent } from './customizable-dashboard/customizable-dashboard.component';
import { WidgetGeneralStatsComponent } from './customizable-dashboard/widgets/widget-general-stats/widget-general-stats.component';
import { DashboardViewConfigurationService } from './customizable-dashboard/dashboard-view-configuration.service';
import { GridsterModule } from 'angular-gridster2';
import { WidgetDailySalesComponent } from './customizable-dashboard/widgets/widget-daily-sales/widget-daily-sales.component';
import { WidgetEditionStatisticsComponent } from './customizable-dashboard/widgets/widget-edition-statistics/widget-edition-statistics.component';
import { WidgetHostTopStatsComponent } from './customizable-dashboard/widgets/widget-host-top-stats/widget-host-top-stats.component';
import { WidgetIncomeStatisticsComponent } from './customizable-dashboard/widgets/widget-income-statistics/widget-income-statistics.component';
import { WidgetMemberActivityComponent } from './customizable-dashboard/widgets/widget-member-activity/widget-member-activity.component';
import { WidgetProfitShareComponent } from './customizable-dashboard/widgets/widget-profit-share/widget-profit-share.component';
import { WidgetRecentTenantsComponent } from './customizable-dashboard/widgets/widget-recent-tenants/widget-recent-tenants.component';
import { WidgetRegionalStatsComponent } from './customizable-dashboard/widgets/widget-regional-stats/widget-regional-stats.component';
import { WidgetSalesSummaryComponent } from './customizable-dashboard/widgets/widget-sales-summary/widget-sales-summary.component';
import { WidgetSubscriptionExpiringTenantsComponent } from './customizable-dashboard/widgets/widget-subscription-expiring-tenants/widget-subscription-expiring-tenants.component';
import { WidgetTopStatsComponent } from './customizable-dashboard/widgets/widget-top-stats/widget-top-stats.component';
import { FilterDateRangePickerComponent } from './customizable-dashboard/filters/filter-date-range-picker/filter-date-range-picker.component';
import { AddWidgetModalComponent } from './customizable-dashboard/add-widget-modal/add-widget-modal.component';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CountoModule } from 'angular2-counto';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { SingleLineStringInputTypeComponent } from './input-types/single-line-string-input-type/single-line-string-input-type.component';
import { ComboboxInputTypeComponent } from './input-types/combobox-input-type/combobox-input-type.component';
import { CheckboxInputTypeComponent } from './input-types/checkbox-input-type/checkbox-input-type.component';
import { MultipleSelectComboboxInputTypeComponent } from './input-types/multiple-select-combobox-input-type/multiple-select-combobox-input-type.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PasswordInputWithShowButtonComponent } from './password-input-with-show-button/password-input-with-show-button.component';
import { KeyValueListManagerComponent } from './key-value-list-manager/key-value-list-manager.component';
import { SubHeaderComponent } from '@app/shared/common/sub-header/sub-header.component';

//AMP COMPONENTS, PIPES, DIRTECTIVES
import { entityContactComponent } from './entityContact/common-entity-contact-modal.component';
import { SafePipe } from '@shared/utils/safe-pipe';
import { GuidToName } from '@shared/common/pipes/file-name.pipe';
import { AmpBackBtn } from '@shared/common/amp-back-btn/amp-back-btn';
import { AmpTable } from '@shared/common/amp-table/amp-table';
import { TableDataSource } from '@shared/common/amp-table/table-data-source';
import { EntityComponentHeader } from '@shared/common/entity-component-header/entity-component-header';
import { AmpContactsList } from '@shared/common/amp-contacts-list/amp-contacts-list';
import { ImagePreloadDirective, DoubleClickDirective, CopyClipboardDirective } from '@shared/common/directives';
import { OptionFieldDirective } from './../../biz-dev/services/acquisition-flow/directives/dinamyc-field.directive';
import { AmpContactItem } from '@shared/common/amp-contact-item/amp-contact-item';
import { AvatarModule } from 'ngx-avatar';
import { FileUploadModule } from 'ng2-file-upload';
import { MatFormFieldModule } from '@angular/material/form-field/';
import { MatInputModule } from '@angular/material/input/';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select/';
import { MatCardModule } from '@angular/material/card/';
import { MatExpansionModule } from '@angular/material/expansion/';
import { MatButtonModule } from '@angular/material/button/';
import { MatListModule } from '@angular/material/list/';
import { MatIconModule } from '@angular/material/icon/';
import { MatChipsModule } from '@angular/material/chips/';
import { MatSlideToggleModule } from '@angular/material/slide-toggle/';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner/';
import { MatTableModule } from '@angular/material/table/';
import { MatSortModule } from '@angular/material/sort/';
import { MatPaginatorModule } from '@angular/material/paginator/';
import { MatTooltipModule } from '@angular/material/tooltip/';
import { MatNativeDateModule } from '@angular/material/core/';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox/';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { LayoutElementsComponent } from '@app/biz-dev/services/acquisition-flow/components/layout-elements.components';
import { LayoutElementsServiceComponent } from '@app/biz-dev/services/acquisition-flow/components/layout-elements-service';

import { AddLayoutConfigModal } from '@app/biz-dev/services/awg-add-layout-config-modal.component';
import { InputTextComponent } from '@app/biz-dev/services/acquisition-flow/directives/input-text.component';
import { InputPickerComponent } from '@app/biz-dev/services/acquisition-flow/directives/input-picker.component';
import { InputFileComponent } from '@app/biz-dev/services/acquisition-flow/directives/input-file.component';
import { SlideToggleComponent } from '@app/biz-dev/services/acquisition-flow/directives/slide-toggle.component';
import { SortByPipe } from '@app/biz-dev/services/acquisition-flow/advanced/acquisition-flow-advanced.component';
import { LayoutBuilderComponent } from '@app/biz-dev/services/layout-builder/layout-builder.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { UploadFileService } from '@app/upload-file-service';

import { ColorPickerModule as ColorPicker } from 'ngx-color-picker';
import { LayoutBasicComponent } from '@app/biz-dev/services/acquisition-flow/components/layout-basic/layout-basic.components';
import { AmpBreadcrumb } from '@shared/common/amp-breadcrumb/amp-breadcrumb.component';

// import { AppBsModalDirective } from '@shared/common/app-bs-modal.directive';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { createEditVendorNetworkModal } from '@app/biz-dev/vendors/vendors-networks/create-or-edit/create-or-edit-vendor-network-modal.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PricePointsTable } from '@app/biz-dev/services/view-service/price-points-table.component';
import { ServicesPricingModelTable } from '@app/biz-dev/services/view-service/services-pricing-model-table.component';
import { SkeletonTable } from './skeletons/table/table.component';
import { AmpDropdownComponent } from './amp-dropdown/amp-dropdown/amp-dropdown.component';
import { ParserService } from './parsers/custom-parsers.service';
import { AmpPermission } from './directives/AmpPermission.directive';
import { DatepickerComponent } from './date-picker/datePicker.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomFormsModule } from 'ngx-custom-validators';
export { DatepickerComponent } from './date-picker/datePicker.component';
import { InputPlusComponent } from './inputplus/inputplus.component';
import { VendorServiceAccessControlModal } from '@app/biz-dev/vendors/vendor-service-access-control-modal.component';
import { DatePickerMonthRangeComponent } from './date-picker-month-range/date-picker-month-range.component';
import { DatePickerDayRangeComponent } from './date-picker-day-range/date-picker-day-range.component';
import { AmpSpinnerComponent } from '@shared/common/amp-spinner/amp-spinner.component';
import { AmpGlobalSpinnerComponent } from '@shared/common/amp-global-spinner/amp-global-spinner.component';
import { AmpEntityRedirectComponent } from './entity-redirect/entity-redirect.component';
import { RouterModule } from '@angular/router';
import { AmpEditInlineComponent } from '@shared/common/amp-edit-inline/amp-edit-inline.component';
import { EditMessagingModelModalComponent } from '@app/biz-dev/provisioning/messaging-model/edit-messaging-model-modal.component';
import { AmpDynamicPropertiesFormComponent } from './amp-dynamic-properties-form/amp-dynamic-properties-form.component';
import { AmpAutocompleteComponent } from '@shared/common/amp-autocomplete/amp-autocomplete.component';
import { GridComponent } from '@app/finance/exchange-rates/grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AmpTableUnpagedComponent } from '@shared/common/amp-table-unpaged/amp-table-unpaged.component';
import { ViewChangeLogModalComponent } from '@shared/common/amp-changelog/view-changelog-modal.component';
import { AmpAceEditorComponent } from './amp-ace-editor/amp-ace-editor.component';
import { AmpAutocompleteLicenseComponent } from '@shared/common/amp-autocomplete-license/amp-autocomplete-license.component';
import { AmpBillingCycleBadgeComponent } from './amp-billing-cycle-badge/amp-billing-cycle-badge.component';
const maskConfig: Partial<IConfig> = null;

//AMP COMPONENTS, PIPES, DIRTECTIVES

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        AmpCommonModule,
        TableModule,
        PaginatorModule,
        GridsterModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        PieChartModule,
        AreaChartModule,
        LineChartModule,
        BarChartModule,
        BsDatepickerModule.forRoot(),
        PerfectScrollbarModule,
        CountoModule,
        AppBsModalModule,
        AutoCompleteModule,
        //custom imports
        BsDropdownModule.forRoot(),
        PopoverModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatOptionModule,
        MatCardModule,
        MatExpansionModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        TabsModule.forRoot(),
        PerfectScrollbarModule,
        GridsterModule,
        MatChipsModule,
        MatSlideToggleModule,
        AvatarModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatRippleModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        DragDropModule,
        FileUploadModule,
        ColorPicker,
        AngularEditorModule,
        BsDatepickerModule.forRoot(),
        NgxMaskModule.forRoot(maskConfig),
        CustomFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([]),
		AgChartsAngularModule
        //custom imports,
    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        CustomizableDashboardComponent,
        WidgetGeneralStatsComponent,
        WidgetDailySalesComponent,
        WidgetEditionStatisticsComponent,
        WidgetHostTopStatsComponent,
        WidgetIncomeStatisticsComponent,
        WidgetMemberActivityComponent,
        WidgetProfitShareComponent,
        WidgetRecentTenantsComponent,
        WidgetRegionalStatsComponent,
        WidgetSalesSummaryComponent,
        WidgetSubscriptionExpiringTenantsComponent,
        WidgetTopStatsComponent,
        FilterDateRangePickerComponent,
        AddWidgetModalComponent,
        SingleLineStringInputTypeComponent,
        ComboboxInputTypeComponent,
        CheckboxInputTypeComponent,
        MultipleSelectComboboxInputTypeComponent,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        SubHeaderComponent,
        // CUSTOM COMPONENTS
        entityContactComponent,
        SafePipe,
        GuidToName,
        AmpBackBtn,
        AmpBreadcrumb,
        AmpTable,
        AmpBillingCycleBadgeComponent,
        GridComponent,
        AmpTableUnpagedComponent,
        AmpAutocompleteComponent,
        AmpAutocompleteLicenseComponent,
        AmpSpinnerComponent,
        AmpGlobalSpinnerComponent,
        EntityComponentHeader,
        AmpContactsList,
        AmpContactItem,
        ImagePreloadDirective,
        DoubleClickDirective,
        CopyClipboardDirective,
        LayoutElementsComponent,
        LayoutElementsServiceComponent,
        LayoutBuilderComponent,
        AddLayoutConfigModal,
        createEditVendorNetworkModal,
        EditMessagingModelModalComponent,
        VendorServiceAccessControlModal,
        InputTextComponent,
        InputPickerComponent,
        InputFileComponent,
        SlideToggleComponent,
        SortByPipe,
        OptionFieldDirective,
        LayoutBasicComponent,
        PricePointsTable,
        ServicesPricingModelTable,
        SkeletonTable,
        AmpDropdownComponent,
        AmpPermission,
        AmpEntityRedirectComponent,
        InputPlusComponent,
        // AppBsModalDirective
        // CUSTOM COMPONENTS
        DatepickerComponent,
        DatePickerMonthRangeComponent,
        DatePickerDayRangeComponent,
        AmpEditInlineComponent,
        AmpDynamicPropertiesFormComponent,
        AmpAceEditorComponent
    ],
    exports: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        AmpBillingCycleBadgeComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        CustomizableDashboardComponent,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        SubHeaderComponent,
        // CUSTOM COMPONENTS
        entityContactComponent,
        SafePipe,
        GuidToName,
        AmpBackBtn,
        AmpBreadcrumb,
        AmpTable,
        GridComponent,
        AmpTableUnpagedComponent,
        AmpAutocompleteComponent,
        AmpAutocompleteLicenseComponent,
        AmpEditInlineComponent,
        AmpSpinnerComponent,
        AmpGlobalSpinnerComponent,
        EntityComponentHeader,
        AmpContactsList,
        AmpContactItem,
        ImagePreloadDirective,
        DoubleClickDirective,
        CopyClipboardDirective,
        LayoutElementsComponent,
        LayoutElementsServiceComponent,
        LayoutBuilderComponent,
        AddLayoutConfigModal,
        createEditVendorNetworkModal,
        EditMessagingModelModalComponent,
        VendorServiceAccessControlModal,
        InputTextComponent,
        InputPickerComponent,
        InputFileComponent,
        SlideToggleComponent,
        SortByPipe,
        OptionFieldDirective,
        LayoutBasicComponent,
        PricePointsTable,
        ServicesPricingModelTable,
        SkeletonTable,
        AmpDropdownComponent,
        AmpPermission,
        AmpEntityRedirectComponent,
        NgxMaskModule,
        DatepickerComponent,
        DatePickerMonthRangeComponent,
        DatePickerDayRangeComponent,
        CustomFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonToggleModule,
        MatMenuModule,
		MatDatepickerModule,
		ReactiveFormsModule,
        InputPlusComponent,
        EditMessagingModelModalComponent,
        AmpDynamicPropertiesFormComponent,
        // AppBsModalDirective
        // CUSTOM COMPONENTS
        AmpAceEditorComponent
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        DashboardViewConfigurationService,
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
        //CUSTOM PROVIDERS
        TableDataSource,
        UploadFileService,
        ParserService,
        //CUSTOM PROVIDERS
    ],

    entryComponents: [
        WidgetGeneralStatsComponent,
        WidgetDailySalesComponent,
        WidgetEditionStatisticsComponent,
        WidgetHostTopStatsComponent,
        WidgetIncomeStatisticsComponent,
        WidgetMemberActivityComponent,
        WidgetProfitShareComponent,
        WidgetRecentTenantsComponent,
        WidgetRegionalStatsComponent,
        WidgetSalesSummaryComponent,
        WidgetSubscriptionExpiringTenantsComponent,
        WidgetTopStatsComponent,
        FilterDateRangePickerComponent,
        SingleLineStringInputTypeComponent,
        ComboboxInputTypeComponent,
        CheckboxInputTypeComponent,
        MultipleSelectComboboxInputTypeComponent,
    ],
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [AppAuthService, AppRouteGuard],
        };
    }
}

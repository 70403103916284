import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { Moment } from "moment";
import * as _moment from "moment";

const moment = _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: "MM/YYYY",
    },
    display: {
        dateInput: "MM/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
};

export interface CustomDateRange {
    from: Date;
    to: Date;
}

@Component({
    selector: "date-picker-month-range",
    templateUrl: "./date-picker-month-range.component.html",
    styleUrls: ["./date-picker-month-range.component.css"],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class DatePickerMonthRangeComponent implements OnInit {
    @Input() valueSetted: CustomDateRange;

    @Input() minDate: Date;
    @Input() maxDate: Date;

    range: FormGroup;

    firstDateSelected: boolean = false;

    @Output() rangeDateChange = new EventEmitter<{
        start: string;
        end: string;
    }>();

    constructor(private _fb: FormBuilder) {
        this.initForm();
    }

    ngOnInit() {
        if (this.valueSetted) {
            this.range.get("start").setValue(this.valueSetted.from);
            this.range.get("end").setValue(this.valueSetted.to);
        }
    }

    initForm() {
        this.range = this._fb.group({
            start: ["", Validators.required],
            end: ["", Validators.required],
        });
    }

    openDatePicker(datepicker: MatDatepicker<Moment>) {
        if (this.firstDateSelected) {
            datepicker.open();
        }
    }

    setMonthAndYearRange(
        normalizedMonth: Moment,
        datepicker: MatDatepicker<Moment>
    ) {
        if (!this.firstDateSelected) {
            this.range.get("start").setValue(normalizedMonth);
            this.firstDateSelected = true;
            datepicker.close();
        } else {
            this.range.get("end").setValue(normalizedMonth);
            this.firstDateSelected = false;
            this.emitRangeDate()
            datepicker.close();
        }
    }

    emitRangeDate() {
      if (this.range.valid) {
            this.rangeDateChange.emit({
                start: this.formatToMonthYear(
                    new Date(this.range.get("start").value)
                ),
                end: this.formatToMonthYear(
                    new Date(this.range.get("end").value)
                ),
            });
        }
    }

    formatToMonthYear(date: Date) {
        return moment(date).format("YYYY-MM");
    }
}

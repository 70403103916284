import { Component, ElementRef, HostListener, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog/';
import { TokenService } from 'abp-ng2-module';
import { AbstractControl } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { BugReportInput, CreateOrUpdateVendorNetworkInput, ReportBugServiceProxy, VendorNetworkServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'report-bug-modal',
    styleUrls: ['report-bug-modal.less'],
    templateUrl: 'report-bug-modal.html',
}) 
export class ReportBugModal extends AppComponentBase {

    form: FormGroup;
    saving: boolean = false;
    editing: boolean = false;
    wasOutside = true;
    constructor(
        public dialogRef: MatDialogRef<ReportBugModal>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        public _reportBugServiceProxy: ReportBugServiceProxy,
        injector: Injector,
        private eRef: ElementRef 
    ) {
        super(injector);
        //this.vendorData = data;
        this.dialogRef.disableClose = true;
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if(!this.eRef.nativeElement.contains(event.target)){
            this.wasOutside = !this.wasOutside;
            if(this.wasOutside){
                this.close();
                this.wasOutside = true;
            }
        } 

    }

    ngOnInit() {
        this.setForm();
    }

    getBrowserVersion() {
        const userAgent: any = navigator.userAgent;
        let tempMatch: any;
        let versionMatch: any =
          userAgent.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
        if (/trident/i.test(versionMatch[1])) {
          tempMatch = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
          return 'IE ' + (tempMatch[1] || '');
        }
        if (versionMatch[1] === 'Chrome') {
          tempMatch = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
          if (tempMatch != null) {
            return tempMatch.slice(1).join(' ').replace('OPR', 'Opera');
          }
        }
        versionMatch = versionMatch[2]
          ? [versionMatch[1], versionMatch[2]]
          : [navigator.appName, navigator.appVersion, '-?'];
        if ((tempMatch = userAgent.match(/version\/(\d+)/i)) != null)
          versionMatch.splice(1, 1, tempMatch[1]);
        return versionMatch;
      }

    setForm() {
        this.form = this.formBuilder.group({
            matter: ['', [Validators.required]],
            whatWantedToDo: ['', [Validators.required]],
            expectedBehavior: ['', [Validators.required]],
            url: ['', [Validators.required]],
        });
    }

    get f() {
        return this.form.controls;
    }
    submitted: boolean = false;

    save(): void {
        this.saving = true;
        this.submitted = true;
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.saving = false;

            // this.submitted = false;
            return;
        }
        let input = new BugReportInput;
        let browser = this.getBrowserVersion();

        input.matter = this.form.controls["matter"].value;
        input.whatWantedToDo = this.form.controls["whatWantedToDo"].value;
        input.expetedBehavior = this.form.controls["expectedBehavior"].value;
        input.url = this.form.controls["url"].value;
        input.browserVersionAndName = browser[0] + ' '+ browser[1];
        this._reportBugServiceProxy.sendBugReportEmail(input).subscribe(
            () => {
                this.saving = false;
                this.submitted = false;
                this.close(1);
            },
            (error) => {
                this.saving = false;
                this.formatError(error);
                this.submitted = false;
                this.close();
            }
        );
    }

    checkValue(): boolean {
        for(let i = 0; i < Object.values(this.form.controls).length; i++){
            if(Object.values(this.form.controls)[i].value){
                return true;
            }
        }    
    }

    close(closeType?: number){
        if(!this.saving){
            if(this.checkValue() && closeType != 1){
                this.message.confirm(this.l('UnsavedChangesChangingRoute'), 
                this.l('AreYouSure'), res => {
                    if(res){
                        this.dialogRef.close(closeType);
                    }
                    this.wasOutside = true;
                })
            }
            else{
                this.dialogRef.close(closeType);
            }
        } 
    }
}

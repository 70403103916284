import { Component, OnInit, Injector, Output, EventEmitter, Input, Renderer2, ElementRef, ViewChild, SimpleChanges } from '@angular/core';

import { MatDialog } from '@angular/material/dialog/';

import { AppComponentBase } from '@shared/common/app-component-base';



import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { AddLayoutConfigModal } from '../../awg-add-layout-config-modal.component';
import { LayoutElementType } from '../models';
import { FileUploader, FileItem } from 'ng2-file-upload';




@Component({
	selector: 'layout-elements',
	templateUrl: './layout-elements.components.html',
	styleUrls: ['./layout-elements.components.less']
})
export class LayoutElementsComponent extends AppComponentBase {

	elements: LayoutElementType[] = [];
	@Output() list = new EventEmitter();
	@Output() stringElements = new EventEmitter();
	@Output('file-uploader') fileUploader = new EventEmitter();
	@Output('new-element') newElement = new EventEmitter();

	@Input() public listName: string;
	@Input() public connectedTo: any;
	@Input() public owner: string;
	@Input('from-db-list') fromDbList;
	@Input() baseCDN;
	@Input('service-id') serviceId:number;
    @Input('is-basic') isBasic:boolean = false;
    @Input('flow-type') flowType:string = "iac";
	layoutElementUrlPreviewPrefix:string = '';
    
    


	@ViewChild("customElementList", {static:true}) customElementList: ElementRef;

	constructor(
		injector: Injector,
		public dialog: MatDialog,
		private renderer: Renderer2

	) {
		super(injector);

		if (this.flowType == 'iac') {
			this.layoutElementUrlPreviewPrefix = '/ac/images/'
		}
	}

	setAttr() {
		const listName = this.listName || false;

		if (listName) {
			this.renderer.setAttribute(this.customElementList.nativeElement, `#${listName}`, "[cdkDropList]");
		}
	}

	drop(event: CdkDragDrop<string[]>) {

		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		}
		this.list.emit(this.elements);
	}

	addElement(element: any, uploader: any, blob:string): void {
		
		element.styles = element.styles.join();
		let newStyles = element.styles.replace(/,/g, ' ');
		element.styles = newStyles;
		element.blob = blob;
		this.elements.push(element);
		this.list.emit(this.elements);
		this.newElement.emit(element);
		if (element.type != 'p') {
			this.fileUploader.emit(uploader);
		}
	}

	updateElement(element: LayoutElementType, position: number, uploader: any, blob:string) {
		
		element.blob = blob;
		const nextPosition: number = position + 1;
		this.elements.forEach(currentElement => {
			if (currentElement.name == element.name) {
				if (typeof currentElement.file != typeof element.file) {
					element.file == currentElement.file;
				}
			}
		})
		this.elements.fill(element, position, nextPosition);
		if (uploader.queue.length > 0) {
			this.fileUploader.emit(uploader);
		}
		this.list.emit(this.elements);
		if (this.isBasic) {
			this.newElement.emit(element);
		}
	}

	deleteElement(position: number): void {
		this.elements.splice(position, 1);
		this.list.emit(this.elements);
		if (this.isBasic) {
			this.newElement.emit({type:'p'});
		}
	}

	openDialog(data: LayoutElementType, position: number, owner: string): void {
		const editing: Boolean = !!data;
		let uploader:FileUploader = null;
		
		
		if (data && data.file) {
			uploader = data.file.uploader;
		}

        let dataWithCDN = { data: data, baseCDN: this.baseCDN, currentElements: this.elements, index:position, serviceId:this.serviceId, flowType:this.flowType }

		const dialogRef = this.dialog.open(AddLayoutConfigModal, {
			data: dataWithCDN,
		});

		dialogRef.afterClosed().subscribe(result => {
			
			let blob;
			if (uploader) {
				result.uploader = uploader
			}
 			if (result.data) {
				result.data.owner = owner;
				let element: LayoutElementType = result.data;

				blob = element.file;
				if (result.uploader.queue.length > 0 && element.type == 'img'){
					element.file = result.uploader.queue[0];
				}
				if (editing) {
					this.updateElement(element, position, result.uploader, blob);
					return;
				}
				this.addElement(element, result.uploader, blob);
			}
		});
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.fromDbList) {
			this.elements = changes.fromDbList.currentValue;
		}
	}
}


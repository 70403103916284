import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'amp-billing-cycle-badge',
    templateUrl: './amp-billing-cycle-badge.component.html',
    styleUrls: ['./amp-billing-cycle-badge.component.css'],
})
export class AmpBillingCycleBadgeComponent implements OnInit {
    @Input() cycle: number;

    billingCycleName: string;

    constructor() {}

    ngOnInit() {}

    setBillingCycleName() {
        let res = '';
        switch (this.cycle) {
            case 1:
                res = 'Daily';
                break;
            case 7:
                res = 'Weekly';
                break;
            case 30:
                res = 'Monthly';
                break;
            default:
                res = 'Unknown';
        }
        this.billingCycleName = res;
    }
}

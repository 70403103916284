import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component ({
    selector: 'app-page-not-found',
    templateUrl: "./not-found.component.html",
    styleUrls: ["./not-found.component.less"],
  })
export class NotFoundComponent extends AppComponentBase {

    constructor(
      injector: Injector
    ) {
      super(injector);
    }
}
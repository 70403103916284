import { Injectable, Injector } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';

import { AppComponentBase } from "@shared/common/app-component-base";
import * as IPCalc from 'ip-subnet-calculator';
import { SubnetAnalysis } from 'ip-subnet-calculator';

@Injectable()
export class IpCalculatorService extends AppComponentBase {

    constructor(
        injector: Injector,
    ) {
        super(injector)
    }

    calculateMinMaxHosts(network: string, mask: string): {minHost: string, maxHost:string} | null {
        if(IPCalc.isIp(network) && IPCalc.isIp(mask) && network && mask){
            const subnetInfo: SubnetAnalysis = IPCalc.calculateCIDRPrefix(network, mask);
            return {
                maxHost: subnetInfo.ipHighStr,
                minHost: subnetInfo.ipLowStr
            }
        }
        return null;
        
    }

    isInRange(maxHost: string, minHost: string): boolean { 
        return IPCalc.isIp(maxHost) && IPCalc.isIp(minHost) && IPCalc.toDecimal(minHost) >= IPCalc.toDecimal(maxHost);
    }

    maxMinHostValidator(): ValidatorFn {
        return(form: FormGroup) => {
            if(form.controls['minHost'].value && form.controls['maxHost'].value &&
            this.isInRange(form.controls['minHost'].value, form.controls['maxHost'].value))
                return null
            else
                return {maxLower: true}
        }
        
    }


    //CALCULATE NETOWRK and MASK IP
    private ipToBinary(ip: string): string {
        return ip.split('.')
          .map(num => Number(num).toString(2).padStart(8, '0'))
          .join('');
      }
    
      private binaryToIp(binary: string): string {
        return [
          parseInt(binary.slice(0, 8), 2),
          parseInt(binary.slice(8, 16), 2),
          parseInt(binary.slice(16, 24), 2),
          parseInt(binary.slice(24, 32), 2)
        ].join('.');
      }
    
      private calculateSubnetMask(minHostBinary: string, maxHostBinary: string): string {
        let maskBinary = '';
        for (let i = 0; i < minHostBinary.length; i++) {
          if (minHostBinary[i] === maxHostBinary[i]) {
            maskBinary += '1';
          } else {
            maskBinary += '0';
          }
        }
        return maskBinary.padEnd(32, '0');
      }
    
      private calculateNetworkAddress(minHostBinary: string, subnetMaskBinary: string): string {
        let networkBinary = '';
        for (let i = 0; i < minHostBinary.length; i++) {
          networkBinary += (minHostBinary[i] === '1' && subnetMaskBinary[i] === '1') ? '1' : '0';
        }
        return networkBinary;
      }
    
      public getNetworkAddressAndMask(minHost: string, maxHost: string): { networkAddress: string, subnetMask: string } {
        const minHostBinary = this.ipToBinary(minHost);
        const maxHostBinary = this.ipToBinary(maxHost);
    
        const subnetMaskBinary = this.calculateSubnetMask(minHostBinary, maxHostBinary);
        const networkAddressBinary = this.calculateNetworkAddress(minHostBinary, subnetMaskBinary);
    
        const subnetMask = this.binaryToIp(subnetMaskBinary);
        const networkAddress = this.binaryToIp(networkAddressBinary);
    
        return { networkAddress, subnetMask };
      }
}

import { Component, Injector, OnInit, Input } from '@angular/core';
import { ThemesLayoutBaseComponent } from '../themes/themes-layout-base.component';
import { AbpSessionService } from 'abp-ng2-module';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewChangeLogModalComponent } from '@shared/common/amp-changelog/view-changelog-modal.component';

@Component({
    selector: 'show-changelog-modal-button',
    templateUrl: './show-changelog-modal-button.html'
})
export class ShowChangelogModalButtonComponent extends ThemesLayoutBaseComponent implements OnInit {
    isHost = false;

    @Input() customStyle = 'btn btn-icon btn-dropdown btn-clean btn-lg mr-1';

    public constructor(
        injector: Injector,
        private _abpSessionService: AbpSessionService,
        _dateTimeService: DateTimeService,
        private dialog: MatDialog
    ) {
        super(injector, _dateTimeService);
    }

    ngOnInit(): void {
        this.isHost = !this._abpSessionService.tenantId;
    }

    showChangelogModal() {
        const dialogRef = this.dialog.open(ViewChangeLogModalComponent, {
            width: '90%', 
            minHeight: '150px',
            maxHeight: '95%'
        });

        dialogRef.afterClosed().subscribe((result) => {
          
            }
        );
    }
    
}

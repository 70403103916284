import { Component, OnInit, Input, SimpleChanges, Renderer2, ElementRef, ViewChild } from '@angular/core';

export interface IAllElements {
    header?: LayoutElementType[],
    footer?: LayoutElementType[]
}

export class BasicConfiguration {
    
    primaryColor : string;
    baseColor : string;
    backgroundImage : any

    constructor(){
        this.primaryColor = "#000000";
        this.baseColor = "#000000";
    }
}

export interface LayoutElementType {
	paragraphText?: string;
	basicFile?: string;
    name: string;
    type: string;
    styles?: string[];
	owner?:string;
	file? : any;
}

@Component({
	selector: 'awg-layout-builder',
	templateUrl: './layout-builder.component.html',
	styleUrls: ['./layout-builder.component.css']
})
export class LayoutBuilderComponent implements OnInit {

	@Input() public element: LayoutElementType;
	@Input() public stringElements: IAllElements;
	@Input('body-config') public bodyConfig: any;
	@Input('general-config') public generalConfig: any;

	htmlElements : any;

	
	url: string = 'http://localhost:4200/layout/iframe';
	@ViewChild("iframeElement", {static:true}) iframeElement: ElementRef;
	
	constructor(
		private renderer: Renderer2
	) {
		this.element = this.element || {name:'',type:''};
		this.stringElements = {
			header: [],
			footer: []
		};

	}
	
	ngOnInit() {


	}

	ngOnChanges(changes : SimpleChanges) {

		const element: HTMLIFrameElement = document.getElementById('iframe') as HTMLIFrameElement;
		const iframeDocument = element.contentWindow.document;

		if (changes.basicConfig) {
			if (changes.basicConfig.firstChange) {
				return false;
			}
			
			var mainContainer = iframeDocument.getElementById('main-container');

			let backgroundColor = changes.basicConfig.currentValue.baseColor;

			
			
			let primaryColor  = changes.basicConfig.currentValue.primaryColor;
			this.renderer.setStyle(mainContainer, 'background-color', backgroundColor);
			
			this.createImgBodyElement(changes.basicConfig.currentValue.backgroundImage).then((img) => {
				
				let backgroundImage = `url(${img})`;
				
				this.renderer.setStyle(mainContainer, 'background-image', backgroundImage);
			}) 

			
		}

		if (changes.stringElements) {
			
			if (changes.stringElements.firstChange) {
				return false;
			}

			this.htmlElements = changes.stringElements.currentValue;

			
			let headerContainer = iframeDocument.getElementById('header-section');
			let footerContainer = iframeDocument.getElementById('footer-section');
			
			headerContainer.innerHTML = "";
			footerContainer.innerHTML = "";

			const headerFooterObj = Object.keys(this.htmlElements);

			headerFooterObj.map(el => {
				let currentELement : LayoutElementType[] = this.htmlElements[el];
				let promisesArray = [];
				currentELement.map(currentEl => {
					promisesArray.push(this.createElement(currentEl))
				})
				Promise.all(promisesArray).then(value => {
					value.map(htmlelement => {
						this.setELement(iframeDocument, htmlelement, el);
					})
				})
			})
			
		}

		if (changes.bodyConfig) {
			if (changes.bodyConfig.firstChange) {
				return false;
			}
			this.setBodyContent(changes.bodyConfig.currentValue);
		}

		if (changes.generalConfig) {
			if (changes.generalConfig.firstChange) {
				return false;
			}
			this.setGeneralConfig(changes.generalConfig.currentValue);
		}
		
	}

	setParentCointainer(document: Document, owner: string): HTMLElement{
		var htmlOwner = document.getElementById(`${owner}-section`);
		return htmlOwner;
	}

	createParagrapElement(text, styles): HTMLElement {

		let paragraphContainer = this.renderer.createElement('div');
		let paragraphElement = this.renderer.createElement('p');
		let paragraphText = this.renderer.createText(text);

		paragraphElement = this.setElementClases(paragraphElement, styles);

		this.renderer.appendChild(paragraphElement, paragraphText);
		this.renderer.addClass(paragraphContainer, 'alignBottom');
		this.renderer.appendChild(paragraphContainer, paragraphElement);

		return paragraphContainer;
	}

	async createImgBodyElement(Input:any, styles?: string[]) : Promise<HTMLElement>{
		return new Promise((resolve, reject) => {
			
			var reader = new FileReader();
			var imgContainer = this.renderer.createElement('div');
			var imgTag =  this.renderer.createElement('img');

			imgTag = this.setElementClases(imgTag, styles);
			
			reader.onload = (e:any) => {
				let imgBase64 = e.target.result;
				
				resolve(imgBase64);
			}

			reader.readAsDataURL(Input.files[0]);
		
		});
	}

	async createImgElement(Input:any, styles?: string[]) : Promise<HTMLElement>{
		return new Promise((resolve, reject) => {
			
			var reader = new FileReader();
			var imgContainer = this.renderer.createElement('div');
			var imgTag =  this.renderer.createElement('img');

			imgTag = this.setElementClases(imgTag, styles);
			
			reader.onload = (e:any) => {
				let imgBase64 = e.target.result;
				this.renderer.setAttribute(imgTag, 'src', imgBase64);
				this.renderer.setAttribute(imgContainer, 'class', 'logo');
				this.renderer.appendChild(imgContainer, imgTag);
				resolve(imgContainer);
			}

			reader.readAsDataURL(Input.file.rawFile);
		
		});
	}

	setELement(iframeDocument: Document, element: HTMLElement, parent:string){
		
		let container = iframeDocument.getElementById(`${parent}-section`);
		this.renderer.appendChild(container, element);
	}

	setBodyContent(value : any){
		const element: HTMLIFrameElement = document.getElementById('iframe') as HTMLIFrameElement;
		const iframeDocument = element.contentWindow.document;

		let label = iframeDocument.getElementById('label');
		let prefix = iframeDocument.getElementById('prefix');
		let input:any = iframeDocument.getElementById('input');
		let button = iframeDocument.getElementById('button');
		
		label.textContent = !value['number-label'] ? "Ingrese su numero" : value['number-label'];
		prefix.textContent = !value['number-prefix'] ? "598" : value['number-prefix'];
		input.placeholder = !value['number-placeholder'] ? "94212288" : value['number-placeholder'];
		button.textContent = !value['submit-button-text'] ? "Enviar" : value['submit-button-text'];
	}

	setGeneralConfig(value:any ){
		const element: HTMLIFrameElement = document.getElementById('iframe') as HTMLIFrameElement;
		const iframeDocument = element.contentWindow.document;

		
			let mainContainer = iframeDocument.getElementById('main-container');
			let label = iframeDocument.getElementById('label');
			let button = iframeDocument.getElementById('button');
			let paragrahTag:any = iframeDocument.getElementsByTagName('p');
		
			if (value.newValue['background-color'] ) {
				let backgroundColor = value.newValue['background-color']
				this.renderer.setStyle(mainContainer, 'background-color', backgroundColor);
			}
			
			if (value.newValue['primary-color'] ) {
				let backgroundColor = value.newValue['primary-color']
				this.renderer.setStyle(button, 'background-color', backgroundColor);
			}

			if (value.newValue['background-image'] ) {
				let backgroundImage = `url(${value.newValue['background-image']})`;
				this.renderer.setStyle(mainContainer, 'background-image', backgroundImage);
			}
		
	}

	setElementClases(element:HTMLElement, clases: string[]): HTMLElement {

		if (!clases || clases.length == 0) {
			return element;
		}

		clases.map(clase => {
			this.renderer.addClass(element, clase);
		})
		return element;
	}

	async createElement(el:LayoutElementType): Promise<HTMLElement> {

		const element: HTMLIFrameElement = document.getElementById('iframe') as HTMLIFrameElement;
		const iframeDocument = element.contentWindow.document;
		const type = el.type;
		let newElement: HTMLElement;

		if (iframeDocument !== null) {
			if (type == 'p') {

				newElement = this.createParagrapElement(el.paragraphText, el.styles);
				return newElement;

			} else {

				let imgElement = await this.createImgElement(el.file, el.styles); 
				return imgElement;

			} 
			//ACA TIENES QU VER COMO LE PASA EL TYPE INPU PARA QUE GENERE LA IMAGEN
			// YA QUE LO CAMBIASTE A STRING
		}
        
	}

}

import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';



@Component({
  selector: 'amp-dropdown',
  templateUrl: './amp-dropdown.component.html',
  styleUrls: ['./amp-dropdown.component.less']
})
export class AmpDropdownComponent extends AppComponentBase implements OnInit {
  

  constructor(
    injector: Injector
) {
    super(injector);
}

  ngOnInit(): void {
  }

}

import { Component, Injector, EventEmitter, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AppComponentBase } from "@shared/common/app-component-base";

@Component({
    selector: 'slide-toggle',
    styleUrls: ['./input-toggle.css'],
    template: `
        <div class="col-md-12 operation-slide-toggle" [formGroup]="group">
            <mat-slide-toggle
                [formControlName]="config.slug" [checked]="config.value" (change)="change($event)">
                {{l(config.slug)}}
            </mat-slide-toggle>
        </div>
    `
})
export class SlideToggleComponent extends AppComponentBase {
    config;
    group: FormGroup;
    a: boolean = false;
    @Output('onSlideChange') toggleEmitter = new EventEmitter<any>();

    constructor(injector:Injector) {
        super(injector);
    }
    


    change(event):void{
        let emitObject = {
            checked:event.checked,
            slug: this.config.slug
        }
        this.toggleEmitter.emit(emitObject);
        
    }
}
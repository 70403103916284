import { Component, Output, ViewChild, ElementRef, Renderer2, Injector, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UploadFileService } from '../../../../upload-file-service';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { TokenService } from 'abp-ng2-module';
import { EventEmitter } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { OperationAcquisitionOption } from '../models';
import {
    AcquisitionFieldType,
    FieldType,
    SwitchedAccountAuthenticateResultModel,
} from '@shared/service-proxies/service-proxies';
import { Observable, Subscription, of } from 'rxjs';
import {
    CommonLookupModalComponent,
    ICommonLookupModalOptions,
} from '@app/shared/common/lookup/common-lookup-modal.component';
import { Router } from '@angular/router';
import { FlowSharedService } from '../shared/FlowSharedService';

interface IFilePathAndName {
    path: string;
    name: string;
}

interface IShowFormTumbnails {
    image: boolean;
    icon: boolean;
}

export interface IOutputFile {
    id: number;
    uploader: FileUploader;
    filename: string;
    fileType: number;
    blob?: string;
}

@Component({
    selector: 'input-file',
    styleUrls: ['input-file.css'],
    template: `
        <div
            class="row input-file-element"
            [formGroup]="group"
            [ngStyle]="{ display: display }">
            <div class="input-file-container col-md-12">
                <div
                    class="d-flex align-items-center mb-4"
                    style="gap: 10px">
                    <p class="mb-0">{{ l(config.slug) }}</p>
                    <div
                        *ngIf="
                            config.backupFileNames != null && config.backupFileNames.length != 0 && !fileAlreadyRestored
                        ">
                        <i
                            (click)="showBackupFiles()"
                            class="fa fa-book cursor-pointer"
                            aria-hidden="true"
                            title="Restore previews versions"></i>
                    </div>

                    <div *ngIf="fileAlreadyRestored">
                        <i
                            (click)="clearRestoreFromBackup()"
                            class="fa fa-undo cursor-pointer"
                            aria-hidden="true"></i>
                    </div>
                </div>

                <div
                    class="custom-file"
                    lang="es">
                    <input
                        ng2FileSelect
                        #inputFile
                        [uploader]="uploader"
                        type="file"
                        (change)="onChanges($event)"
                        class="custom-file-input"
                        [id]="inputId"
                        aria-describedby="fileHelp"
                        [disabled]="fileAlreadyUploaded && !fileInheritedFromVendor" />
                    <label
                        class="custom-file-label"
                        [for]="inputId">
                        <div class="text-container">
                            {{ fileName }}
                        </div>
                    </label>
                    <div class="icons-container">
                        <i
                            class="fas fa-search background-image cursor-pointer"
                            *ngIf="withSearchOption"
                            (click)="clickSearch()"></i>
                        <i
                            class="fas fa-times background-image cursor-pointer"
                            *ngIf="fileAlreadyUploaded && !fileInheritedFromVendor && !fileAlreadyRestored"
                            (click)="clickClean()"></i>
                        <i
                            (click)="inputFile.click()"
                            class="fas fa-folder-open"></i>
                    </div>
                </div>

                <mat-hint *ngIf="fileInheritedFromVendor" style="font-size: 10px;">
                    {{ this.l('FileInheritedFromVendorPart1') }}               
                    {{ (this.config.defaultValue) }}
                    {{ this.l('FileInheritedFromVendorPart2') }}  
                    <a (click)="goToUrl('Pages.BizDev.Flow.Tenant.Vendor.Edit', [
                    'app/biz-dev/vendors/',
                    vendorId,
                    'acquisition-flow',
                    flowId,
                    'advanced'
                    ])" style="color: blue; cursor: pointer;">
                  {{ this.l('FileInheritedFromVendorPart3') }}
                   </a>   
                </mat-hint>
                <br />
                <mat-hint *ngIf="errorMessage.length > 0">{{ errorMessage }}</mat-hint>
            </div>
            <div
                class="col-md-3 tumbnail-container"
                *ngIf="config.fieldType == 0">
                <div *ngIf="loadingImage">
                    <mat-spinner></mat-spinner>
                </div>
                <div *ngIf="!loadingImage">
                    <div
                        class="clean-input-file"
                        *ngIf="isUploaded"
                        (click)="cleanFileInputField()">
                        <i class="fa fa-times"></i>
                    </div>
                    <img
                        [src]="fileSource"
                        class="image-test"
                        *ngIf="showFormThumbnails.image"
                        alt="{{ fileName }}" />
                </div>
            </div>
        </div>

        <commonLookupModal
            #backupFilesLookupModal
            customTop="20%"
            (itemSelected)="selectedCommonModalValue($event, 'backupFiles')"></commonLookupModal>
    `,
})
export class InputFileComponent extends AppComponentBase {
    @ViewChild('inputFile', { static: true }) inputFile: ElementRef;
    @Input() submitted$: Observable<void>;

    config: OperationAcquisitionOption;
    tempConfigPath: string;
    serviceId: number;
    baseCDN: string;
    withSearchOption: boolean;
    group: FormGroup;
    fileSource;
    inputId: string;
    errorMessage: string = '';
    translatedType;
    isUploaded: boolean = false;
    showFormThumbnails: IShowFormTumbnails = { image: false, icon: false };
    loadingImage: boolean = false;
    outputFile: IOutputFile;
    fileAlreadyRestored: boolean = false;
    tempFileId: number;
    
    fileInheritedFromVendor: boolean = false;
    file: any;
    fileName: string = 'Choose a file';
    fileType: string;
    path: string;
    display: string;
    fileAlreadyUploaded: boolean = false;
    fileWasAlreadyUploaded: boolean = false;
    firstFileName: string = null;
    inputName: string;

    flowId: number;
    vendorId: number;
    private _uploaderOptions: FileUploaderOptions = {};

    originalValue: string;

    @ViewChild('backupFilesLookupModal', { static: true }) backupFilesLookupModal: CommonLookupModalComponent;
    @Output('onFileChange') fileObjectEmitter = new EventEmitter<IOutputFile>();
    @Output('searchOptionEvent') searchOptionEvent = new EventEmitter<boolean>(false);

    public uploader: FileUploader;
    submitSubscription: Subscription;
    fileChanged: boolean = false;

    constructor(
        public _uploadService: UploadFileService,
        private _tokenService: TokenService,
        public renderer: Renderer2,
        private router: Router,
        private flowSharedService: FlowSharedService,
        injector: Injector
    ) {
        super(injector);
       
    }

    async ngOnInit() {
        this.setInitialOriginalValue();    
    

        this.submitSubscription = this.submitted$?.subscribe(() => {
            this.fileAlreadyRestored = false;
            //Set original value when is saved 
            this.setInitialOriginalValue();    
            console.log( 'cambio en after submit ' + this.config.slug,this.fileChanged)
            if(this.fileChanged) {
                this.getBackups();
            }
        });

        this.inputId = `${this.config.slug}_${this.getRandomNumber()}`;
        if (this.config.value == 'null' || this.config.value == '') {
            this.config.value = null;
        }

        this.display = this.config.hide ? 'none' : 'show';

        if (this.config.value != null && this.config.value != '') {
            if (this.config.fieldType == FieldType.FileScript || this.config.fieldType == FieldType.FileStylesheet) {
                if (this.config.value != null) {
                    this.fileAlreadyUploaded = true;
                }
            } else {
                this.isUploaded = true;
                this.fileAlreadyUploaded = true;
                this.getPreviewTumbnailFromDB();
            }
        }

        this.getBackups();

        // Si se cumplen todos los if, es porque el servicio tiene un archivo cargada a nivel vendor y hay que mostrar un mensaje para advertir
        if (this.config.defaultValue != null) {
            if (this.config.fieldType == FieldType.FileScript || this.config.fieldType == FieldType.FileStylesheet) {
                this.fileInheritedFromVendor = true;
                this.config.value = null;
                this.vendorId = this.flowSharedService.vendorId;
                this.flowId = this.flowSharedService.flowId;
            }
        }

        this.setUploaderInstance();

        if (this.firstFileName == null) this.firstFileName = this.fileName;

        this.fileWasAlreadyUploaded = this.fileAlreadyUploaded;
    }

    private setInitialOriginalValue() {
        this.originalValue = this.group.value[this.config.slug];
    }

    private async getBackups() {
let filesBackupObservable: Observable<string[]>;

        if (this.config.fieldType == FieldType.FileScript || this.config.fieldType == FieldType.FileStylesheet) {
            let fileNamePromises;
            this.withSearchOption = false;
            switch (this.config.fieldType) {
                case FieldType.FileScript:
                    filesBackupObservable = this._uploadService.GetFilesInFolder(
                        this.config.backupFilePath,
                        AppConsts.FILE_TYPE_JS
                    );

                    if (this.config.value != null) {
                        this.fileAlreadyUploaded = true;
                    }
                    fileNamePromises = await filesBackupObservable.toPromise();
                    this.config.backupFileNames = fileNamePromises.result;
                    break;

                case FieldType.FileStylesheet:
                    filesBackupObservable = this._uploadService.GetFilesInFolder(
                        this.config.backupFilePath,
                        AppConsts.FILE_TYPE_CSS
                    );

                    if (this.config.value != null) {
                        this.fileAlreadyUploaded = true;
                    }
                    fileNamePromises = await filesBackupObservable.toPromise();
                    this.config.backupFileNames = fileNamePromises.result;
                    break;
                default:
                    break;
            }

            this.configureFileBackupModal();
        }
    }


    
    onChanges($event: any) {
        let file = $event.target.files[0];

        this.fileChanged = true;
        
        if (!file) return;
        this.loadingImage = true;
        this.config.isBlob = true;
        if (file && !this.isValidFile(file.type)) {
            this.errorMessage = `Only valid ${this.translatedType} files`;
            let queueLength = this.uploader.queue.length;
            let lastPosition = queueLength - 1;
            this.uploader.queue.splice(lastPosition, 1);
            this.loadingImage = false;
            return false;
        }
        this.errorMessage = '';
        
        this.getPreviewTumbnailFromFile(file).then((data) => {
            const filePathAndName: IFilePathAndName = this.getFileNameAndPath(file);
            this.fileName = filePathAndName.name;

            this.loadingImage = false;
            if (data.type == 'FileImage') {
                this.fileSource = data.source;

                this.fileAlreadyUploaded = true;
                this.fileInheritedFromVendor = false;
                this._uploadService.queueFileForUpload(file, `acq/${this.config.path}`);
                this.showFormThumbnails.image = true;
            } else this.showFormThumbnails.icon = true;

            if (data.type == 'File') {
                let fromVendor = '';

                if (this.config.fromVendor == true) fromVendor = 'vendor-';

                this.fileAlreadyUploaded = true;
                this.fileInheritedFromVendor = false;
                const currentDate = new Date();
                const formattedDate = currentDate.toLocaleString().replace(/[, :/]/g, '-');
                if (this.config.fieldType == FieldType.FileScript) {
                    const newFileName = `script-${fromVendor}${formattedDate}-${file.name}`;
                    this.fileName = newFileName;
                    file = new File([file], newFileName, { type: file.type });
                }
                if (this.config.fieldType == FieldType.FileStylesheet) {
                    const newFileName = `stylesheet-${fromVendor}${formattedDate}-${file.name}`;
                    this.fileName = newFileName;
                    file = new File([file], newFileName, { type: file.type });
                }
                //Original file last modified changed because of the new file instance
                this.tempFileId = file.lastModified;
                this._uploadService.queueFileForUpload(file, `acq/${this.config.path}`);
            }

            this.uploader.setOptions(this._uploaderOptions);

            this.isUploaded = true;
            this.outputFile = {
                uploader: this.uploader,
                filename: this.fileName,
                fileType: this.config.fieldType,
                blob: this.fileSource,
                id: this.tempFileId,
            };

            // if (data.type == 'File')
            //     this.group.controls[this.config.slug].setValue(this.fileName);

            // if(data.type == "FileImage")
            //     this.group.controls[this.config.slug].setValue(this.outputFile.blob);

            this.fileObjectEmitter.emit(this.outputFile);
        });
    }

    resetForm(): void {
        this.loadingImage = false;
    }

    getFileNameAndPath(file: any): IFilePathAndName {
        let filePath = this.getFilePath(file.type);
        let fileName = this.getFileName(file.name);
        let fullPath = `${filePath}${fileName}`;

        return { name: fileName, path: fullPath };
    }

    onBuildItemForm(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
                form.append('fileName', fileItem.options.itemAlias);
                form.append('serviceId', this.serviceId);
                form.append('type', this.config.fieldType);
                resolve();
            };

            this.uploader.onErrorItem = (item, response) => {
                console.log(`error suviendo ${item.alias}`, response);
                reject();
            };

            this.uploader.onCompleteAll = () => {
                this.uploader.clearQueue();
            };
        });
    }

    cleanFileInputField(): void {
        this.isUploaded = false;
        this._uploaderOptions.itemAlias = '';
        
        this.uploader.clearQueue();

        this.uploader.setOptions(this._uploaderOptions);

        if (this.config.fieldType == FieldType.FileImage) {
            this.group.controls['background-image'].setValue('');
        }

        if (this.config.fieldType == FieldType.FileScript || this.config.fieldType == FieldType.FileStylesheet) {
            const initiallyHasValue = !!this.originalValue
            if (
                //CAMBIAR CONDICION PARA EVALUAR CUANDO CAMBIA
                initiallyHasValue &&
                !this.config.value.match('https://') &&
                !this.config.value.match('http://')
            ) {
                const originalPath = `acq/${this.config.path}${this.fileName}`;
                let backupPath = originalPath.replace(/\/(js|css)\//, '/backup/');
                this._uploadService.queueFileForBackup(originalPath, backupPath);
            }

            this._uploadService.cleanFile(this.tempFileId);
        }

        this.fileName = null;
        this.fileSource = '';
        this.showFormThumbnails.image = false;
        this.outputFile = {
            uploader: this.uploader,
            filename: this.fileName,
            fileType: this.config.fieldType,
            id: this.tempFileId,
        };

        this.inputFile.nativeElement.value = null;

        this.fileObjectEmitter.emit(this.outputFile);
    }

    onCompleteUpload(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.uploader.onCompleteItem = (item, response, status, headers) => {
                if (status == 500) {
                    this.cleanFileInputField();
                    this.errorMessage = 'Error uploading image';
                    return false;
                }
                this.isUploaded = true;
                resolve();
            };
        });
    }

    onErrorUpload(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.uploader.onErrorItem = (item, response, status, headers) => {
                this.isUploaded = false;
                resolve();
            };
        });
    }

    setUploaderInstance() {
        // set label value for fileInputs in IAC form
        if(!this.config.acquisitionFieldType && this.config.value){
            this.fileName = this.config.value;
        }
        else {
            if (this.config.acquisitionFieldType == AcquisitionFieldType.FileImage) {
                if (this.fileAlreadyUploaded) this.fileName = this.getFileName(this.config.value);
                else this.fileName = 'Upload file..';
            }
    
            if (
                this.config.acquisitionFieldType == AcquisitionFieldType.FileScript ||
                this.config.acquisitionFieldType == AcquisitionFieldType.FileStylesheet
            ) {
                if (this.fileAlreadyUploaded) this.fileName = this.config.value;
                else this.fileName = 'Upload file..';
            }
    
        }

        this.uploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + this.config.urlEndPoint });

        this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
            form.append('fileName', this.fileName);
            form.append('serviceId', this.serviceId);
            form.append('type', this.config.fieldType);
        };

        this.uploader.onCancelItem = () => {
            this.loadingImage = false;
        };

        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        this._uploaderOptions.removeAfterUpload = true;

        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.onCompleteUpload().then(() => {
            this.uploader.clearQueue();
        });
        this.onErrorUpload();

        this.uploader.setOptions(this._uploaderOptions);
    }

    clickSearch() {
        this.searchOptionEvent.emit(true);
    }

    clickClean() {
        this.cleanFileInputField();
        this.fileAlreadyUploaded = false;
    }

    getFilePath(type: string): string {
        let path: string = '';
        switch (type) {
            case 'text/javascript':
                path = 'scripts/';
                break;
            case 'text/css':
                path = 'css/';
                break;
            default:
                path = 'images/';
                break;
        }
        return path;
    }

    guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return '';
    }

    // getFileScriptOrFileStylesheetName(filePath: string): string {
    //     let name;

    //     if (filePath.match('/js/') != null || filePath.match('/css/') != null) {
    //         let splitedPath = this.config.value.split('/');
    //         name = splitedPath[splitedPath.length-1].split('?')[0];
    //     }

    //     return name;
    // }

    getFileName(filePath: string): string {
        let name;
        if (!filePath) {
            return;
        }

        if (filePath.match('service/') != null) {
            let splitedPath = this.config.value.split('/');
            name = splitedPath[splitedPath.length - 1].split('?')[0];
        } else {
            let separatorStringPosition = filePath.indexOf('/') + 1;
            let stringLength = filePath.length;
            name = `${filePath.slice(separatorStringPosition, stringLength)}`;
        }

        return name;
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }
    getPreviewTumbnailFromDB() {
        const pathImage = this.config.value;
        let randomNum = this.getRandomInt(1, 1000000);
        if (this.config.value.match('s3.amazonaws.com') == null) {
            if (this.config.urlEndPoint == '/Upload/UploadAccessControlElement') {
                if (this.config.value.match('images/') != null)
                    this.path = `${this.baseCDN}service/${this.serviceId}/ac/${pathImage}?v=${randomNum}`;
                else this.path = `${this.baseCDN}service/${this.serviceId}/ac/images/${pathImage}?v=${randomNum}`;
            } else {
                if (this.config.value.match('service/') != null) this.path = this.config.value;
                else {
                    if (this.config.value.match('images/') != null)
                        this.path = `${this.baseCDN}service/${this.serviceId}/${pathImage}?v=${randomNum}`;
                    else this.path = `${this.baseCDN}${this.config.path}${pathImage}?v=${randomNum}`;
                }
            }
        } else {
            this.path = this.config.value;
        }
        this.showFormThumbnails.image = !!pathImage;
        this.fileSource = this.path;
    }

    getPreviewTumbnailFromFile(file): Promise<any> {
        return new Promise((resolve, reject) => {
            var fileReader = new FileReader();
            if (file.type.match('image')) {
                fileReader.onload = function () {
                    resolve({ source: fileReader.result, type: 'FileImage' });
                };
                fileReader.readAsDataURL(file);
            } else {
                resolve({ source: 'description', type: 'File' });
            }
        });
    }

    showBackupFiles() {
        this.backupFilesLookupModal.show();

        const modal = document.getElementsByClassName('modal')[0];

        const modalBackdrop: any = document.getElementsByTagName('bs-modal-backdrop')[0];
        if (modalBackdrop) modalBackdrop.style.position = 'inherit';
       
    }
    selectedCommonModalValue(item, property) {
        if (this.config.value != null && this.fileName != null) {
            this.cleanFileInputField();
        }
        this.fileAlreadyRestored = true;
        this.fileName = item.name;
        this._uploadService.queueFileForRestoredBackupFiles(
            `acq/${this.config.path}${this.fileName}`,
            `${this.config.backupFilePath}${this.fileName}`
        );
        this.fileAlreadyUploaded = true;
        this.outputFile = {
            uploader: this.uploader,
            filename: this.fileName,
            fileType: this.config.fieldType,
            blob: this.fileSource,
            id: this.tempFileId,
        };

        this.fileObjectEmitter.emit(this.outputFile);
    }

    clearRestoreFromBackup() {
        this.fileAlreadyRestored = false;
        this.fileChanged = true
        this._uploadService.removeFileFromQueueForRestoredBackup(this.fileName);
        this._uploadService.removeFileFromQueueForBackup(this.firstFileName);
        this.fileAlreadyUploaded = this.fileWasAlreadyUploaded;
        this.fileName = this.firstFileName;

        const outputFile: IOutputFile = {
            uploader: this.uploader,
            filename: this.fileName,
            fileType: this.config.fieldType,
            blob: this.fileSource,
            id: this.tempFileId,
        };
        
        this.fileObjectEmitter.emit(outputFile);

    }

    configureFileBackupModal() {
        const customDataSource = (
            skipCount: number,
            maxResultCount: number,
            filter: string,
            tenantId?: number
        ): Observable<any> => {
            const filteredData = this.config.backupFileNames.filter((fileName) => fileName.includes(filter));

            const startIndex = skipCount;
            const endIndex = Math.min(startIndex + maxResultCount, filteredData.length);
            const slicedData = filteredData.slice(startIndex, endIndex);

            const result = {
                totalCount: filteredData.length,
                items: slicedData.map((fileName, index) => ({
                    id: index,
                    name: fileName,
                })),
            };

            // Devuelve los datos como un Observable
            return of(result);
        };

        // Configura las opciones del modal
        const modalOptions: ICommonLookupModalOptions = {
            title: this.l('RestoreFileLookUpTitle'),
            dataSource: customDataSource,
        };

        this.backupFilesLookupModal.configure(modalOptions);
    }

    getRandomNumber(): number {
        const min = 1;
        const max = 9999999;
        return Math.floor(Math.random() * (+max - +min)) + +min;
    }

    goToUrl(permission: string, route: string[]) {
        if (!this.validatePermissionAction(permission)) return false;
        this.router.navigate(route);
    }

    isValidFile(type) {
        if (this.config.fieldType == 0) {
            this.translatedType = 'Image';
            return type == 'image/png' || type == 'image/jpeg';
        }
        if (this.config.fieldType == 1) {
            this.translatedType = 'Javascript';
            return type == 'text/javascript';
        }
        if (this.config.fieldType == 2) {
            this.translatedType = 'Css';
            return type == 'text/css';
        }
        this.resetForm();
    }

    ngOnDestroy(): void {
        this.submitSubscription?.unsubscribe();
    }
}

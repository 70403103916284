import { Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AMPColumnType, AMPColumn } from '../amp-table/amp-table-entities';
import { DataSource } from '@angular/cdk/collections';
import { AppComponentBase } from '../app-component-base';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';

@Component({
    selector: 'amp-table-unpaged',
    templateUrl: './amp-table-unpaged.component.html',
    styleUrls: ['./amp-table-unpaged.component.scss'],
})
export class AmpTableUnpagedComponent extends AppComponentBase implements OnInit {
    @Input('dataSource') dataSource: any[] = [];
    @Input('displayedColumns') displayedColumns: AMPColumn[];
    @Input('displayedRows') displayedRows: string[];
    @Input('permissionTo') permissionTo: string;
    @Input() sorteableColumns: boolean = true

    @Output() soartChange = new EventEmitter<Sort>();

    totalCount: number;
    hasNoResult: boolean = false;

    ampColumnType = AMPColumnType;
    permissionGranted: boolean;

    constructor(private injector: Injector, private router: Router) {
        super(injector);
        this.displayedRows = [];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataSource && changes.dataSource.currentValue) {
            this.hasNoResult = this.dataSource.length == 0;
        }
    }

    ngOnInit() {
        this.permissionGranted = this.permission.isGranted(this.permissionTo);

        if (this.displayedRows.length == 0) {
            this.displayedColumns.forEach((column) => {
                this.displayedRows.push(column.name);
            });
        }
    }

    goToUrl(action, element) {
      let permission = action.permission;
      let route: string[];
      if (action.edit) {
          route = [action.goTo, element.id, "edit"];
      } else {
          route = [action.goTo, element.id];
      }
      if (!this.validatePermissionAction(permission)) return false;
      this.router.navigate(route);
  }

  onSortChange(sort: Sort){
    this.soartChange.emit(sort);  
  }
}

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'periodicy'})
export class PeriodicyPipe implements PipeTransform {
    transform(value: number): string {
        switch (value) {
            case 1:
                return 'Daily';
            case 7:
                return 'Weekly';
            case 30:
                return 'Monthly';
            default :
                return value.toString();
        }
    }
}
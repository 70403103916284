import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, switchMap, take, tap } from 'rxjs/operators';

/**
 * Interceptor to handle the refresh token request
 * It stops the request if the refresh token is being requested in order to obtain a new access token
 * with the Organization Units updated in the user session
 */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {


    loadingRefreshToken = new BehaviorSubject<boolean>(false);
    loadingRefreshToken$ = this.loadingRefreshToken.asObservable();

    constructor(
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
      if(request.url.includes('refreshToken')) {
        this.loadingRefreshToken.next(true);
        return next.handle(request).pipe(tap(_ => this.loadingRefreshToken.next(false)))
      }
      else {
        if(this.loadingRefreshToken.getValue()) {
          return this.loadingRefreshToken$.pipe(
            filter(loading => !loading),
            take(1),
            switchMap(_ => {
              return next.handle(request).pipe(
                finalize(() => {})
              )
            })
          )
        } else {
          return next.handle(request)
        }
      }
    }
}



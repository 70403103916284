import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputTextComponent } from './input-text.component'
import { InputPickerComponent } from './input-picker.component'
import { SlideToggleComponent } from './slide-toggle.component'
import { InputFileComponent } from './input-file.component'
import { FileUploader } from 'ng2-file-upload';
import { OperationAcquisitionOption } from '../models';
import { ParserService } from '@app/shared/common/parsers/custom-parsers.service';
import { Observable } from 'rxjs';


const components = {
    text: InputTextComponent,
    picker: InputPickerComponent,
    slide: SlideToggleComponent,
    file: InputFileComponent
};

interface IOutputFile {
    uploader: FileUploader;
    filename: string;
}

@Directive({
    selector: '[ampInput]'
})
export class OptionFieldDirective implements OnInit {
    @Input() option: OperationAcquisitionOption;

    @Input() group: FormGroup;
    @Input() origin: string;
    @Input() withSearchOption: boolean = false;
    @Input() baseCDN: string;
    @Input() serviceId: number;

    @Input() submitted: Observable<void>;

    @Input("img-preview") imgPreview:string="";

    @Output('on-text-change') textEmitter = new EventEmitter<string>();
    @Output('on-blur') onBlur = new EventEmitter();
    @Output('onFileChange') fileObjectEmitter = new EventEmitter<IOutputFile>();
    @Output('onColorChange') colorEmitter = new EventEmitter<string>();
    @Output('onSlideChange') toggleEmitter = new EventEmitter<any>();
    @Output('searchOptionEvent') searchOptionEvent = new EventEmitter<boolean>(false);

    @Output('bgFilePath') bgFilePath = new EventEmitter<boolean>(false);
    lastColorSelected:string;


    constructor(
        private resolver: ComponentFactoryResolver,
        private container: ViewContainerRef,
        private paserService: ParserService
    ) { }

    component;

    ngOnInit() {
        let type = 'text';

        switch (this.option.fieldType) {
            case 0:
            case 1:
            case 2:
                type = 'file';
                break;
            case 3:
                type = 'picker';
                break;
            case 5:
            case 4:
                type = 'text';
            
                break;
            default:
                type = 'slide';
                break;
        }
        // this.option["name"] = Object.keys(this.option)[0];


        const component = components[type];
        const factory = this.resolver.resolveComponentFactory<any>(component);
        this.component = this.container.createComponent(factory);

        if (type == 'text') {
            this.component.instance.imgPreview = this.imgPreview;
            this.component.instance.output.subscribe(event => {
                this.textEmitter.emit(event);
            });
        }

        if (type == "file") {
            this.component.instance.imgPreview = this.imgPreview;
            this.component.instance.withSearchOption = this.withSearchOption;
            
            this.component.instance.fileObjectEmitter.subscribe(event => {
                this.fileObjectEmitter.emit(event);
            })

            this.component.instance.searchOptionEvent.subscribe(event => {
                this.searchOptionEvent.emit(event);
            })
            this.component.instance.submitted$ = this.submitted;

        }

        if (type == 'text') {
            this.component.instance.onBlurEvent.subscribe(event => {
                this.onBlur.emit(event);
            });
        }

        if (type == 'picker') {
            this.component.instance.imgPreview = this.imgPreview;
            this.component.instance.colorEmitter.subscribe(event => {
                this.colorEmitter.emit(event)
                
            })
        }

        if (type == 'slide') {
            if (typeof this.option.value == 'string') {
                this.option.value = this.paserService.parseBooleanToStr(this.option.value);
            }

            this.component.instance.toggleEmitter.subscribe(event => {
                this.toggleEmitter.emit(event);
            });
        }
        this.component.instance.config = this.option;
        this.component.instance.group = this.group;
        this.component.instance.origin = this.origin;
        this.component.instance.baseCDN = this.baseCDN;
        this.component.instance.serviceId = this.serviceId;
        this.component.instance.lastColorSelected = this.lastColorSelected;

    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.imgPreview) {
            if (this.option.fieldType == 3 && !changes.imgPreview.firstChange) {
                this.component.instance.group.controls[this.component.instance.config.slug].setValue(changes.imgPreview.currentValue);
                this.component.instance.config.value = changes.imgPreview.currentValue;
                this.component.instance.lastColorSelected = changes.imgPreview.currentValue;
            }
    
            if ((this.option.fieldType == 4 || this.option.fieldType == 5) && !changes.imgPreview.firstChange) {
                this.component.instance.group.controls[this.component.instance.config.slug].setValue(changes.imgPreview.currentValue.value);
                
            }
            if (this.option.fieldType == 0 && !changes.imgPreview.firstChange) {
                this.component.instance.group.controls[this.component.instance.config.slug].setValue(changes.imgPreview.currentValue.name);
                this.component.instance.fileName = changes.imgPreview.currentValue.name

                const path = changes.imgPreview.currentValue
                if (path != null && path.path) {
                    this.component.instance.fileSource = path.path;
                    this.component.instance.fileName = path.name;
                    this.component.instance.showFormThumbnails.image = true;
                }
            }
        }
      }
}
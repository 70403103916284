import { Component, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog/';

import { TokenService } from 'abp-ng2-module';
import { AppConsts } from "@shared/AppConsts";


import { AppComponentBase } from '@shared/common/app-component-base';
import { ICommonOptions, ICommonOptionsDialogs } from '../models-basic';



@Component({
    selector: 'common-options-modal',
    styleUrls: ['./common-options-modal.component.less'],
    templateUrl: 'common-options-modal.component.html',
})
export class CommonOptionsModal extends AppComponentBase {


    showImages: boolean = false;
    showColors: boolean = false;
    images: any[] = [];
    texts:any[] = [];
    colors: string[] = [];
    modalType: string = "Images"
    selected:any;
    constructor(
        public dialogRef: MatDialogRef<ICommonOptionsDialogs>,
        @Inject(MAT_DIALOG_DATA) public data: ICommonOptionsDialogs,
        private formBuilder: FormBuilder,
        private _tokenService: TokenService,
        injector: Injector
    ) {
        super(injector);
        this.getCommonOptions(data)

    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    getCommonOptions(commonOptions: ICommonOptionsDialogs) {
        console.log(commonOptions);
        switch (commonOptions.field.slug) {
            case "background-image":
                this.setImagesOptions(commonOptions);
                break;
            case "background-color":
            case "primary-color":
                this.setColorOptions(commonOptions);
                break;
            default:
                this.setTextOptions(commonOptions);
                break;
        }
    }

    setImagesOptions(commonOptions: ICommonOptionsDialogs) {
        console.log(commonOptions.commonOptions.images);
        commonOptions.commonOptions.images.forEach(img => {
            if (img != "") {
                let randomNum = this.getRandomInt(1, 1000000);
                let fullImagePath = `${commonOptions.baseCDN}service/${commonOptions.serviceId}/${img}?v=${randomNum}`;
                this.images.push({ name: img, path: fullImagePath });
            }
        })
    }

    setColorOptions(commonOptions: ICommonOptionsDialogs){
        this.modalType = "Colors"
        this.colors = commonOptions.commonOptions.colors; 
    }

    setTextOptions(commonOptions: ICommonOptionsDialogs){
        this.modalType = "Texts"

        
        let tempText=[];
       
        commonOptions.commonOptions.strings.forEach(text=>{
            if (text.label == commonOptions.field.slug && text.value != "" && tempText.indexOf(text.value) == -1) {
                this.texts.push(text);
                tempText.push(text.value);
            }
        })
        this.texts;
    }

    getImage(img: any) {
        this.dialogRef.close({ data: img });
    }

    closeModal(){
        this.dialogRef.close({ data: false });
    }

    getColor(color:string) {
        this.dialogRef.close({ data: color });
    }

    getText(text:string) {
        this.dialogRef.close({ data: text });
    }
}


import { Component, Injector, OnInit, Input } from '@angular/core';
import { ThemesLayoutBaseComponent } from '../themes/themes-layout-base.component';
import { AbpSessionService } from 'abp-ng2-module';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { MatDialog } from '@angular/material/dialog';
import { createEditVendorNetworkModal } from '@app/biz-dev/vendors/vendors-networks/create-or-edit/create-or-edit-vendor-network-modal.component';
import { ReportBugModal } from '../reportbBugModal/report-bug-modal';

@Component({
    selector: 'report-bug-button',
    templateUrl: './report-bug-button.html'
})
export class ReportBugButtonComponent extends ThemesLayoutBaseComponent implements OnInit {

    isHost = false;

    @Input() customStyle = 'btn btn-icon btn-dropdown btn-clean btn-lg mr-1';

    public constructor(
        injector: Injector,
        private _abpSessionService: AbpSessionService,
        _dateTimeService: DateTimeService,
        private dialog: MatDialog
    ) {
        super(injector, _dateTimeService);
    }

    ngOnInit(): void {
        this.isHost = !this._abpSessionService.tenantId;
    }

    reportBug() {
        const dialogRef = this.dialog.open(ReportBugModal, {
            width: '700px', 
        });

        dialogRef.afterClosed().subscribe((result) => {
            switch (result) {
                case 0:
                    this.message.error(this.l('Error'));
                    break;
                case 1:
                    this.message.success(this.l('EmailSentSuccesfully'));
                    break;

                default:
                    break;
            }
        });
    }
    
}

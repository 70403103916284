import { Component, ElementRef, Inject, Injector, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog/';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CreateOrUpdateVendorNetworkInput, VendorNetworkServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import { IpCalculatorService } from '../../vendor-ip-calculator/vendor-ip-calculator.service';

function ipValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(control.value))
        return null;
    else
        return { ip: true };
}

@Component({
    selector: 'create-or-edit-vendor-network',
    styleUrls: ['create-or-edit-vendor-network-modal.component.less'],
    templateUrl: 'create-or-edit-vendor-network-modal.component.html',
})
export class createEditVendorNetworkModal extends AppComponentBase {
    form: FormGroup;

    networkFormControl: FormControl;

    vendorData: any;
    saving: boolean = false;
    editing: boolean = false;
    public existingIp: boolean = false;

    isDisableMinMaxHost = false;
    isDisableMaskNetwork = false;

    public networkCombinationTaken: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<createEditVendorNetworkModal>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        public _vendorNetworkServiceProxy: VendorNetworkServiceProxy,
        private _ipCalculator: IpCalculatorService,
        injector: Injector
    ) {
        super(injector);
        this.vendorData = data;
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    ngOnInit() {
        this.setForm();
        this.f['network'].valueChanges.subscribe(()=>{
            this.listenSetMaxMinHost();
        });
        this.f['mask'].valueChanges.subscribe(()=>{
            this.listenSetMaxMinHost();
        });

        this.f['minHost'].valueChanges.subscribe(()=>{
            this.listenSetNetworkMask();
        });
        this.f['maxHost'].valueChanges.subscribe(()=>{
            this.listenSetNetworkMask();
        });

    }

    private listenSetNetworkMask(): void{
        if(this.isDisableMaskNetwork || !this.isDisableMinMaxHost)
            this.setNetworkAndMask();
    }
    private listenSetMaxMinHost(): void{
        if(this.isDisableMinMaxHost || !this.isDisableMaskNetwork)
            this.setMaxMinHost();
    }

    setForm() {
        let id = this.vendorData.element?.id ? this.vendorData.element?.id : undefined;
        let network = this.vendorData.element?.network || '';
        let mask = this.vendorData.element?.mask || '';
        let minHost = this.vendorData.element?.minHost || '';
        let maxHost = this.vendorData.element?.maxHost || '';

        this.form = this.formBuilder.group({
            network: [network, [Validators.required, ipValidator]],
            mask: [mask, [Validators.required, ipValidator]],
            minHost: [minHost, ipValidator],
            maxHost: [maxHost, ipValidator],
        }, {validator: this._ipCalculator.maxMinHostValidator()});
    }

    setMaxMinHost(): void{
        this.disableOrEnableMinMaxHost();
        const results = this._ipCalculator.calculateMinMaxHosts(this.f['network'].value, this.f['mask'].value);
        if(results){
            this.f['minHost'].setValue(results.minHost);
            this.f['maxHost'].setValue(results.maxHost);
        }
    }

    private disableOrEnableMinMaxHost(): void{
        this.isDisableMaskNetwork = false;
        if(!this.f['network'].value && !this.f['mask'].value){

            this.f['minHost'].enable();
            this.f['maxHost'].enable();
            this.isDisableMinMaxHost = false;
        }
        else{
            this.isDisableMinMaxHost = true;
            this.f['minHost'].disable();
            this.f['maxHost'].disable();
        }
    }

    setNetworkAndMask(): void{
        this.disableOrEnableMaskNetwork();
        if(this._ipCalculator.isInRange(this.f['minHost'].value, this.f['maxHost'].value)){
            const ips = this._ipCalculator.getNetworkAddressAndMask(this.f['minHost'].value, this.f['maxHost'].value)
            if(ips && this.isDisableMaskNetwork){
                this.f['network'].setValue(ips.networkAddress);
                this.f['mask'].setValue(ips.subnetMask);
            }
        }
    }
    private disableOrEnableMaskNetwork(): void{
        this.isDisableMinMaxHost = false;
        if(!this.f['minHost'].value && !this.f['maxHost'].value){
            this.f['network'].enable();
            this.f['mask'].enable();
            this.isDisableMaskNetwork = false;
        }
        else{
            this.isDisableMaskNetwork = true;
            this.f['network'].disable();
            this.f['mask'].disable();
        }
    }

    get f() {
        return this.form.controls;
    }
    submitted: boolean = false;

    public onClickSave(): void {
        this.setErrorByTakenIp();
        if( this.form.invalid ) return;
        this.saving = true;
        this.asyncIsVendorNetworkTakenValidation();
    }

    private asyncIsVendorNetworkTakenValidation(): void {
        this._vendorNetworkServiceProxy
            .isVendorNetworkTaken(this.vendorNetworkConfiguration)
            .subscribe( response =>
                {
                    if (!response) {
                        this.networkCombinationTaken = false;
                        this.setErrorByTakenIp();
                        this._saveVendorNetwork();
                    }
                    else {
                        this.networkCombinationTaken = true;
                        this.saving = false;
                        this.setErrorByTakenIp( true );
                    }
                }
            )
    }

    // provoca que los input se marquen de color rojo
    private setErrorByTakenIp( state: boolean = null ): void {
        if ( state ) {
            this.f.network.setErrors({ networkCombinationTaken: state });
            this.f.mask.setErrors({ networkCombinationTaken: state });
        } else {
            this.f.network.setErrors( null );
            this.f.mask.setErrors( null );
        }
    }

    private _saveVendorNetwork(): void {
        const input = this.vendorNetworkConfiguration;

        if (this.vendorData.element) {
            this._vendorNetworkServiceProxy
                .updateVendorNetwork(input)
                .pipe(
                    finalize(() => {
                        this.saving = false;
                    })
                )
                .subscribe({
                    next: () => {
                        this.submitted = false;
                        this.close(1);
                    },
                    error: (error) => {
                        this.submitted = false;
                        this.close();
                    },
                });
        } else {
            this._vendorNetworkServiceProxy
                .createVendorNetwork(input)
                .pipe(
                    finalize(() => {
                        this.saving = false;
                    })
                )
                .subscribe(
                    () => {
                        this.submitted = false;
                        this.close(1);
                    },
                    (error) => {
                        this.submitted = false;
                        this.close();
                    }
                );
        }
    }

    get vendorNetworkConfiguration(): CreateOrUpdateVendorNetworkInput{
        const input = new CreateOrUpdateVendorNetworkInput();
        input.network = this.form.controls['network'].value;
        input.mask = this.form.controls['mask'].value;
        input.minHost = this.form.controls['minHost'].value;
        input.maxHost = this.form.controls['maxHost'].value;
        input.vendorId = this.vendorData.vendorId;
        input.id = this.vendorData.element?.id ?? undefined;

        return input;
    }

    close(closeType?: number): void {
        this.dialogRef.close(closeType);
    }
}

import { Component, Inject, Injector, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AccessControlFlowDto, AccessControlFlowServiceProxy, CreateOrUpdateFlowInput, CreateOrUpdateServiceInput, ServiceSelectDto, ServiceServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'vendor-service-access-control-modal',
  styleUrls: ['vendor-service-access-control-modal.component.less'],
  templateUrl: 'vendor-service-access-control-modal.component.html',

})
export class VendorServiceAccessControlModal extends AppComponentBase {
	serviceForm: FormGroup;
  submitted: boolean = false;
  saving: boolean = false;
  editing: boolean = false;
	flowLoaded: boolean = false;
  
  serviceData: any;
  
  vendorId: number;
  serviceId: number;
  accessControlFlowId: number;

  accessControlFlows: Array<AccessControlFlowDto> = [];

  serviceDto: ServiceSelectDto;

  get f() {
    return this.serviceForm.controls;
  }

  constructor(
      public dialogRef: MatDialogRef<VendorServiceAccessControlModal>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public router: Router,
      private formBuilder: FormBuilder,
      private _accessControlFlowServiceProxy: AccessControlFlowServiceProxy,
      private _servicesServiceProxy: ServiceServiceProxy,
      injector: Injector,) {
    super(injector);
    
    this.setForm(data.serviceId);

    this.serviceData = data;
  }

  ngOnInit() {
  }

  setForm(serviceId: number) {
    this._servicesServiceProxy.getService(serviceId).toPromise().then(service => {
      this.serviceDto = service;

      this.vendorId = service.vendorId;
      this.accessControlFlowId = service.accessControlFlowId;
      this.serviceId = service.id;

      this.serviceForm = this.formBuilder.group({
        'accessControlFlowId': [this.accessControlFlowId]
      });

      this._accessControlFlowServiceProxy.getFlowsByVendorId(this.vendorId).subscribe( result => {
        this.accessControlFlows = result;
        this.flowLoaded = true;
      });

    });
  }

  createAccessControlFlow(vendorId: number) {
    let newFlow = new CreateOrUpdateFlowInput();
    newFlow.vendorId = vendorId;
    newFlow.baseCDN = "";
    newFlow.isActive = true;
    if (
        this.validatePermissionAction(
            "Pages.BizDev.Tenant.Vendors.Access.Control.Create"
        )
    ) {
        this._accessControlFlowServiceProxy
            .createOrUpdateFlow(newFlow)
            .subscribe((newFlowId) => {
                setTimeout(() => {
                  this.close(1);
                    this.router.navigate([
                        "/app/biz-dev/vendors",
                        this.vendorId,
                        "access-control",
                        newFlowId,
                    ]);
                }, 1000);
            });
    }
  }

  goToEditAccessControlFlow(serviceId: number, accessControlFlowId: number) {
    setTimeout(() => {
      let url = `/app/biz-dev/services/${serviceId}/access-flow/${accessControlFlowId}/basic`;
      window.open(url, '_blank');
    }, 500);
  }

  public save() {
    this.submitted = true;
    if (this.serviceForm.invalid) {
        return;
    }
    
    this.saving = true;

    let serviceInput = new CreateOrUpdateServiceInput();

    serviceInput.id = this.serviceId;
		serviceInput.name = this.serviceDto.name;
		serviceInput.productId = this.serviceDto.productId;
		serviceInput.vendorId = this.serviceDto.vendorId;
		serviceInput.provisionerId = this.serviceDto.provisionerId;
		serviceInput.fixedRate = this.serviceDto.fixedRate;
		serviceInput.revenueShare = this.serviceDto.revenueShare;
		serviceInput.url = this.serviceDto.url;
		serviceInput.vendorServiceId = this.serviceDto.vendorServiceId;
		serviceInput.inputShortNumberId = this.serviceDto.inputShortNumber ? this.serviceDto.inputShortNumber.id : null;
		serviceInput.outputShortNumberId = this.serviceDto.outputShortNumber ? this.serviceDto.outputShortNumber.id : null;
		serviceInput.billingCycle = this.serviceDto.billingCycle;
		serviceInput.ownerId = this.serviceDto.owner ? this.serviceDto.ownerId : null;
		serviceInput.notificationType = this.serviceDto.notificationType;
		serviceInput.alias = this.serviceDto.alias;
		serviceInput.externalId = this.serviceDto.externalId;
		serviceInput.notificationConfiguration = this.serviceDto.notificationConfiguration;
		serviceInput.isActive = this.serviceDto.isActive;

    // campo de interes
    serviceInput.accessControlFlowId = this.serviceForm.value.accessControlFlowId;

    if (!this.validatePermissionAction('Pages.BizDev.Tenant.Services.Edit')) return false;



    this._servicesServiceProxy.updateService(serviceInput)
    .pipe(finalize(() => { this.saving = false; }))
    .subscribe({
      next:() => {
        this.submitted = false;
        this.notify.success(this.l('MessageAssociatedSuccessully'));
        this.close(1);
      },
      error: (error) => {
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  
  close(closeType?: number): void {
    this.dialogRef.close(closeType);
  }

}


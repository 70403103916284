import { AcquisitionFieldType, MergeAcquisitionFlowOperationDto, OperationAcquisitionOptionListDto } from "@shared/service-proxies/service-proxies";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { OperationAcquisitionOption, AcquisitionFlowOperationView } from "./models";
import { IAllElements } from "../layout-builder/layout-builder.component";

import { AmpBaseUrls } from '@shared/AppBaseUrls';
import { SettingService } from 'abp-ng2-module';
import { OperationAccessOption } from "@app/biz-dev/vendors/access-control-flow/models";



export class CardOperation {
    name: string
    order: number;
    ordereable: boolean;
    operations: CardAcquisitionFlowOperation[];
    showTabs: boolean;
    slug:string;
    selected:boolean;

    constructor(name: string, order: number, ordereable: boolean, operations: CardAcquisitionFlowOperation[], showTabs: boolean, slug:string) {
        this.name = name;
        this.order = order;
        this.ordereable = ordereable;
        this.operations = operations;
        this.showTabs = showTabs;
        this.slug = slug;
        this.selected = false;
    }
}




export class CardAcquisitionFlowOperation extends MergeAcquisitionFlowOperationDto {
    id!: number;
    frontName: string;
    formValues: OperationAcquisitionOption[] = [];
    bodyValues: OperationAcquisitionOption[] = [];
    form: FormGroup;
    fb: FormBuilder;
    htmlcontent!: string;
    allElements: IAllElements;
    dbImageUrl!: string;
    layoutWithSourceUrl!: {[key: string]: string};
    renderResultCases: Array<any> = [];
    selectOptions?= [];
    selectedCase?:string;
    renderResultOptions?:string;
    slug:string;
    

    constructor(CardAcquisitionFlowOperation: MergeAcquisitionFlowOperationDto, fronName: string, allElements: IAllElements) {
        super(CardAcquisitionFlowOperation);
        this.layoutWithSourceUrl = this.layout;
        this.id = CardAcquisitionFlowOperation.acquisitionFlowOperationId;
        this.frontName = fronName;
        this.fb = new FormBuilder();
        this.form = this.fb.group({});
        this.htmlcontent = JSON.stringify(CardAcquisitionFlowOperation.layout);
        this.allElements = allElements;

        this.renderResultOptions = JSON.stringify(CardAcquisitionFlowOperation.options);
        if (this.acquisitionFlowOperation.operation.name == "RenderResult") {
            this.setRenderResultCases();
        }
        this.setForm();
    }


    setForm() {

        let objectOptions;
        let urlEndPoint: string = "";
        let overrideableValues: OperationAcquisitionOption[] = [];
        let bodyValues: OperationAcquisitionOption[] = [];
        objectOptions = this.options;
        
        if (this.acquisitionFlowOperation.operation.name == "RenderResult") {
            objectOptions = JSON.parse(this.renderResultOptions);
        }

        this.acquisitionFlowOperation.operation.options.forEach((option:OperationAcquisitionOptionListDto) => {
            if (option.overrideLevel >= 3 && !option.isLayoutOption ) {
                if (option.acquisitionFieldType == AcquisitionFieldType.FileImage || option.acquisitionFieldType == AcquisitionFieldType.FileStylesheet) {
                    urlEndPoint = "/Upload/UploadAcquisitionControlElement";
                }
                overrideableValues.push(new OperationAcquisitionOption(option, objectOptions[option.slug], urlEndPoint, false));
            }

            
            if (this.acquisitionFlowOperation.operation.bodyEnabled) {
                if (
                    option.isLayoutOption || (option.slug == "confirm-button-text" ||
                    option.slug == "confirm-message" ||
                    option.slug == "continue-message" ||
                    option.slug == "continue-button-text" ||
                    option.slug == "no-wifi-retry-button-text" ||
                    option.slug == "no-wifi-message" ||
                    option.slug == "remember-me-text" ||
                    option.slug == "authentication-failed-message" ||
                    option.slug == "number-prefix" ||
                    option.slug == "number-label" ||
                    option.slug == "number-placeholder" ||
                    option.slug == "submit-button-text") 
                ) {
                    bodyValues.push(new OperationAcquisitionOption(option, objectOptions[option.slug], "", false))
                }
            }
        })
        if (this.acquisitionFlowOperation.operation.name == "RenderResult") {

            this.renderResultCases.forEach(c => {
                this.acquisitionFlowOperation.operation.options.forEach(option => {
                    c.form.forEach(field => {
                        if (field.name == option.slug) {
                            bodyValues.push(new OperationAcquisitionOption(option, objectOptions[option.slug], "", false, c.case))
                        }
                    })
                })
            })
        }
        this.bodyValues = bodyValues;

        this.formValues = overrideableValues;
        this.formValues.forEach(formValue => this.form.addControl(formValue.slug, new FormControl(formValue.value)))
        this.bodyValues.forEach(formValue => this.form.addControl(formValue.slug, new FormControl(formValue.value)))
    }

    setRenderResultCases() {
        // nuevo feature de opciones de renderresult
        let options = JSON.parse(this.acquisitionFlowOperation.options); // opciones de string a objeto
        console.log(options);
        let optionsMessages: string[] = []; // array para guardar las propiedades "result-message-"
        let optionsMessagesField: string[] = []; // array para guardar las propiedades sin los prefijos ej: con pre=>result-message-subscribe, sin pre => suscribed
        let optionsButtons: string[] = []; // array para guardar las propiedades "result-button-text-"
        let optionsButtonsField: string[] = [];  // array para guardar las propiedades sin los prefijos ej: con pre=>result-button-text-subscribe, sin pre => suscribed


        console.log(options);
        let currentOptions : String[] = [];
        for (var op in options) {
            
            if (op.match('result-message-') || op.match('result-action-')) { // busco propiedades con el prefijo "result-message-"
                let splitedOption: string[] = op.split('-'); // creo un nueno array en base al '-' de la llave
                let name = splitedOption.splice(2, splitedOption.length - 1); // obtengo el nombre sin prefijo
                let optionsMessagesFieldName = name.join('-');

                if (optionsMessagesField.indexOf(optionsMessagesFieldName) == -1) {
                    optionsMessagesField.push(name.join('-')); // agrego el nombre coin guion al array de optionsMessagesField;
                }

                if (options[op] != "" && op != "result-action-already-subscribed" && op != "result-action-subscribed") {
                    if (currentOptions.indexOf(name.join('-')) == -1) {
                        currentOptions.push(name.join('-'));
                        this.selectOptions.push({ name: name.join(' '), slug: name.join('-') }); // agrego al array del select su opcion
                    }
                }

                if (optionsMessages.indexOf(op) == -1) {
                    optionsMessages.push(op);
                }
                
            }
            if (op.match('result-button-')) {
                let splitedOption: string[] = op.split('-');
                let name = splitedOption.splice(3, splitedOption.length - 1);
                optionsButtonsField.push(name.join('-'));
                optionsButtons.push(op);
            }
        }

      
        
        for (let i = 0; i < optionsMessagesField.length; i++) {
            let form = this.fb.group({});
            let formOption = {
                form: [],
                formgroup: form
            }
            let opMessage = optionsMessagesField[i];
            let pos = optionsButtonsField.indexOf(opMessage);

           
            formOption['case'] = optionsMessagesField[i];
           
            let formArray = [];

            // creo la isntancia del formulartio
            formOption.formgroup.addControl('result-message-' + opMessage, new FormControl(options['result-message-' + opMessage]));
            formOption.formgroup.addControl('show-button', new FormControl(pos != -1));
            formOption.formgroup.addControl('result-button-text-' + opMessage, new FormControl(options['result-button-text-' + opMessage]));
            formOption.formgroup.addControl('result-action-' + opMessage, new FormControl(options['result-action-' + opMessage]));
            // creo la isntancia del formulartio

            //creo objecto con valores del formulario para itera en el html
            // opMessage == 'already-subscribed' && options['result-message-' + opMessage] != null )
            // || (opMessage == 'subscribed' && options['result-message-' + opMessage] != null ) || 
            if (options['result-message-' + opMessage] != null) {
                formArray.push({
                    name: 'result-message-' + opMessage,
                    value: options['result-message-' + opMessage]
                })

                formArray.push({
                    name: 'show-button',
                    value: pos != -1
                })

                formArray.push({
                    name: 'result-button-text-' + opMessage,
                    value: options['result-button-text-' + opMessage]
                })


                formArray.push({
                    name: 'result-action-' + opMessage,
                    value: options['result-action-' + opMessage]
                })

                formOption.form = formArray;
                this.renderResultCases.push(formOption);
            }
            
            //creo objecto con valores del formulario para itera en el html
        }


        // console.log(this.formOptions);
        // nuevo feature de opciones de renderresult


    }
}


export class CardVendorOperation {
    name: string
    order: number;
    ordereable: boolean;
    operations: CardVendorAcquisitionFlowOperation[];
    showTabs: boolean;
    slug:string;
    selected:boolean;

    constructor(name: string, order: number, ordereable: boolean, operations: CardVendorAcquisitionFlowOperation[], showTabs: boolean, slug:string, selected:boolean) {
        this.name = name;
        this.order = order;
        this.ordereable = ordereable;
        this.operations = operations;
        this.showTabs = showTabs;
        this.slug = slug;
        this.selected = selected;
    }
}


export class CardVendorAcquisitionFlowOperation extends AcquisitionFlowOperationView {
    id!: number;
    frontName: string;
    formValues: OperationAcquisitionOption[] = [];
    bodyValues: OperationAcquisitionOption[] = [];
    form: FormGroup;
    fb: FormBuilder;
    htmlcontent!: string;
    allElements: IAllElements;
    dbImageUrl!: string;
    layoutWithSourceUrl!: {[key: string]: string};
    renderResultCases: Array<any> = [];
    selectOptions?= [];
    selectedCase?:string;
    renderResultOptions?:string;
    slug:string;
    isCardOperation:boolean;

    setting:SettingService;
    ampBaseUrls;
    

    constructor(CardVendorAcquisitionFlowOperation: AcquisitionFlowOperationView, fronName: string, allElements: IAllElements) {
        super(
            CardVendorAcquisitionFlowOperation.id ,
            CardVendorAcquisitionFlowOperation.operationId,
            CardVendorAcquisitionFlowOperation.bodyEnabled,
            CardVendorAcquisitionFlowOperation.layoutEnabled,
            CardVendorAcquisitionFlowOperation.name,
            CardVendorAcquisitionFlowOperation.type,
            CardVendorAcquisitionFlowOperation.options,
            CardVendorAcquisitionFlowOperation.inFlow,
            CardVendorAcquisitionFlowOperation.order,
            CardVendorAcquisitionFlowOperation.layout,
            CardVendorAcquisitionFlowOperation.isActive,
            CardVendorAcquisitionFlowOperation.disabledDrag,
            CardVendorAcquisitionFlowOperation.isDirty,
            CardVendorAcquisitionFlowOperation.externalSave,
            CardVendorAcquisitionFlowOperation.previewRender,
            CardVendorAcquisitionFlowOperation.stringOptions,
            CardVendorAcquisitionFlowOperation.stringOperationOptions,
            CardVendorAcquisitionFlowOperation.isDeleted
        );
        this.layoutWithSourceUrl = this.layout;
        this.id = CardVendorAcquisitionFlowOperation.id;
        this.frontName = fronName;
        this.fb = new FormBuilder();
        this.form = this.fb.group({});
        this.htmlcontent = JSON.stringify(CardVendorAcquisitionFlowOperation.layout);
        this.allElements = allElements;
        this.isCardOperation = false;
        
        this.renderResultOptions = JSON.stringify(CardVendorAcquisitionFlowOperation.options);

        if (this.name == "RenderResult") {
            this.setRenderResultCases();
        }

        this.ampBaseUrls = new AmpBaseUrls();

        this.setForm();
    }


    setForm() {
        let objectOptions;
        let urlEndPoint: string = "";
        let overrideableValues: OperationAcquisitionOption[] = [];
        let bodyValues: OperationAcquisitionOption[] = [];
        objectOptions = this.options;
        
        if (this.name == "RenderResult") {
            objectOptions = JSON.parse(this.renderResultOptions);

        }
        
        this.options.forEach((option, i) => {

            if (option.overrideLevel >= 3) {
                if (option.acquisitionFieldType == 0 || option.acquisitionFieldType == 2) {
                    urlEndPoint = "/Upload/UploadAcquisitionControlElement";
                }
                
                if (option.slug == 'background-image') {
                    option.value = `${this.ampBaseUrls.urls.bucket}defaults/preview_default_bg.jpg`;
                } 

                overrideableValues.push(new OperationAcquisitionOption(option, option.value, urlEndPoint, false));
            }


            if (this.bodyEnabled) {
                if (
                    option.slug == "confirm-button-text" ||
                    option.slug == "confirm-message" ||
                    option.slug == "continue-message" ||
                    option.slug == "continue-button-text" ||
                    option.slug == "no-wifi-retry-button-text" ||
                    option.slug == "no-wifi-message" ||
                    option.slug == "remember-me-text" ||
                    option.slug == "authentication-failed-message" ||
                    option.slug == "number-prefix" ||
                    option.slug == "number-label" ||
                    option.slug == "number-placeholder" ||
                    option.slug == "submit-button-text"
                ) {
                    bodyValues.push(new OperationAcquisitionOption(option, option.value, "", false))
                }

            }
            

            if (this.name == "RenderResult") {
                this.renderResultCases.forEach(c => {
                    c.form.forEach(field => {
                        if (field.name == option.slug) {
                            bodyValues.push(new OperationAcquisitionOption(option, option.value, "", false, c.case))
                        }
                    })
                })
            }
        })

        this.bodyValues = bodyValues;

        this.formValues = overrideableValues;
        this.formValues.forEach(formValue => this.form.addControl(formValue.slug, new FormControl(formValue.value)))
        this.bodyValues.forEach(formValue => this.form.addControl(formValue.slug, new FormControl(formValue.value)))


    }

    setRenderResultCases() {
        // nuevo feature de opciones de renderresult

        let options = {}; // opciones de string a objeto
        this.options.forEach(option => {
            options[option.slug] = option.value;
        })


        let optionsMessages: string[] = []; // array para guardar las propiedades "result-message-"
        let optionsMessagesField: string[] = []; // array para guardar las propiedades sin los prefijos ej: con pre=>result-message-subscribe, sin pre => suscribed
        let optionsButtons: string[] = []; // array para guardar las propiedades "result-button-text-"
        let optionsButtonsField: string[] = [];  // array para guardar las propiedades sin los prefijos ej: con pre=>result-button-text-subscribe, sin pre => suscribed

       
        for (let op in options) {
            if (op.match('result-message-')) { // busco propiedades con el prefijo "result-message-"
                let splitedOption: string[] = op.split('-'); // creo un nueno array en base al '-' de la llave
                let name = splitedOption.splice(2, splitedOption.length - 1); // obtengo el nombre sin prefijo
                optionsMessagesField.push(name.join('-')); // agrego el nombre coin guion al array de optionsMessagesField;
                this.selectOptions.push({ name: name.join(' '), slug: name.join('-') }); // agrego al array del select su opcion
                optionsMessages.push(op);
            }
            if (op.match('result-button-')) {
                let splitedOption: string[] = op.split('-');
                let name = splitedOption.splice(3, splitedOption.length - 1);
                optionsButtonsField.push(name.join('-'));
                optionsButtons.push(op);
            }
        }

        for (let i = 0; i < optionsMessagesField.length; i++) {
            let form = this.fb.group({});
            let formOption = {
                form: [],
                formgroup: form
            }
            let opMessage = optionsMessagesField[i];
            let pos = optionsButtonsField.indexOf(opMessage);

           
            formOption['case'] = optionsMessagesField[i];
           
            let formArray = [];

            // creo la isntancia del formulartio
            formOption.formgroup.addControl('result-message-' + opMessage, new FormControl(options['result-message-' + opMessage]));
            formOption.formgroup.addControl('show-button', new FormControl(pos != -1));
            formOption.formgroup.addControl('result-button-text-' + opMessage, new FormControl(options['result-button-text-' + opMessage]));
            formOption.formgroup.addControl('result-action-' + opMessage, new FormControl(options['result-action-' + opMessage]));
            // creo la isntancia del formulartio

            //creo objecto con valores del formulario para itera en el html
            formArray.push({
                name: 'result-message-' + opMessage,
                value: options['result-message-' + opMessage]
            })

            formArray.push({
                name: 'show-button',
                value: pos != -1
            })

            formArray.push({
                name: 'result-button-text-' + opMessage,
                value: options['result-button-text-' + opMessage]
            })

            formArray.push({
                name: 'result-action-' + opMessage,
                value: options['result-action-' + opMessage]
            })

            formOption.form = formArray;
            this.renderResultCases.push(formOption);
            //creo objecto con valores del formulario para itera en el html
        }

        // console.log(this.formOptions);
        // nuevo feature de opciones de renderresult


    }
}

interface ILayoutItem {
    id:string,
    value:string,
    class:any[]
}

export interface ICommonOptions {
    options:any,
    layout:any,
    images: string[],
    colors:string[],
    strings:any[]
    layoutImages:string[],
    layoutParagraph:string[],
    layoutItems:{}
}

export interface ICommonOptionsDialogs {
    commonOptions: ICommonOptions,
    field: OperationAcquisitionOption | OperationAccessOption,
    baseCDN:string,
    serviceId:number,
}

import { Component, ViewChild, Injector, Output, EventEmitter, ElementRef, Inject, LOCALE_ID, SimpleChanges, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AgGridAngular} from 'ag-grid-angular';
import 'ag-grid-enterprise'
import { ColDef, Column, ColumnApi, GridApi, RowNode, ValueFormatterParams } from 'ag-grid-enterprise';
import { key } from 'localforage';
import { Dictionary, isNumber } from 'lodash';
import { Observable } from 'rxjs';

export interface gridColPropertiesInterface {
    renderers: object,
    headers: object,
    urls: object,
    minWidth: object,
    flags: object,
    type?: object
  }


@Component({
    selector: "grid-component",
    templateUrl: './grid.component.html',
    styleUrls:['./grid.component.less']
})
export class GridComponent extends AppComponentBase{
    @ViewChild('agGrid') agGrid!: AgGridAngular;
    @Input() colProperties: gridColPropertiesInterface;
    @Input() columns: Array<String> = [];
    @Input() rowData: Observable<any[]>;
    @Input() footerDef: any = {};
    @Output() finishedGrid = new EventEmitter<{
        gridApi: GridApi,
        columnApi: ColumnApi
    }>();
    gridApi!: GridApi;
    data: any;
    context: any = this;
    columnDefs: Array<any>=[];
    definer: any;
    defaultColDef: any;
    autoGroupColumnDef: any;
    popupParent: any;
    groupDisplayType:any;
    rowGroupPanelShow:string;
    sidebar:any;
    noRowOverlay:boolean= false;
    columnApi: any;

    constructor(
        injector: Injector,
        public activateRouter: ActivatedRoute,
        public router: Router
    ) {
        super(injector)
        this.rowGroupPanelShow= 'always';
        this.sidebar= 'columns';
        this.groupDisplayType = 'multipleColumns';
        this.defaultColDef = {
            sortable: true,
            flex: 1,
            minWidth: 120,
            filter: 'agMultiColumnFilter',
            floatingFilter: true,
            resizable: true,
          };
        this.autoGroupColumnDef = {
          pinned:'left',
          sortable: true, 
          filter:true 
        };
        this.popupParent = document.body;
        this.definer = function columnDefinition(keys: Array<string>){
            var columns : Array<ColDef> = [];
            keys?.forEach(element => {
                    let colDef: ColDef = {
                        field: element,
                        colId: element,
                        headerName: this.colProperties.headers[element],
                        minWidth: this.colProperties.minWidth[element] ?? 140,
                        enableRowGroup: false,
                        sortable: true,
                        filter: this.colProperties.type?.[element] == null ? true : (this.colProperties.type[element] == "number" ? 'agNumberColumnFilter' : 'agTextColumnFilter'),
                        valueFormatter: this.colProperties.renderers[element],
                        suppressMovable: false,
                        cellRenderer: this.colProperties.urls[element]
                    };
                    columns.push(colDef);

            });
            return columns;
        }

        
    }
    
    onComponentStateChanged(params){
        this.gridApi = params.api
        this.columnApi = params.columnApi
        this.finishedGrid.emit({
            gridApi: this.gridApi,
            columnApi: this.columnApi
        })
        let rowData = []
        this.agGrid?.api.forEachNodeAfterFilter((node) => rowData.push(node.data));
        if (Object.keys(this.footerDef).length > 0){
            let pinnedRowData = [];
            pinnedRowData.push(this.createFooter(rowData));
            this.agGrid?.api.setPinnedBottomRowData(pinnedRowData)
        }
    }

    gridFilterChange(params){
        this.gridApi = params.api
        this.columnApi = params.columnApi
        this.finishedGrid.emit({
            gridApi: this.gridApi,
            columnApi: this.columnApi
        })
        let rowData = []
        this.gridApi.forEachNodeAfterFilter((node) => rowData.push(node.data));
        if(Object.keys(this.footerDef).length > 0){
            let pinnedRowData = [];
            pinnedRowData.push(this.createFooter(rowData));
            this.gridApi.setPinnedBottomRowData(pinnedRowData)
        }
        
    }

    onGridReady(){
    }

    defineGrid(){
        if (this.columnDefs.length > 0){
            return
        }else{
            this.columnDefs = this.definer(this.columns)
        }
        return
    }
/* 
    getAllNodes(){
        let rows = []
        this.agGrid.api.forEachNode(node => rows.push(node))
        return rows
    } */

    ngOnInit(){
        this.noRowOverlay = false
        this.rowData.subscribe(element =>{
            if (element.length == 0){
                this.noRowOverlay = true
            }
        })
    }

    ngOnChanges(change: SimpleChanges){
        this.noRowOverlay = true
        this.defineGrid()
        this.agGrid?.api.redrawRows()
        this.rowData.subscribe(element =>{
            if (element.length == 0){
                this.noRowOverlay = false
            }
        })
        console.log(this.colProperties.flags)
    }

     

    createFooter(rowData) {
        let bottomRow = {};
        rowData.forEach((element) => {
            Object.keys(this.footerDef).forEach((key) => {
                if (isNumber(this.footerDef[key])){
                    const isFirst = bottomRow[key] === undefined;
                    if(isFirst){
                        bottomRow[key] =  isNaN(element[key]) ? 0 : (element[key] ?? 0);
                    } else {
                        bottomRow[key] += isNaN(element[key]) ? 0 : (element[key] ?? 0);
                    }
                } else {
                    bottomRow[key] = this.footerDef[key]
                }
            })
        });
        return bottomRow;
    }
}



import { Component, Injector, OnInit, AfterViewInit, ViewChild, ElementRef, Inject, ChangeDetectorRef } from '@angular/core';
import { AcquisitionOperationForm } from './acquisition-operation-form/acquisition-operation-form.component'
import { MatChipInputEvent } from '@angular/material/chips';
import {
    AcquisitionFlowServiceProxy,
    AcquisitionOperationServiceProxy,
    AcquisitionFlowDto,
    AcquisitionFlowOperationDto,
    AddOrUpdateAcquisitionFlowOperationInput,
    SetAcquisitionFlowOperationsOrderInput,
    AcquisitionPreviewDto,
    AcquisitionPreviewServiceProxy,
    PreviewAcquisitionRenderUserIdFormDto,
    PreviewAcquisitionRenderOTPFormDto,
    PreviewAcquisitionStartOptInDto,
    PreviewAcquisitionConfirmOptInDto,
    PreviewAcquisitionTermsDto,
    UpdateAcquisitionFlowDto,
    PreviewAcquisitionRenderResultDto,
    FieldType,
    AcquisitionFieldType,
    OverrideLevel,
    AcquisitionFlowTemplateSelectDto,
    AcquisitionFlowTemplateServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CanComponentDeactivate } from '../../access-control-flow/CanDeactivateGuard';
import { CustomValidators } from 'ngx-custom-validators';
import { BreadcrumbItem } from '@app/shared/common/sub-header/sub-header.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, observable, of } from 'rxjs';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AcquisitionFlowOperationView, IflowWithOperation, OperationAcquisitionOption, IFormAndLayout, GenericPreviewForm } from '../models';
import { MatSelectChange } from '@angular/material/select/';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { DOCUMENT } from "@angular/common";
import { forEach as _forEach } from 'lodash-es';
import { PathService } from '@app/shared/services/PathService';
import { AmpGlobalSpinnerService } from '@app/shared/amp-global-spinner.service';
import { finalize, take } from 'rxjs/operators';
import { UploadFileService } from '../../../../upload-file-service';

export enum UploadType {
    FileImage,
    FileScript,
    FileStylesheet,
    VendorLogo,
    ProductLogo,
    AggregatorLogo,
    ProvisionerLogo
}

@Component({
    templateUrl: './acquisition-flow-advanced.component.html',
    styleUrls: ['./acquisition-flow-advanced.component.less'],
    animations: [appModuleAnimation()]
})
export class AcquisitionFlowAdvancedComponent extends AppComponentBase implements OnInit, AfterViewInit, CanComponentDeactivate {
    
    @ViewChild('iframe', { static: true }) iframe: ElementRef; // referencia al iframe en la vista
    @ViewChild(AcquisitionOperationForm, { static: true }) AcquisitionOperationForm;
    @ViewChild('acqOperation') acqOperation: AcquisitionFlowServiceProxy;
    acquisitionFlow: AcquisitionFlowDto;
    acquisitionOperations: AcquisitionFlowOperationView[] = [];
    selectedOperations: AcquisitionFlowOperationView[] = [];
    serviceId: number;
    loadedFlow: boolean = false;
    step: number;
    baseCDN: string = this.getAmpBaseUrlsForEnviroment.acquisition;
    showIframe: boolean = false;
    vendorId: number;
    flowId: number;
    removable = true;
    submitted: boolean = false;
    breadCrum: BreadcrumbItem[];
    addOnBlur = true;
    flowName: string;
    vendorName: string;
    visible = true;
    selectable = true;
    description: string;
    acquisitionFlowForm: FormGroup;
    flow: AcquisitionFlowDto; // acquisition flow actual
    order: number = 0; // valor para contador del orden de las operaciones
    inFlowOperations = []; // operationes en el flow
    selectedOperation: any // valor del select de operationces
    dirtyForm: boolean; // variable que define si algun formulario esta tocado sin guardar
    
    templatesList:AcquisitionFlowTemplateSelectDto[] = [];
    
    readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
    loading: boolean = false;
    alreadyTryToGetTemplates: boolean = false;
    loadingTemplates: boolean = false;

    constructor(
        injector: Injector,
        private _acquisitionFlowServiceProxy: AcquisitionFlowServiceProxy,
        private _uploadService: UploadFileService,
        private _acquisitionOperationServiceProxy: AcquisitionOperationServiceProxy,
        private _ampSpinner: AmpGlobalSpinnerService,
        private _acquisitionPreviewServiceProxy: AcquisitionPreviewServiceProxy,
        private _acqFlowTemplateService: AcquisitionFlowTemplateServiceProxy,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private router: Router,
        private cdRef: ChangeDetectorRef,
        private pathService: PathService,
        @Inject(DOCUMENT) private document: Document
    ) {
        super(injector);
        this.buildForm();

        
        const {vendorId, flowId} =this.route.snapshot.params;
        this.vendorId = parseInt(vendorId);
        this.flowId = parseInt(flowId);

        this.checkIfNumber(this.vendorId);
        this.checkIfNumber(this.flowId);

    }

    fillTemplatesOptions(){
        if(this.alreadyTryToGetTemplates) return;
        this.loadingTemplates = true
        this._acqFlowTemplateService.getByVendor(this.vendorId)
            .pipe(
                take(1), 
                finalize(() => {
                    this.alreadyTryToGetTemplates = true;
                    this.loadingTemplates = false
                }))
            .subscribe( templates => {
                this.templatesList = templates;
            })
    }

    ngOnInit(): void {
        this._uploadService.initFileUploaderAcquisitionFlow();
        

        let acquisitionFormData: IAcquisitionForm = {
            name: '',
            description: '',
            hostBaseUrl: 'https://acq.vas.ac',
            antiClickHijacking: false,
            gtmContainers: []
        }
        this.setForm(acquisitionFormData)
    }
    
    get f() { return this.acquisitionFlowForm.controls; }

    async ngAfterViewInit() {
            this.loading = true;
            const data = await this.getFlowAndOperations();
            this.loading = false;
            this.cdRef.detectChanges();
                
            let gtmContainers: string[];
            if (data.flow.gtmContainers != null) {
                gtmContainers = data.flow.gtmContainers.split(',');

            } else {
                gtmContainers = [];
            }

            let hostBaseUrl = 'https://acq.vas.ac';
            if (data.flow.hostBaseUrl && data.flow.hostBaseUrl != "" && data.flow.hostBaseUrl != null) {
                hostBaseUrl = data.flow.hostBaseUrl;
            }
            this.acquisitionFlow = data.flow;
            this.acquisitionOperations = data.operations;
            this.inFlowOperations = data.inFlowOperations;
            this.description =  data.flow.description;
            this.flowName = data.flow.name;
            this.vendorName = data.flow.vendor.name;
            this.loadedFlow = true;

            let acquisitionFormData: IAcquisitionForm = {
                name: data.flow.name,
                description: data.flow.description,
                hostBaseUrl: hostBaseUrl,
                antiClickHijacking: data.flow.antiClickHijacking,
                gtmContainers: gtmContainers
            }
            this.setForm(acquisitionFormData);
            this.breadCrum = [
                new BreadcrumbItem(this.vendorName, `/app/biz-dev/vendors/${this.vendorId}`),
                new BreadcrumbItem(this.flowName, `/app/biz-dev/vendors/${this.vendorId}/acquisition-flow/${this.flowId}`),
                new BreadcrumbItem(this.l('Advanced'))
            ]
       
    }

    private buildForm() {
        this.acquisitionFlowForm = this.formBuilder.group({
            'name': ['', Validators.required],
            'description': ['', Validators.required],
            'hostBaseUrl': ['', [Validators.required, CustomValidators.url]],
            'acqFlowTemplate': ['',[Validators.required]],
            'antiClickHijacking': [''],
            'gtmContainers': this.formBuilder.array([])
        });
    }

    setForm(acquisitionForm: IAcquisitionForm) {
        let name = acquisitionForm.name;
        let description = acquisitionForm.description;
        let hostBaseUrl = acquisitionForm.hostBaseUrl;
        let antiClickHijacking = acquisitionForm.antiClickHijacking;
        let gtmContainers = acquisitionForm.gtmContainers;

        this.acquisitionFlowForm.patchValue({
            'name': name, 
            'description': description, 
            'hostBaseUrl': hostBaseUrl,
            'antiClickHijacking': antiClickHijacking,
        });

        this.acquisitionFlowForm.get('gtmContainers') .setValue(gtmContainers)
    }

   async saveAcquisitionFlow() {
        this.acquisitionFlowForm.markAllAsTouched();
        this.submitted = true;

        if (!this.validatePermissionAction('Pages.BizDev.Flow.Tenant.Vendor.Edit')) return false;
        
        if(this.acquisitionFlowForm.invalid ){
            return;
        }

        this._ampSpinner.showSpinner("normal");
        this.inFlowOperations;
        let operations: AddOrUpdateAcquisitionFlowOperationInput[] = []

        let input: UpdateAcquisitionFlowDto = new UpdateAcquisitionFlowDto();

        if (this.acquisitionFlowForm.value.gtmContainers.length > 0) 
            input.gtmContainers = this.acquisitionFlowForm.value.gtmContainers.join();
        else 
            input.gtmContainers = null;
        input.antiClickHijacking = this.acquisitionFlowForm.value.antiClickHijacking;

        input.name = this.acquisitionFlowForm.value.name;
        input.description = this.acquisitionFlowForm.value.description;
        input.hostBaseUrl = this.acquisitionFlowForm.value.hostBaseUrl;
        input.acquisitionFlowTemplateId = this.acquisitionFlowForm.value.acqFlowTemplate;
        input.id = this.flowId;

        this.inFlowOperations.forEach(operation => {
            let optionsToSave = operation.stringOptions;
            let jsonOptions = JSON.parse(optionsToSave);
            let filtreredOptions = {};

            for (var i in jsonOptions) {
                if (typeof jsonOptions[i] != "boolean" && (jsonOptions[i] == null || jsonOptions[i] == "")) {
                    filtreredOptions[i] = "";
                } else if (typeof jsonOptions[i] == "boolean") {
                    filtreredOptions[i] = jsonOptions[i].toString();
                } else {
                    filtreredOptions[i] = jsonOptions[i];
                }
            }

            optionsToSave = JSON.stringify(filtreredOptions);


            // if (operation.isDirty) {
            let op = new AddOrUpdateAcquisitionFlowOperationInput(
                {
                    id: operation.id,
                    flowId: this.flowId,
                    operationId: operation.operationId,
                    options: optionsToSave,
                    layout: JSON.stringify(operation.layout),
                    order: operation.order,
                    isActive: true
                }
            )
            operations.push(op);
            // }
            //ANTES SE VERIFICABA SI LA OPERACION HABIA SIDO TOCADA DE ALGUNA MANERA PARA ASI GUARDARLA Y NO GUARDAR CADA VEZ
            //QUE SE GUARDE UN FLUJO, TODAS LAS OPERACIONES, PERO SI SE CAMBIA ALGO EN EL SEED DE OPERACIONES
            // ENTONCES LOS OPERADORES (IVAN, DIEGO) TENDRIA QUE TOCAR EL FORMULARIO PARA PODER GUARDAR CON LOS CAMPOS NUEVOS FLUJO POR FLUJO Y ES MUCHO TRABAJO
        });

        input.operations = operations;
        
        try {
            const uploadResult = await this._uploadService.uploadPendingFiles();
            const uploadBackupResult = await this._uploadService.uploadBackupFiles();
            const restoreFromBackup = await this._uploadService.restoreFileFromBackup();
    
            if (uploadResult.success && uploadBackupResult.success && restoreFromBackup.success)
            {
                this._acquisitionFlowServiceProxy.updateFlow(input)
                       .pipe(finalize(() => this._ampSpinner.hideSpinner()))
                       .subscribe((data) => {  
                        this.inFlowOperations.forEach(operation => {
                            var createdOperation = data.operations.find(oper => oper.operationId === operation.operationId);
                            operation.id = createdOperation ? createdOperation.id : operation.id;
                            operation.isDirty = false;
                        });
                        this.message.success(this.l('SavedSuccessfully'));
                        this.submitted = false;
                        this.dirtyForm = false;
                        this.router.navigate([`app/biz-dev/vendors/${this.vendorId}/acquisition-flow/${this.acquisitionFlow.id}`])
                    });
            }
            else
                this.message.error(`Files updated: ${uploadResult.message} Files backup: ${uploadBackupResult.message}`);
        } catch (error) {
            this._ampSpinner.hideSpinner();
        }
     
        
    }

    setFlowOrder(): Promise<any> {

        let operationsIds: number[] = [];
        this.inFlowOperations.forEach(operation => operationsIds.push(operation.id));
        let newFlowOrder = new SetAcquisitionFlowOperationsOrderInput({
            flowId: this.flowId, 
            acquisitionFlowOperationIds: operationsIds
        })
        return this._acquisitionFlowServiceProxy.setFlowOrder(newFlowOrder).toPromise();
    }

    validFlowInVendor: boolean = true;
    async getFlowAndOperations(): Promise<IflowWithOperation<AcquisitionFlowDto>> {

        let acquisitionFlowPromise = this._acquisitionFlowServiceProxy.getFlow(this.flowId).toPromise();
        let acquisitionOperationsPromise = this._acquisitionOperationServiceProxy.getOperations().toPromise();
        try {
            let acquisitionFlow = await acquisitionFlowPromise;
            
            this.templatesList = [acquisitionFlow.acquisitionFlowTemplate];
            this.acquisitionFlowForm.get('acqFlowTemplate').setValue(acquisitionFlow.acquisitionFlowTemplate.id);

            this.checkIfExists(acquisitionFlow);
            if (acquisitionFlow.vendorId != this.vendorId) {

                this.validFlowInVendor = false;
                return { flow: acquisitionFlow, operations: [], inFlowOperations: [] };
            }


            let acquisitionOperations = await acquisitionOperationsPromise;
            let acquisitionOperationsWithOutOptions: AcquisitionFlowOperationView[] = [];
            let flowOperations: AcquisitionFlowOperationDto[] = acquisitionFlow.operations; // SETEO LAS OPERACIONES DEL CURRENT FLOW
            // ITERO TODAS LAS OPERATIONES DISPONIBLES

            acquisitionOperations.items.forEach(operation => {

            let options: OperationAcquisitionOption[] = [];
            var inFlow: boolean[] = [false];
            let value = null;
            let layout: any = null;
            let id = null;
            let isActive: boolean = false;
            var order: number = 0;
            let isDeleted: boolean;
            let disabledDrag: boolean = operation.name == "RenderResult" || operation.name == "Setup";
            let objectOptions = {};


            flowOperations.forEach(op => {
                if (op.operationId == operation.id) {
                    if (!op.isDeleted) {
                        inFlow = [true]; // SI ENTRA ACA, ESTA OPERACION ESTA SELECCIONADA
                    }
                    id = op.id;
                    isActive = op.isActive;
                    let isBlob = false;
                    isDeleted = op.isDeleted
                    layout = op.layout == "" || op.layout == null ? {} : JSON.parse(op.layout);
                    order = op.order;

                    if (op.options != "" && op.options != null && operation.options.length > 0) {

                        var objectOptions = JSON.parse(op.options);

                        operation.options.forEach((opOption, j) => {
                        value = objectOptions[opOption.slug] || opOption.defaultValue;
                        const option = new OperationAcquisitionOption(opOption, value, "/Upload/UploadAcquisitionControlElement", isBlob);
                        if ((option.fieldType == FieldType.FileScript && option.acquisitionFieldType == AcquisitionFieldType.FileScript) ||
                            (option.fieldType == FieldType.FileStylesheet && option.acquisitionFieldType == AcquisitionFieldType.FileStylesheet))
                        {
                            option.backupFilePath = this.pathService.getAcquisitionBackupPath(acquisitionFlow.id, op.operation.name, option.acquisitionFieldType, this.vendorId, OverrideLevel.Vendor);
                            option.path = this.pathService.getAcquisitionBasePathForScriptAndStyleSheet(acquisitionFlow.id, op.operation.name, option.acquisitionFieldType, this.vendorId, OverrideLevel.Vendor);
                            option.urlEndPoint = "/Upload/UploadAcquisitionControlElementFileScriptOrStylesheet";
                            option.fromVendor = true;
                            options.push(option);
                        }
                        else if(option.acquisitionFieldType == AcquisitionFieldType.FileImage)
                        {
                            option.path = this.pathService.getAcquisitionBasePathForImage(this.vendorId, OverrideLevel.Vendor);
                            option.backupFilePath = this.pathService.getAcquisitionFileImageBackupPath(this.vendorId, OverrideLevel.Vendor);
                            options.push(option);
                        }
                        else
                            options.push(option);
                           
                        })
                        //ASIGNO A LA OPCION DE LA OPERACION YA SELECCIONADA EL VALOR CORRESPONDIENTE DEW LA BASE DE DATOS
                    }
                }
            });

            if (!inFlow[0]) {
                operation.options.forEach((opOption) => {
                    value = opOption.defaultValue;
                    options.push(new OperationAcquisitionOption(opOption, value, "/Upload/UploadAcquisitionControlElement", false));
                })
            }

            acquisitionOperationsWithOutOptions.push(new AcquisitionFlowOperationView(
                id,
                operation.id,
                operation.bodyEnabled,
                operation.layoutEnabled,
                operation.name,
                operation.type,
                options,
                inFlow[0],
                order,
                layout,
                isActive,
                disabledDrag,
                false,
                false,
                undefined,
                JSON.stringify(objectOptions),
                null,
                isDeleted
            ))
            objectOptions = {};
            // CREO  EL ELEMENTO AcquisitionFlowOperationView QUE ES EL TIPO DE DATO QUE SE MUESTRA TANTO EN EL SELECT COMO EN LA LISTA DE 
            // OPERACIONES SELECCIONADAS
        });
        var inFlowOperations = acquisitionOperationsWithOutOptions.filter(op => {
            return op.inFlow;
        })

        let promises = [];
        let names = [];


        acquisitionFlow.operations.forEach(aop => {

            if (aop.operation.name == "RenderUserIdForm") {
                let parse1 = aop.options != null ? JSON.parse(aop.options) : aop.options;

                let layout = this.addFilesOptionsPathLayout(aop.layout);

                names.push(aop.operation.name);

                promises.push(this.buildHTMLpreview({ form: parse1, layout: layout, operationName: aop.operation.name, isBlob: false, operation: undefined }).toPromise())
            }
        })

        inFlowOperations.forEach(operation => {
            if (operation.name == "RenderResult") {
                operation.renderResultCasesAcq = operation.renderResultCasesAcq.sort((a, b) => this.resultCaseDefinedAtributes(b)-this.resultCaseDefinedAtributes(a))
                let objectOptions = {};
                operation.options.forEach(option => {
                    objectOptions[option.slug] = option.value;
                })

                let form = this.buildFormAndLAyoutPreviewForRenderResult(operation, objectOptions);                    
                
                promises.push(this.buildHTMLpreview({ form: form, layout: {}, operationName: operation.name, isBlob: false, operation: undefined }).toPromise())
            }
        })

        let previewResolveds = await Promise.all(promises);

        for (let i = 0; i < previewResolveds.length; i++) {
            const preview = previewResolveds[i];
            for (let j = 0; j < inFlowOperations.length; j++) {
                let operation = inFlowOperations[j];
                if (names[i] == operation.name) {
                    inFlowOperations[j].previewRender = preview;
                }
            }
        }

        this.order = inFlowOperations.length;
        inFlowOperations = this.orderInFlowOperation(inFlowOperations);

        return { flow: acquisitionFlow, operations: acquisitionOperationsWithOutOptions, inFlowOperations: inFlowOperations }
        } catch (error) {
            // return error;
            this.loading = false
        }

    }

    resetOrderOperationsOrder(): void {

        this.inFlowOperations.forEach((operation, i) => {
            operation.order = i;
        })
    }

    change: boolean = false;
    test(operation: AcquisitionFlowOperationView) {
        operation.externalSave = true;
    }

    orderInFlowOperation(array): any[] {
        return array.sort((a, b) => { return a.order - b.order })
    }

    setRenderResultform(operationFormInfo: IFormAndLayout) {
        let form = this.buildFormAndLAyoutPreviewForRenderResult(operationFormInfo.operation, operationFormInfo.form);
        operationFormInfo.form = form;
        this.buildHTMLpreview(operationFormInfo).subscribe(htmlContent => {
            this.setIframeReady(this.iframe, htmlContent.previewContent);
            if (operationFormInfo.operation) {
                operationFormInfo.operation.previewRender = htmlContent;
            }
        })
    }

    onChangeRenderCase(operationFormInfo) {

        this.setRenderResultform(operationFormInfo);
    }

    selectOperation(event: MatSelectChange): void {
        event.value.inFlow = true;
        // ACA LE AGREGO EL ORDERN A LA OPERACION RECIEN AGREGADA


        let lastPost = this.inFlowOperations.length > 0 ? this.inFlowOperations.length - 1 : 0;
        this.acquisitionOperations.map(op => {
            if (event.value.operationId == op.operationId) {
                op.inFlow = true;
                op.order = this.order;
                op.isDirty = true;
                if (event.value.name == "Setup") {
                    this.inFlowOperations.unshift(op);
                } else {
                    if (lastPost > 0 && this.inFlowOperations[lastPost].name == "RenderResult") {
                        this.inFlowOperations.splice(lastPost, 0, op);
                    } else {
                        this.inFlowOperations.push(op);
                    }
                }
            }

        });
        this.order++;

        this.resetOrderOperationsOrder();
        // ACA LE AGREGO EL ORDERN A LA OPERACION RECIEN AGREGADA

        this.selectedOperation = "default";
    }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        return new Observable((observer) => {
            if (this.dirtyForm) {
                this.message.confirm(this.l("UnsavedFlowChangingRoute"), '', confirm => {
                    observer.next(confirm)
                })
            } else {
                observer.next(true);
            }
        })
    }

    onSavedForm($event: boolean) {
        this.step = -1;
    }

    removeOperationFromList(operation: AcquisitionFlowOperationView, index: number): void {
        this.inFlowOperations.splice(index, 1)
        this.acquisitionOperations.map(op => {
            if (operation.operationId == op.operationId) {
                op.inFlow = false;
                op.order = 0;
            }
        });
        this.order--;
    }


    closeExpansionPanel(operation): void {
        this.showIframe = false;
        this.step = -1;
    }


    getFormValues(operationFormInfo: IFormAndLayout) {
        operationFormInfo.layout = this.addFilesOptionsPathLayout(operationFormInfo.layout);

        if (operationFormInfo.operationName == "RenderResult") {
            let form = this.buildFormAndLAyoutPreviewForRenderResult(operationFormInfo.operation, operationFormInfo.form);
            let index = this.inFlowOperations.findIndex(i => i.name == "RenderResult")
            this.inFlowOperations[index].renderResultCasesAcq = operationFormInfo.operation.renderResultCasesAcq
            operationFormInfo.form = form;
        }

        if (operationFormInfo.operation.layoutEnabled || operationFormInfo.operationName == "RenderResult") {
            this.buildHTMLpreview(operationFormInfo).subscribe(htmlContent => {
                this.setIframeReady(this.iframe, htmlContent.previewContent);
                if (operationFormInfo.operation) {
                    operationFormInfo.operation.previewRender = htmlContent;
                }
            })
        }
    }

    removeOperation(operation: AcquisitionFlowOperationView, index: number): any {

        if (operation.id != null) {
            this.message.confirm(
                '', '', async (isConfirmed) => {

                    if (isConfirmed) {
                        try {
                            await this._acquisitionFlowServiceProxy.removeOperation(operation.id).toPromise()
                            try {
                                await this.notify.success(this.l('SuccessfullyDeleted'));
                                this.removeOperationFromList(operation, index);
                                // this.showIframe = false;
                            } catch (error) {
                                this.message.error('Error');
                                return false;
                            }
                        } catch (error) {

                            console.error(error);
                        }
                    }
                }
            );
        } else {
            this.removeOperationFromList(operation, index);
        }


        this.resetOrderOperationsOrder();

    }

    previewOperationOpen: boolean = false;
    collapsedPanels: boolean = false;
    setStep(operation: AcquisitionFlowOperationView, index: number): void {
        this.collapsedPanels = operation.name.match('Render') != null;
        this.step = index == this.step ? -1 : index;
        let buildHTMLpreviewParams =
         { 
            form: JSON.parse(operation.stringOptions),
            layout: operation.layout,
            operationName: operation.name,
            isBlob: false,
            operation: undefined 
        }
        if (operation.name == "RenderResult") {
            let form = this.buildFormAndLAyoutPreviewForRenderResult(operation, JSON.parse(operation.stringOptions));
            buildHTMLpreviewParams.form = form;
        }
        let timeout = this.showIframe ? 800 : 0;
        setTimeout(() => {
            if (operation.layoutEnabled || operation.name == "RenderResult") {
                this.previewOperationOpen = true;
                if (!operation.previewRender) {
                    this.buildHTMLpreview(buildHTMLpreviewParams).subscribe(data => {
                        this.setIframeReady(this.iframe, data.previewContent);
                    })
                } else {
                    this.setIframeReady(this.iframe, operation.previewRender.previewContent);
                }
            } else {
                this.previewOperationOpen = false;
                this.showIframe = false;
            }
        }, timeout);
    }

    viewFlow() {
        this.router.navigate(['app/biz-dev/vendors/', this.vendorId, 'acquisition-flow', this.flowId])
    }

    addFilesOptionsPathLayout(layout: any): any {
        if (layout != "" && layout != null) {
            var playout = JSON.parse(layout);
            for (var i in playout) {
                if (i.match('img') && !playout[i].match('base64')) {
                    playout[i] = 'images/' + playout[i];
                }
            }
            return JSON.stringify(playout);
        }
        return layout;
    }

    addFilesOptionsPath(camelCaseString: string, element: any, isBlob: boolean, operationName: string): string {
        if (!isBlob && camelCaseString == "backgroundImage") {
            if (element != null && element != "" && !element.match('images/')) {
                element = `images/${element}`;
            }
        }

        switch (camelCaseString) {
            case 'stylesheet':
                if (element != null && element != "" && !element.match('css/') && !element.match('https://')) {
                    element = `${this.baseCDN}vendor/${this.vendorId}/${this.flowId}/${operationName}/css/${element}`;
                }
                break;
            case 'script':
                if (element != null && element != "" && !element.match('script/') && !element.match('https://')) {
                    element = `${this.baseCDN}vendor/${this.vendorId}/${this.flowId}/${operationName}/js/${element}`;
                }
                break
            default:
                break;
        }
        // 
        return element;
    }

    getPreviewHTML<T>(renderHtmlOperationName: string, formPreviewObject: any, previewDTO): Observable<AcquisitionPreviewDto> {
        let genericPreviewObject = new GenericPreviewForm<T>(previewDTO, formPreviewObject);
        if (!this._acquisitionPreviewServiceProxy[renderHtmlOperationName]) {
            this.message.error(`The method ${renderHtmlOperationName} doesnt exist in acquisitionPreviewServiceProxy`);
        } else {
            return this._acquisitionPreviewServiceProxy[renderHtmlOperationName](genericPreviewObject.formResult);
        }
    }

    buildFormAndLAyoutPreviewForRenderResult(operation: AcquisitionFlowOperationView, formValue: any): any {

        if (!operation.selectedCaseAcq && operation.renderResultCasesAcq.length > 0) {
            
            operation.renderResultCasesAcq = operation.renderResultCasesAcq
                .sort((a, b) => this.resultCaseDefinedAtributes(b) - this.resultCaseDefinedAtributes(a))
            operation.selectedCaseAcq = operation.renderResultCasesAcq[0].case
        }

        let showResultButton;
        if (formValue['result-message-' + operation.selectedCaseAcq] != null) {
            showResultButton = true;
        } else {
            showResultButton = false;
        }
        let resultButtonText = formValue['result-button-text-' + operation.selectedCaseAcq];
        let resultMessage = formValue['result-message-' + operation.selectedCaseAcq];

        let form = {};
        form = {
            'meta-description': formValue['meta-description'],
            'title': formValue['title'],
            'script': formValue['script'],
            'stylesheet': formValue['stylesheet'],
            'background-color': formValue['background-color'],
            'background-image': formValue['background-image'],
            'primary-color': formValue['primary-color'],
        }
        form['show-result-button'] = showResultButton;
        form['result-button-text'] = resultButtonText;
        form['result-message'] = resultMessage;

        return form;
    }

    buildHTMLpreview(operationFormInfo: IFormAndLayout): Observable<any> {
        let formPreviewObject = {};
        let camelCaseString;
        let genericPreview$;
        for (let i in operationFormInfo.form) {
            let element = operationFormInfo.form[i];
            camelCaseString = this.toCamelCase(i);
            element = this.addFilesOptionsPath(camelCaseString, element, operationFormInfo.isBlob, operationFormInfo.operationName);
            formPreviewObject[camelCaseString] = element;
        }
        if ( formPreviewObject['backgroundImage'] == null) {
            formPreviewObject['backgroundImage'] = `${this.getAmpBaseUrlsForEnviroment.bucket}defaults/preview_default_bg.jpg`;
        }
        else
        {
            formPreviewObject['backgroundImage'] = `${this.getAmpBaseUrlsForEnviroment.acquisition}vendor/${this.vendorId}/${formPreviewObject['backgroundImage']}`;
        }

        if (operationFormInfo.form['backgroundColor'] == null) {
            formPreviewObject['backgroundColor'] = '#fff';
        }
        if (operationFormInfo.form['primaryColor'] == null) {
            formPreviewObject['primaryColor'] = '#3d2121';
        }

        formPreviewObject["baseCDN"] = this.baseCDN.slice(0, -1);;
        formPreviewObject["serviceId"] = this.serviceId;
        formPreviewObject["layout"] = operationFormInfo.layout == null || !operationFormInfo.layout ? {} : operationFormInfo.layout;

        switch (operationFormInfo.operationName) {
            case "RenderUserIdForm":
                genericPreview$ = this.getPreviewHTML<PreviewAcquisitionRenderUserIdFormDto>("renderUserIdForm", formPreviewObject, PreviewAcquisitionRenderUserIdFormDto);
                break;
            case "RenderOTPForm":
                genericPreview$ = this.getPreviewHTML<PreviewAcquisitionRenderOTPFormDto>("renderOTPForm", formPreviewObject, PreviewAcquisitionRenderOTPFormDto);
                break;
            case "RenderStartOptIn":
                genericPreview$ = this.getPreviewHTML<PreviewAcquisitionStartOptInDto>("renderStartOptIn", formPreviewObject, PreviewAcquisitionStartOptInDto);
                break;
            case "RenderConfirmOptIn":
                genericPreview$ = this.getPreviewHTML<PreviewAcquisitionConfirmOptInDto>("renderConfirmOptIn", formPreviewObject, PreviewAcquisitionConfirmOptInDto);
                break;
            case "RenderExtraOptIn":
                genericPreview$ = this.getPreviewHTML<PreviewAcquisitionConfirmOptInDto>("renderExtraOptIn", formPreviewObject, PreviewAcquisitionConfirmOptInDto);
                break;
            case "RenderResult":
                genericPreview$ = this.getPreviewHTML<PreviewAcquisitionRenderResultDto>("renderResult", formPreviewObject, PreviewAcquisitionRenderResultDto);
                break;
            case "RenderTerms":
                genericPreview$ = this.getPreviewHTML<PreviewAcquisitionTermsDto>("renderTerms", formPreviewObject, PreviewAcquisitionTermsDto);
                break;

            default:
                break;
        }
        // return of(null);
        return genericPreview$;
    }

    private setIframeReady(iframe: ElementRef, data): void {

        const win: Window = iframe.nativeElement.contentWindow;
        const doc: Document = win.document;
        doc.open();
        doc.write(data);
        doc.close();
    }

    toCamelCase(dashCaseString: string): string {
        return dashCaseString.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
    }

    getDirtyForm(dirtyForm) {
        this.dirtyForm = dirtyForm;
    }

    drop(event: CdkDragDrop<string[]>) {
        if (!this.validatePermissionAction('Pages.BizDev.Flow.Tenant.Vendor.Reorder')) return false;
        let allOperationsSaved: boolean = true;
        this.inFlowOperations.forEach(operation => {
            if (operation.isDirty) {
                allOperationsSaved = false;
            }
        })

        if (allOperationsSaved) {

            if (event.currentIndex != 0 && event.currentIndex != this.inFlowOperations.length - 1) {

                this.message.confirm(this.l("ChangeOperationsOrder"), '', confirm => {
                    if (confirm) {
                        moveItemInArray(this.inFlowOperations, event.previousIndex, event.currentIndex);
                        this.resetOrderOperationsOrder();
                        this.acquisitionOperations = this.orderInFlowOperation(this.acquisitionOperations);
                        let orders = this.inFlowOperations.map(operation => {
                            return operation.operationId;
                        })

                        let flowOrderInput = new SetAcquisitionFlowOperationsOrderInput({
                            flowId: this.flowId,
                            acquisitionFlowOperationIds: orders
                        })
                        this._acquisitionFlowServiceProxy.setFlowOrder(flowOrderInput).subscribe(
                            data => {
                                this.notify.success(this.l('FlowOrderUpdatedSuccess'))
                            },
                            error => {
                                this.notify.error(this.l('FlowOrderUpdatedFail'))
                                console.log(error);
                            }
                        )
                    }
                })
            } else {
                this.resetOrderOperationsOrder();
                if (event.currentIndex == 0) {
                    this.notify.warn(this.l('OrderSetupOperationNotAllowed'))
                } else {
                    this.notify.warn(this.l('OrderRenderResultOperationNotAllowed'))
                }
            }

        } else {
            this.message.warn('SaveAllOperationsBeforeOrder');
        }

    }

    async saveOperations(promisedArray: Promise<number>[]): Promise<boolean> {
        try {
            await Promise.all(promisedArray);
            return true;
        } catch (err) {
            console.log(err);
            this.message.error("An error updating operations");
            return false;
        }

    }

    changeStep(operationId) {

        this.step === operationId;

    }

    add(event: MatChipInputEvent): void {

        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            const styles = this.acquisitionFlowForm.get('gtmContainers') as FormArray;
            styles.push(this.formBuilder.control(value.trim()));
            this.dirtyForm = true;
        }

        if (input) {
            input.value = '';
        }
    }

    remove(index): void {
        const styles = this.acquisitionFlowForm.get('gtmContainers') as FormArray;

        if (index >= 0) {
            styles.removeAt(index);
            this.dirtyForm = true;
        }
    }

    resultCaseDefinedAtributes(resultCase: any){
        var result = 0;
        resultCase.form.forEach(element =>{
            if (element.name != "show-button" && element.value){
                result += 1;
            }
        })
        return result;
    }
}

interface IAcquisitionForm {
    name: string;
    description: string;
    hostBaseUrl: string;
    antiClickHijacking: boolean;
    gtmContainers: string[]
}



@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

    transform(value: any[], order: any = '', column: string = ''): any[] {
        if (!value || order === '' || !order) { return value; } // no array
        if (!column || column === '') { return _.sortBy(value); } // sort 1d array
        if (value.length <= 1) { return value; } // array with only one item
        return _.orderBy(value, [column], [order]);
    }

}



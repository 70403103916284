import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DynamicEntityPropertyValueServiceProxy, GetAllDynamicEntityPropertyValuesOutputItem, InsertOrUpdateAllValuesInput, InsertOrUpdateAllValuesInputItem } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';

@Component({
    selector: 'amp-dynamic-properties-form',
    templateUrl: './amp-dynamic-properties-form.component.html',
    styleUrls: ['./amp-dynamic-properties-form.component.css'],
})
export class AmpDynamicPropertiesFormComponent implements OnInit {
    
    @Input() entityFullName: string = ''
    @Input() entityId = 0;

    dynamicPropertiesValues: GetAllDynamicEntityPropertyValuesOutputItem[] = [];
    loading = true;
    dynForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        private _dynamicEntityPropertyValueServiceProxy: DynamicEntityPropertyValueServiceProxy ) {
        this.dynForm = this.fb.group({});
    }

    ngOnInit() {
    }
 
    getDynamicPropertyValue(property: GetAllDynamicEntityPropertyValuesOutputItem): any {
        let value: any = property.selectedValues[0];
        switch (property.inputType.name) {
            case 'CHECKBOX':
                value = !property.selectedValues.length ? false : property.selectedValues[0] == 'true' || false;
                break;
            case 'MULTISELECTCOMBOBOX':
                value = !!property.selectedValues.length ? property.selectedValues : [];
                break;
            default:
                break;
        }
        return value;
    }

    setDynamicPropertiesForm(entityId: string) {
        this._dynamicEntityPropertyValueServiceProxy
            .getAllDynamicEntityPropertyValues(this.entityFullName, entityId || '0')
            .pipe(take(1))
            .subscribe((dynamicPropertyOutput) => {
                this.dynamicPropertiesValues = dynamicPropertyOutput.items;
                this.dynamicPropertiesValues.forEach((element) => {
                    let control = new FormControl(this.getDynamicPropertyValue(element));
                    this.dynForm.addControl(element.propertyName, control );
                });
            });
    }

    getDynamicPropertiesInput(): InsertOrUpdateAllValuesInput {
        let dPropertiesInput: InsertOrUpdateAllValuesInput = new InsertOrUpdateAllValuesInput();
        dPropertiesInput.items = [];
        _.forEach(this.dynForm.value, (value, key) => {
            if ((value || value == '') && key != 'provisionerPlatformName' && key != 'name') {
                let itemInput = new InsertOrUpdateAllValuesInputItem();
                let dp = _.find(this.dynamicPropertiesValues, (dpv) => dpv.propertyName == key);
                itemInput.dynamicEntityPropertyId = dp.dynamicEntityPropertyId;
                if (typeof value === 'object') {
                    itemInput.values = value;
                } else {
                    itemInput.values = [value];
                }
                dPropertiesInput.items.push(itemInput);
            }
        });
        return dPropertiesInput;
    }
}


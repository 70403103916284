import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable, Inject, Optional } from '@angular/core';
import {catchError} from 'rxjs/operators'; 
import { finalize } from 'rxjs/operators';

@Injectable()
export class TableDataSource  implements DataSource<any> {
    
    protected entitySubject = new BehaviorSubject<any[]>([]);
    protected loadingSubject = new BehaviorSubject<boolean>(false);
    protected totalCountSubject = new BehaviorSubject<number>(null);
    public loading$ = this.loadingSubject.asObservable();
    public totalCount$ = this.totalCountSubject.asObservable();

    NameFilter: string = '';
    filterText: string = '';
    getEntity:any;
    functionName:string;
    
    constructor(
        @Inject('proxy') @Optional() public proxy?: any,
        @Inject('entity') @Optional() public entity?: any,
    ) {
        this.proxy = proxy;
        this.getEntity = entity;
        this.functionName = this.getFunctionProxyName(this.entity)
        console.log(this.getEntity);
    }

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.entitySubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.entitySubject.complete();
        this.loadingSubject.complete();
    }

    capitalize = (string) => {
        if (typeof string !== 'string') return ''
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
      

    getFunctionProxyName(entity) {

        return `get${this.capitalize(entity)}`;
    }

    getData(NameFilter:string,filterText:string,sorting:string,skipCount:number,maxResultCount:number) {
        this.loadingSubject.next(true);
   
        this.proxy.getEntities(NameFilter,
            filterText,
            sorting,
            skipCount,
            maxResultCount).pipe(
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(data => {
            this.totalCountSubject.next(data.totalCount);
            this.entitySubject.next(data.items);
        });
    }    
}

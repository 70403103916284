import { Component, EventEmitter, Injector, Output, ViewChild, Input, SimpleChanges, OnInit, Directive } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NameValueDto, ContactDto, EntityContactDto, PagedResultDtoOfEntityContactDto, EntityContactsServiceProxy, ContactType } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table/'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

export interface ICommonEntityContactModalOptions {
    title?: string;
    isFilterEnabled?: boolean;
    dataSource: (skipCount: number, maxResultCount: number, filter: string) => Observable<PagedResultDtoOfEntityContactDto>;
    canSelect?: (item: NameValueDto) => boolean | Observable<boolean>;
    loadOnStartup?: boolean;
    pageSize?: number;
}


//For more modal options http://valor-software.com/ngx-bootstrap/#/modals#modal-directive

@Component({
    selector: 'commonEntityContactModal',
    templateUrl: './common-entity-contact-modal.component.html',
    animations: [appModuleAnimation()],
    styleUrls: ['./common-entity-contact-modal.component.css']
})
export class entityContactComponent extends AppComponentBase implements OnInit {

    @Input('entity-id') entityId;
    @Input('entity-name') entityName;
    @Input('') contacts: string;
    @Input('action-type') actionType: string;
    @Input('string-contact') stringContact: string;
    @Input() proxy: any;
    static defaultOptions: ICommonEntityContactModalOptions = {
        dataSource: undefined,
        canSelect: () => true,
        loadOnStartup: true,
        isFilterEnabled: true,
        pageSize: AppConsts.grid.defaultPageSize
    };

    @Output() itemSelected: EventEmitter<ContactDto> = new EventEmitter<ContactDto>();

    @ViewChild('modal', {static:true}) modal: ModalDirective;

    options: ICommonEntityContactModalOptions = _.merge({});

    isShown = false;
    isInitialized = false;
    filterText = '';

    id: number;
    item: ContactDto = new ContactDto();
    contact: ContactDto = new ContactDto();
    contactName: string;
    contactType: ContactType;
    selected;
    entityContactDto: EntityContactDto;

    displayedColumns: string[] = ['actions', 'name', 'position', 'email', 'type', 'workPhone', 'personalPhone', 'skypeId', 'linkedInProfile'];
    // columnsToDisplay: string[] = this.displayedColumns.slice();
    data: ContactDto[] = [];

    dataSource = new MatTableDataSource(this.data);
    contactForm: FormGroup;
    submitted: boolean = false;

    constructor(
        injector: Injector,
        private entityContactsAppService: EntityContactsServiceProxy,
        private formBuilder: FormBuilder,
        public router: Router
    ) {
        super(injector);
    }

    ngOnInit() {
        this.setContactForm();
    }

    get form() { return this.contactForm.controls; }

    close(): void {
        this.clearForm();
        this.modal.hide();
    }

    setContactForm() {
       
        if (this.stringContact) {
            this.contact =  Object.assign({}, JSON.parse(this.stringContact));
        }
        
        this.contactName = this.contact.name;
        this.id = this.contact.id || null;
        let name = this.contact.name || '';
        let email = this.contact.email || '';
        let position = this.contact.position || '';
        let workPhone = this.contact.workPhone || '';
        let personalPhone = this.contact.personalPhone || '';
        let skypeId = this.contact.skypeId || '';
        let linkedInProfile = this.contact.linkedInProfile || '';
        let type = this.contact.type ? String(this.contact.type) : '0'; 
        // this.selected = type;
        

        this.contactForm = this.formBuilder.group({
            'name': [name, Validators.required],
            'email': [email, Validators.email],
            'position': [position],
            'workPhone': [workPhone],
            'personalPhone': [personalPhone],
            'skypeId': [skypeId],
            'linkedInProfile': [linkedInProfile],
            'type': [type],
        });
        // new FormControl({value: 'Nancy', disabled: true}
    }

    show(): void {
        if (!this.options) {
            throw Error('Should call CommonEntityModalContactComponent.configure once before CommonEntityContactModalComponent.show!');
        }

        if (this.actionType == 'view') {
            this.contactForm.get('type').disable();
        }
        this.modal.show();
    }

    

    save() {

        this.submitted = true;
        if (!this.contactForm.valid) {
            return false;
        };

        if (this.actionType == 'edit') {
            this.entityContactDto.contact.id = this.id;
            this.entityContactDto.contactId = this.id;
        };
        // this.entityContactDto.aggregatorId = this.entityId;
        this.entityContactDto[`${this.entityName}Id`] = this.entityId;
        this.entityContactDto.contact.name = this.contactForm.value.name;
        this.entityContactDto.contact.email = this.contactForm.value.email;
        this.entityContactDto.contact.workPhone = this.contactForm.value.workPhone;
        this.entityContactDto.contact.skypeId = this.contactForm.value.skypeId;
        this.entityContactDto.contact.linkedInProfile = this.contactForm.value.linkedInProfile;
        this.entityContactDto.contact.position = this.contactForm.value.position;
        this.entityContactDto.contact.personalPhone = this.contactForm.value.personalPhone;
        this.entityContactDto.contact.type =  parseInt(this.contactForm.value.type);
        this.entityContactsAppService.createContact(this.entityContactDto)
            .pipe(finalize(() => { }))
            .subscribe((result) => {
                if (result == null) {
                    this.message.error(this.l('InvalidForm'));
                } else {
                    this.notify.success(this.l('SavedSuccessfully'));
                    this.itemSelected.emit(result.contact);
                    this.close();
                   
                }
            });
    }

    

    clearForm() {

        var formValues = this.contactForm.value;
        let value;

        for (let i in formValues) {
            if (this.actionType == 'create') {
                value = '' ;
                this.contactName = '';
                if (i == 'type'){
                    value  = String(0);
                    this.selected = value;
                }
            }

            if (this.actionType == 'edit' || this.actionType == 'view') {
                value =  !this.submitted ? this.contact[i] : formValues[i];
                if (i == 'type'){
                    value  = String(value);
                }
                
                if (this.submitted) {
                    this.contact = formValues;
                }
            }
            this.contactForm.get(i).setValue(value);
        }
        this.submitted = false;
    }

    changeInput($event) {
        this.contactName = $event.target.value;
    }
}

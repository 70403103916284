import { Component, Injector, ViewEncapsulation, ViewChild, OnInit, Input } from '@angular/core';

import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { Router } from "@angular/router";

@Component({
    selector:'amp-breadcumn',
    template: `
        <span [ngClass]="{'kt-subheader__title': item.type == 'title', 'kt-subheader__desc': item.type == 'sub-title'}" *ngIf="!item.url">
            <span>{{item.text}}</span>
        </span>




        <span (click)="goTo(item.url)" class="breadcrumb-pointer" [ngClass]="{'kt-subheader__title': item.type == 'title', 'kt-subheader__desc': item.type == 'sub-title'}" *ngIf="item.url">
            <span>{{item.text}}</span>
        </span>
        <span class="kt-subheader__separator kt-subheader__separator--v breadcrumb-subtitle" *ngIf="!item.lastElement"></span>
    `,
    animations: [appModuleAnimation()],
    styleUrls: ['./amp-breadcrumb.component.less']
})
export class AmpBreadcrumb implements OnInit  {

    @Input('item') item : IBreadCumb;
    // showElement: boolean = true;

    constructor(
        private location: Location,
        private router: Router
    ) {
        // [routerLink]="['/app/biz-dev/vendors/', element.vendor.id]"
        
    }

    ngOnInit(){
    }

    goTo(url) {
        this.router.navigate(url).then( (e) => {
            if (e) {
              console.log("Navigation is successful!");
            } else {
              console.log("Navigation has failed!");
            }
          });
    }
}



export interface IBreadCumb {
    type:string;
    url?:any | undefined;
    text:string;
    lastElement:boolean;
}
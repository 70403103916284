import { Component, EventEmitter, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { OperationAcquisitionOption } from "../models";

@Component({
    selector: 'input-picker',
    styleUrls: ['./input-picker.css'],
    template: `
        <div class="input-picker-element" [formGroup]="group">
        
            <div class="custom-file" lang="es">
                <label for="basic-url">{{config.name}}</label>
                <div class="input-group mb-3">
                    <input class="form-control" 
                        (colorPickerChange)="changeColor($event)" 
                        [(colorPicker)]="config.value" 
                        [formControlName]="config.slug"
                        [(cpToggle)]="toggle"
                        cpPosition="bottom"
                        cpOutputFormat="hex"
                        cpAlphaChannel="disabled"
                    />
                    <div class="color-preview" [style.background]="lastColorSelected"></div>
                </div>
            </div>
        </div>
    `
            // <div class="col-md-3 offset-md-1">
            //     <div class="color-preview" [style.background]="lastColorSelected"></div>
            // </div>
})
export class InputPickerComponent {
    config: OperationAcquisitionOption;
    group: FormGroup;
    color: string;
    toggle: boolean = false;
    // lastColorSelected:string = '';
    lastColorSelected: string = "#ffffff";

    @Output('onColorChange') colorEmitter = new EventEmitter<string>();
    constructor() { }

    ngOnInit() {
        this.setInitColor();
        
    }

    setInitColor(){
        this.lastColorSelected = this.config.value || "#ffffff";
        this.group.controls[this.config.slug].setValue(this.lastColorSelected);
    }

    isHex(string:string):Boolean {
        if (string.length == 0) {
            return false;
        }
        let stringWithOutNumeral = string.slice(1);
        let stringToHex = parseInt(stringWithOutNumeral,16);
        return (stringToHex.toString(16) === stringWithOutNumeral.toLowerCase())
    }

    changeColor(color){

        let typeOfChange = typeof color;
        if (typeOfChange == 'string') {
            this.lastColorSelected = color;
            this.config.value = color;
            this.group.controls[this.config.slug].setValue(color);
        }  else {
            if (!this.isHex(color.target.value)) {
                this.config.value = this.lastColorSelected;
            } else {
                this.config.value = color;
           }
            this.group.controls[this.config.slug].setValue(this.lastColorSelected);
        }
        let valueToEmit = {
            isBodyConfig:false,
            isText : true, 
            input : {
                name:this.config.slug,
                value: this.group.controls[this.config.slug].value
            }
        }
        this.toggle = false;
        this.colorEmitter.emit(valueToEmit.input.value);
    }
}
import { Component, Input, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';

export declare type Size = 'small' | 'medium' | 'large';
const DIAMETER_SMALL = 25;
const DIAMETER_MEDIUM = 50;
const DIAMETER_LARGE = 80;

@Component({
  selector: 'amp-spinner',
  templateUrl: './amp-spinner.component.html',
  styleUrls: ['./amp-spinner.component.css']
})
export class AmpSpinnerComponent implements OnInit {

  /**
   * Text to display under the spinner
   */
  @Input() text: string;

  /**
   * If true, the spinner will be displayed in the center of the screen 
   * and will be on top of all other elements (using z-axis)
   */
  @Input() global: boolean;

  @Input() size: Size = 'medium';

  /**
   * If has value, no matter {@link size}, the spinner will be displayed with the given size in pixels
   */
  @Input() customSize: number;

  /**
   * If has value, the spinner will be displayed for the given time in milliseconds
   */
  @Input() debounceTime: number;

  /**
   * If has value, it is the text to display after the {@link debounceTime} has passed
   */
  @Input() latenessText: string;

  /**
   * The diameter of the spinner in pixels
   */
  diameter: number;

  @Input()
  /**
   * If true, the spinner will have a minimum height of 100px.
   * Usefull when spinner must have a min height when it is 
   * displayed in a container with a small height
   */
  useMinHeight: boolean = true;

  timerSub: Subscription;

  constructor() { }

  ngOnInit() {
    this.diameter =  !!this.customSize ? this.customSize : this.getDiameter(this.size);
    if(!!this.debounceTime && !!this.latenessText){
      this.initLatenessTimer(this.debounceTime, this.latenessText);
    }
  }
  
  /**
   * Init a timer that will display the {@link latenessText} after the {@link debounceTime} has passed
   * @param debounceTime time in milliseconds
   * @param latenessText text to display after the debounceTime has passed
   */
  private initLatenessTimer(debounceTime: number, latenessText: string) {
    const timer$ = timer(debounceTime);
    this.timerSub = timer$.subscribe(() => {
      this.text = latenessText;  
    })
      
  }

  /**
   * Set diameter to spinner according to the given size
   * @param size 
   * @returns spinner diameter in pixels
   */
  private getDiameter(size: Size): number {
    let diameter: number;

    switch (size.toLowerCase()) {
      case "small":
        diameter = DIAMETER_SMALL;
        break;
      case "medium":
        diameter = DIAMETER_MEDIUM;
        break;
      case "large":
        diameter = DIAMETER_LARGE;
        break;
      default:
        diameter = DIAMETER_MEDIUM;
        break;
    }
    
    return diameter;
  }

  ngOnDestroy(): void {
    this.timerSub?.unsubscribe();
  }

}

import { Component, forwardRef, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from "@angular/core";
import { NG_VALUE_ACCESSOR, FormControl } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { NameValueDto } from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'amp-autocomplete-license',
    templateUrl: './amp-autocomplete-license.component.html',
    styleUrls: ['./amp-autocomplete-license.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AmpAutocompleteLicenseComponent),
            multi: true,
        },
    ],
})

export class AmpAutocompleteLicenseComponent implements OnInit, OnChanges {

    constructor() {}

    isLoading = false;
    isSearch = false;
    noResults = false;

    @Input() label: string;
    @Input() idFormatter: Function;
    @Input() public dataSource: NameValueDto[];

    public optionsList: string[] = [];

    public chipsFormControl: FormControl = new FormControl();
    public inputFormControl: FormControl = new FormControl();

    @Output() public selectionChipMadeEmit = new EventEmitter<NameValueDto>();
    @Output() public inputStringMadeEmit = new EventEmitter<string>();

    @ViewChild('chipInputText', {static: false}) chipInputText: ElementRef<HTMLInputElement>;

    ngOnInit() {
        this.changeInputDetection();
        this.changeChipsSelection();
    }

    ngOnChanges( changes: SimpleChanges ): void {
        if ( changes.dataSource?.previousValue === undefined ) return; // Indica que el componente se inicializó
        if ( changes.dataSource?.currentValue?.length ) this.noResults = false;
        else this.noResults = true;
    }

    private changeInputDetection(): void {
        this.inputFormControl.valueChanges.subscribe( r  =>{
            this.isSearch = false;
            if ( this.optionsList.length || r.name ) return
            this.searchByInputString( r );
        });
    }

    private changeChipsSelection(): void {
        this.chipsFormControl.valueChanges.subscribe( ( selected ) => {
            if ( !selected ) this.selectionChipMadeEmit.emit( null );
            if ( selected || selected.length ) {
                this.selectionChipMadeEmit.emit( selected[0] );
                this.inputFormControl.disable();
            }
            if ( selected.name ) this.selectionChipMadeEmit.emit( selected );
        });
    }

    public searchByInputString( key: string ): void {
        this.inputStringMadeEmit.emit(key)
    }

    public autocompleteSelected(selectedValue: MatAutocompleteSelectedEvent) {
        this.optionsList = [selectedValue.option.value.name];
        this.chipsFormControl.setValue([selectedValue.option.value]);
        this.clearInput();
    }

    public clearInput(){
        this.inputFormControl.setValue('');
        this.chipInputText.nativeElement.value = '';
    }

    public clearChips(){
        this.inputFormControl.enable();
        this.chipsFormControl.setValue('');
        this.optionsList = [];
    }

    public closedAutocompletePanel(){
        this.clearInput();
    }
}

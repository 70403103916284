import { Component, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog/';


import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { LayoutElementType } from './layout-builder/layout-builder.component';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { TokenService } from 'abp-ng2-module';
import { AppConsts } from "@shared/AppConsts";

import { AbstractControl } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ICommonOptions } from './acquisition-flow/models-basic';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { LayoutElementStyleServiceProxy, LayoutElementType as ElementType } from '@shared/service-proxies/service-proxies';
import { finalize, map, take } from 'rxjs/operators';

export class FormValidator {

    public existId(currentElements: LayoutElementType[], editing: boolean, index: any) {
        return (control: AbstractControl) => {
            let validation = null;
            let elementIndex = index != null ? index : -1;

            if (currentElements.length > 0) {
                for (let i = 0; i < currentElements.length; i++) {
                    let el = currentElements[i];
                    if (el.name == control.value && i != elementIndex) {
                        validation = { existId: true };
                    }
                }
            } else {
                validation = null;
            }
            return validation;
        }
    }
}


@Component({
    selector: 'awg-add-layout-config-modal',
    styleUrls: ['./layout-builder/layout-builder.component.css'],
    templateUrl: 'awg-add-layout-config-modal.html',
})
export class AddLayoutConfigModal extends AppComponentBase {

    elementsForm: FormGroup;
    addBtnText: string = "Edit";
    submitted = false;
    serviceId: number;
    baseCDN: string;
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    selectedElement = 'p';
    fileSource = '';
    selected: any;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
    availableStyles: Array<string>;

    availableStylesForImage: Array<string>;

    // showFlowOptions2:boolean = false;
    showOptionsContainer: boolean = false;
    name: string = '';
    elementsType: LayoutElementType[] = [
        { name: 'Image', type: 'img', styles: [] },
        { name: 'Text', type: 'p', styles: [] },
    ];
    srcResult: any;

    file: any;
    fileName: string = "Choose a file";
    fileType: string;
    path: string;
    currentElements: LayoutElementType[];
    styleArray;
    private _uploaderOptions: FileUploaderOptions = {};

    public uploader: FileUploader;
    textStyles: string[];
    imageStyles: string[];
    loadingStyles: boolean;

    constructor(
        public dialogRef: MatDialogRef<AddLayoutConfigModal>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private _tokenService: TokenService,
        public formValidator: FormValidator,
        injector: Injector,
        private _layoutElementService: LayoutElementStyleServiceProxy
    ) {
        super(injector);
        this.getLayoutElementStyles().then(_ => this.createForm(data));
    }


    async getLayoutElementStyles() {
        this.loadingStyles = true
        const result = await this._layoutElementService.getEntities('','', 0, 1000)
        .pipe(
            take(1),
            map(res => res.items),
            finalize(() => this.loadingStyles = false)
        ).toPromise()
        
        this.textStyles = result.filter(les => les.layoutElementType == ElementType.Text ).map(res => res.name);
        this.imageStyles = result.filter(les => les.layoutElementType == ElementType.Image ).map(res => res.name);

        this.createStylesArray();
    }

    pressSpace() {
        return false;
    }
    
    createStylesArray() {
        this.availableStyles = [...this.textStyles];
        this.availableStylesForImage = [...this.imageStyles];
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    returnForm(): FormGroup {
        return this.elementsForm;
    }
    commonFlowOptions: ICommonOptions;
    selectedType: boolean = true;

    keydown() {
        return false;
    }

    fromSelect:boolean = true;

    createForm(data): void {
        this.commonFlowOptions = data.commonFlowOptions;
        this.setOptions();
        this.serviceId = data.data.serviceId;
        this.baseCDN = data.data.baseCDN;
        if (data.data && data.data.basicFile && data.data.basicFile.match("images/")) {
            data.data.basicFile = data.data.basicFile.split('/')[1];
        }

        this.currentElements = data.currentElements;

        if (Object.keys(data.data).length === 0) {
            this.addBtnText = "Save"
            data.data = {
                name: '',
                styles: [],
                type: 'p',
                paragraphText: ''
            }
            this.disableSelect = false;
        } else {
            this.disableSelect = true;
        }
        let styles;
        if (data.data.styles && data.data.styles.length > 0) {
            styles = data.data.styles.split(' ');
            if (data.data.type == 'p') {
                this.availableStyles = this.cleanStyleArray(styles, this.availableStyles)
            } else {
                this.availableStylesForImage = this.cleanStyleArray(styles, this.availableStylesForImage)
            }
        } else {
            styles = []
        }
        let editing: boolean = this.addBtnText == "Edit"
        this.elementsForm = this.formBuilder.group({
            name: [data.data.name, [Validators.required, this.formValidator.existId(this.currentElements, editing, data.index)]],
            type: this.formBuilder.control({ value: data.data.type, disabled: this.disableSelect }, Validators.required),
            styles: this.formBuilder.array(styles),
        });
        
        if (data.data.type == 'img') {

            this.selectedElement = 'img'
            this.elementsForm.addControl('basicFile', new FormControl(data.data.basicFile, Validators.required));
            this.fileName = data.data.basicFile;
            if (data.data.fileSource) {
                this.fileSource = data.data.fileSource;
            } else {
                if (data.data.blob) {
                    this.fileSource = data.data.blob;
                } else {
                    this.getPreviewTumbnailFromDB(data.data.basicFile, data.baseCDN, data.serviceId);
                }
            }
        } else {
            this.elementsForm.addControl('paragraphText', new FormControl(data.data.paragraphText, Validators.required))
        }


        this.elementsForm.get('type').valueChanges.subscribe(value => {

            if (this.fromSelect) {
                this.selectedElement = value
                this.selectedType = true;
                if (this.selectedElement == 'img') {
                    this.elementsForm.removeControl('paragraphText');
                    this.elementsForm.addControl('basicFile', new FormControl('', Validators.required))
                    this.showImagesList = true;
                    this.showParagraphList = false;
                    this.resetStylesInput();
                    this.createStylesArray();
                } else {
                    this.showImagesList = false;
                    this.showParagraphList = true;
                    this.elementsForm.removeControl('basicFile');
                    this.elementsForm.addControl('paragraphText', new FormControl('', Validators.required))
                    this.resetStylesInput();
                    this.createStylesArray();
                }
            }
        });


    }

    resetStylesInput(): void {
        let styles = this.elementsForm.get('styles') as FormArray;
        styles.clear()
    }

    cleanStyleArray(selectedStyles: Array<string>, availableStyles: Array<string>): Array<string> {
        let notSelectedStyles: Array<string> = []
        availableStyles.forEach(style => {
            if (selectedStyles.indexOf(style) == -1) {
                notSelectedStyles.push(style)
            }
        });
        return notSelectedStyles;
    }

    commonParagraphs = [];
    commonImages = [];
    showImagesList: boolean = false;
    showParagraphList: boolean = false;
    disableSelect: boolean = false;
    ngOnInit() {
        this.setUploaderInstance();
    }

    get f() { return this.elementsForm.controls; }
    temapImgArray = [];

    setOptions() {
        for (var i in this.commonFlowOptions.layoutItems) {
            let item = this.commonFlowOptions.layoutItems[i];
            let cleanImageName;
            if (item.type == 'p') {
                this.commonParagraphs.push(this.commonFlowOptions.layoutItems[i]);
            } else {
                let currentItem = this.commonFlowOptions.layoutItems[i]
                if (currentItem.value.match('v=')) {
                    cleanImageName = currentItem.value.split('?')[0]
                    cleanImageName = cleanImageName.split('/')[1];
                } else {
                    cleanImageName = currentItem.value;
                }

                currentItem.value = cleanImageName;

                if (this.temapImgArray.indexOf(cleanImageName) == -1) {
                    let randomNum = this.getRandomInt(1, 1000000);

                    if (cleanImageName.match('images/')) {
                        cleanImageName = this.getImageName(cleanImageName);
                    }

                    let path = `${this.data.baseCDN}service/${this.data.serviceId}/images/${cleanImageName}?v=${randomNum}`;
                    this.commonFlowOptions.layoutItems[i].path = path;
                    this.temapImgArray.push(cleanImageName);
                    this.commonImages.push(this.commonFlowOptions.layoutItems[i]);
                }
            }
        }
    }

    getImageName(imagePath: string): string {
        let splitedImage = imagePath.split('/');
        let lastPos = splitedImage.length - 1;
        return splitedImage[lastPos];
    }

    showFlowOptions() {

        this.showOptionsContainer = true;
        if (this.selectedElement == "p") {
            this.showParagraphList = true;
            this.showImagesList = false;
        } else {
            this.showParagraphList = false;
            this.showImagesList = true;
        }
    }

    currentAvailableItem:number = 0;
    selectetFlowItem(item) {
        if (this.currentAvailableItem != item.id) {
            this.elementsForm.controls['name'].setValue(item.id);
    
            if (item['class']) {
                let classesArray = item['class'].split(' ');
    
                classesArray.forEach(element => {
                    this.add(element);
                });
            }
    
            if (item.type == 'p') {
                this.elementsForm.controls['paragraphText'].setValue(item.value);
            } else {
                var itemName = item.value.split('?')[0];
                this.getPreviewTumbnailFromDB(itemName, this.data.baseCDN, this.data.serviceId);
                this.elementsForm.controls.basicFile.setValue(itemName);
                this.fileName = itemName;
            }
            this.currentAvailableItem = item.id; 
        }
    }
    styles:FormArray;
    addElement(): void {
        this.fromSelect = false;
        this.elementsForm.controls['type'].enable();
        this.submitted = true;
        // this.elementsForm.controls['styles'] = this.styles;

        let data = this.elementsForm.value;
        if (data.fileSource && data.fileSource != "") {
            data.fileSource = this.fileSource;
        }
        if (data.type == 'img') {
            data.file = this.fileSource;
        }


        if (this.elementsForm.invalid) {
            return;
        }
        this.dialogRef.close({ data: data, uploader: this.uploader, form: this.elementsForm });

    }

    editorConfig: AngularEditorConfig = {
        editable: true,

        height: 'auto',
        minHeight: '100px',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            [
                'undo',
                'redo',
                'strikeThrough',
                'subscript',
                'superscript',
                'justifyLeft',
                'justifyCenter',
                'justifyRight',
                'justifyFull',
                'indent',
                'outdent',
                'insertUnorderedList',
                'insertOrderedList',
                'heading',
                'fontName'
              ],
              [
                'fontSize',
                'textColor',
                'backgroundColor',
                'customClasses',
                'insertImage',
                'insertVideo',
                'insertHorizontalRule',
                'removeFormat',
              ]
        ]
    };

    add(value: string): void {

        // const input = event.input;
        // const value = event.value;
        // let  availableStylesCopy :Array<string>;

        if ((value || '').trim()) {
            this.styles = this.elementsForm.get('styles') as FormArray;
            this.styles.push(this.formBuilder.control(value.trim()));

            if (this.selectedElement == 'img') {
                // availableStylesCopy  = Object.assign([], this.availableStylesForImage);
                let selectedStylePos = this.availableStylesForImage.indexOf(value);
                this.availableStylesForImage.splice(selectedStylePos, 1);
                // availableStylesCopy = Object.assign([], this.availableStylesForImage);
            } else {
                // availableStylesCopy  = Object.assign([], this.availableStyles);
                let selectedStylePos = this.availableStyles.indexOf(value);
                this.availableStyles.splice(selectedStylePos, 1);
                // availableStylesCopy = Object.assign([], this.availableStyles);
            }

        }

    }

    remove(index): void {

        const styles = this.elementsForm.get('styles') as FormArray;
        if (this.selectedElement == 'img') {
            this.availableStylesForImage.push(styles.value[index])
        } else {
            this.availableStyles.push(styles.value[index])
        }
        
        if (index >= 0) {
            styles.removeAt(index);
        }

    }

    onChanges($event: any) {
        this.fileName = `${$event.srcElement.files[0].name}`;
        this._uploaderOptions.itemAlias = this.fileName;
        this.uploader.setOptions(this._uploaderOptions);
        this.elementsForm.get('basicFile').setValue(`${this.fileName}`);

        this.getPreviewTumbnailFromFile($event.srcElement.files[0]).then(data => {
            this.fileSource = data.source;
        })
    }

    guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return '';
    }

    onBuildItemForm(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
                form.append('fileName', fileItem.options.itemAlias);
                form.append('serviceId', this.data.serviceId);
                form.append('type', 0);
                resolve();
            };
        })
    }

    setUploaderInstance() {

        if (this.data.flowType == "acq") {
            this.uploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + '/Upload/UploadAcquisitionControlElement' });
        } else {
            this.uploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + '/Upload/UploadAccessControlElement' });
        }
        this.onBuildItemForm();
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };
        this.uploader.setOptions(this._uploaderOptions);
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }
    getPreviewTumbnailFromDB(pathImage: string, baseCDN: string, serviceId: number) {

        let randomNum = this.getRandomInt(1, 1000000);
        if (this.data.flowType == "acq") {

            if (pathImage.match('images/')) {
                pathImage = this.getImageName(pathImage);
            }
            this.path = `${baseCDN}service/${serviceId}/images/${pathImage}?v=${randomNum}`;
        } else {
            this.path = `${baseCDN}service/${serviceId}/ac/images/${pathImage}?v=${randomNum}`;
        }
        this.fileSource = this.path;
    }

    getPreviewTumbnailFromFile(file): Promise<any> {

        return new Promise((resolve, reject) => {
            var fileReader = new FileReader();
            if (file.type.match('image')) {
                fileReader.onload = function () {
                    resolve({ source: fileReader.result, type: 'FileImage' });
                };
                fileReader.readAsDataURL(file);
            } else {
                resolve({ source: 'description', type: "File" });
            }
        })
    }
    

}


import { Component, ViewChild, Injector, Output, EventEmitter, ElementRef, Input } from '@angular/core';

import { AgencyServiceProxy, VendorDto, CampaignServiceProxy, AgencyDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ActivatedRoute, Router } from '@angular/router';

import { appModuleAnimation } from '@shared/animations/routerTransition';


@Component({
    selector: 'skeleton-table',
    templateUrl: './table.component.html',
    animations: [appModuleAnimation()],
    styleUrls: ['./table.component.less']
})
export class SkeletonTable  {

    @Input() rows:number = 4;
    @Input() colums:number = 8;
   

    constructor(
    ) {

    }

    ngOnInit() {
    }
    ngAfterViewInit() {

    }

    counter(size: number) {
        return Array.from({length: size}, (v, i) => i)
    }

}
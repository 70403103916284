import { Component,  Injector, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
	selector: 'inputplus',
	templateUrl: './inputplus.component.html',
	styleUrls: ['./inputplus.component.css']
})
export class InputPlusComponent extends AppComponentBase implements OnInit {
	@Input('control-name') controlName;
	@Input('control-placeholder') controlPlaceholder;
	@Input('formgroup-name') formGroupName;
 
	@Output() onClick: EventEmitter<number> = new EventEmitter<number>();

	type: FormControl;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit(): void {
		this.type = new FormControl('');
	}

	OnClickInputOrButton() {
		this.onClick.emit();
	}
}

import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { PermissionCheckerService } from 'abp-ng2-module';


@Directive({ selector: '[ampPermission]' })
export class AmpPermission implements OnInit {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        public permissionCheckerService: PermissionCheckerService
    ) { }

    permission: string | string[];

    // @Input() permission: string; 
    @Input() set ampPermission(permission: string | string[]) {
        this.permission = permission;
      }

    ngOnInit() {

        let show:boolean = true;
        if (Array.isArray(this.permission)) {
            show = this.permission.some(permission => this.permissionCheckerService.isGranted(permission));
        } else {
            show = this.permissionCheckerService.isGranted(this.permission);
        }
        this.toggleElementByPermission(show);
    }

    private toggleElementByPermission(show){
        if (show) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

}
import { Component, Injector, Input, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CurrencyDto, PricePointDto } from '@shared/service-proxies/service-proxies';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
    selector: 'price-points-table',
    styleUrls: ['price-points-table.component.less'],
    templateUrl: 'price-points-table.component.html',
})
export class PricePointsTable extends AppComponentBase {

    displayedColumns: string[] = ['id', 'name', 'price', 'priceWithVAT', 'vendorPricePointId'];
    pricePointsDataSource : Array<PricePointDto> = [];
    @ViewChild('pricePointsTable', { static: true }) pricePointsTable: MatTableDataSource<any>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @Input() data: Array<PricePointDto>;
    @Input() currency: CurrencyDto;
    constructor(
        injector: Injector
    ) {
        super(injector);
    }
  
}


import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FilterParams } from './filter-params.enum';




@Injectable()
export class UrlFiltersPersist  {

    private persistInUrl: boolean = true;
    private selfParams = new BehaviorSubject<Params>({});

    private params =  new BehaviorSubject<Params>({})
    private paramsSub: Subscription

    public get params$(): Observable<Params> {
        return this.params.asObservable()
    }

    constructor(
        private _activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.setParamsSubscription();
     }

    private setParamsSubscription() {
        const obs$ = this.persistInUrl ? this._activatedRoute.queryParams : this.selfParams.asObservable();
        this.paramsSub = obs$.subscribe(p => this.params.next(p));
    }

    public disablePersistInUrl(){
        this.persistInUrl = false;
        this.setParamsSubscription()
    }

    /**
     * Get a snapshot of the params, util when have to refresh 
     * the table and the params are not in the url (because dont have
     * to persist in url)
     * @returns Params from url or from selfParams
     */
    public getSnapshotParams(): Params{
        return this.persistInUrl ? this._activatedRoute.snapshot.queryParams : this.selfParams.value;
    }

    applyFilterToRoute(params:Params): void {
        this.router.navigate(
            [],
            {
                queryParams: params,
                relativeTo: this._activatedRoute,
                replaceUrl: true
            }
        );
    }

    public refreshWithCurrentParams(){
        this.params.next(this.params.value)
    }

    public addFilterParam(filter: string){
        const params = this.persistInUrl ? 
            UrlHelper.omitKeysFromObject({...this._activatedRoute.snapshot.queryParams, [FilterParams.FILTER_NAME]:filter}, [FilterParams.PAGE_INDEX_NAME, FilterParams.PAGE_SIZE_NAME])  : 
            UrlHelper.omitKeysFromObject({...this.selfParams.value, [FilterParams.FILTER_NAME]:filter}, [FilterParams.PAGE_INDEX_NAME, FilterParams.PAGE_SIZE_NAME]);

        if(this.persistInUrl){
            this.applyFilterToRoute(params)
        } else {
            this.selfParams.next(params)
        }
    }

    public addSortParam(sort: Sort){
        this.addParamWithMerge({
            [FilterParams.SORT_NAME]: `${sort.active} ${sort.direction}`
        })
    }

    public addPaginatorParam(pageEvent: PageEvent){
        this.addParamWithMerge({
            [FilterParams.PAGE_INDEX_NAME]: pageEvent.pageIndex,
            [FilterParams.PAGE_SIZE_NAME]: pageEvent.pageSize
        })   
    }

    addParamWithMerge(params: Params): void {
         if(this.persistInUrl){
             this.router.navigate(
                 [],
                 {
                     queryParams: params,
                     relativeTo: this._activatedRoute,
                     replaceUrl: true,
                     queryParamsHandling: 'merge'
                 }
             );
         }
         else {
                this.selfParams.next({
                    ...this.selfParams.value,
                    ...params
                })
         }
    }    

    /**
     * @deprecated Use amp-table and {@link removeParams} instead
     * @param params 
     * @param customFilter 
     * @returns 
     */
    clearFilterIfEmpty(params:any, customFilter?:string):any {

        let filterToClear = customFilter? customFilter : 'filterinput';

        if (params[filterToClear]) {
            delete params[filterToClear];
            this.applyFilterToRoute(params);
        }
        return params;
    }

    cleanAllParams(params){
        params = {};
        this.applyFilterToRoute(params);
        return params;
    }

    removeParams(paramNames: string[]) {
        const params =  this.persistInUrl ? this._activatedRoute.snapshot.queryParams : this.selfParams.value;
        const newParams = UrlHelper.omitKeysFromObject(params, paramNames);
        if(this.persistInUrl){
            this.applyFilterToRoute(newParams);
        } else {
            this.selfParams.next(newParams)
        }
    }

    /**
     * @deprecated loading table filters (setting data to inputs or paginator) from params needs to be done in the table component (as amp-table)
     * @param parent 
     * @param stringFilter 
     * @param sorteablesItems 
     * @returns 
     */
    loadTableFilters(parent: string, stringFilter: string | null, sorteablesItems:Array<string>): any {

        let arrayFilter: Array<string>;
        if (stringFilter == null) {
            return undefined;
        }
        let filter: any;

        arrayFilter = stringFilter.split(',');
        switch (parent) {
            case 'sort':
                // let sorteablesItems = ["id", "name", "isActive"];
                let orderItems = ["asc", "desc"];

                if (sorteablesItems.indexOf(arrayFilter[0]) == -1 || orderItems.indexOf(arrayFilter[1]) == -1) {
                    return undefined;
                }
                filter = { active: arrayFilter[0], order: arrayFilter[1] };
                break;

            case 'paginator':
                let index = parseInt(arrayFilter[0]);
                let size = parseInt(arrayFilter[1]);

                if (isNaN(index) || isNaN(size)) {
                    return undefined;
                }

                filter = { index: index, size: size }
                break;
            case 'filterInput':
                break;
            case 'lookupSeach':
                break;
            default:
                break;
        }

        return filter;
    }

    /**
     * @deprecated Use amp-table and addFilterParam instead
     * @param filter 
     * @param paramsArray 
     * @param params 
     * @returns 
     */
    setTableParams(filter: string, paramsArray: any, params:any): any {
        params[filter] = paramsArray.toString();
        //this.applyFilterToRoute(params);
        return params;
    }
    

    /**
     * @deprecated Use amp-table and removeParams instead
     * @param filter 
     * @param params 
     * @returns 
     */
    cleanParams(filter: string, params:any) :any {
        if (params[filter]) {
            delete params[filter];
        }
        return params;
    }

    ngOnDestroy(): void {
        this.paramsSub?.unsubscribe();
    }

}

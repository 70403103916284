import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { skip } from 'rxjs/operators';

@Directive({
    selector: '[clickOutside]',
})
export class AmpClickoutsideDirective {
    @Output() clickOutside: EventEmitter<void> = new EventEmitter();
    @Input('omitFirsClickOutside') omitFirsClick: boolean = false;
    
    firstClick = true;
    constructor( private _elementRef: ElementRef) {
    }

    @HostListener('document:click', ['$event','$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }

        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            if(this.omitFirsClick && this.firstClick){
                this.firstClick = false;
                return;
            }
            else {
                this.clickOutside.emit();
            }
        }
    }
 }
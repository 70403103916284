import { DatePipe } from "@angular/common";
import {
    Component,
    Injector,
    Output,
    EventEmitter,
    SimpleChanges,
    Input,
    OnInit,
    AfterViewInit,
} from "@angular/core";
import {
    FormControl,
    FormGroup,
} from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponentBase } from "@shared/common/app-component-base";
import { DateTime } from "luxon";

@Component({
    selector: "datePicker-component",
    templateUrl: "./datePicker.component.html",
    styleUrls: ["./datePicker.component.less"],
})
export class DatepickerComponent
    extends AppComponentBase
    implements OnInit, AfterViewInit {
    @Input() type: string;
    @Input() granularity: string;
    @Input() start: DateTime;
    @Input() end: DateTime;
    @Input() dateStartString: string;
    @Input() dateEndString: string;

    @Input() hasProductSelected: boolean;

    @Output() singleDateChange = new EventEmitter<{ date: string }>();
    @Output() rangeDateChange = new EventEmitter<{
        start: string;
        end: string;
    }>();
    changed: boolean = false;
    maxDate: Date;
    minDate: Date;
    startDateString: string;
    endDateString: string;
    Label: string = this.l("EnterDate");
    monthRangeLabel: string = this.l("EnterMonthRange");
    language: string = abp.localization.currentLanguage.name;
    languageDateFormat: object = {
        es: "d/M/yyyy",
        en: "M/d/yyyy",
        de: "d.M.yyyy",
        "pt-BR": "dd/MM/yyyy",
    };
    range: FormGroup;
    single: FormGroup;
    firstMonth: string;
    startingView: string = "multi-year";
    startingYear: Date = new Date(
        /*2022, 0, 1)*/ DateTime.now().toFormat(
            this.languageDateFormat[this.language]
        )
    );
    datepickerFlags = {
        firstDateSelected: false,
    };
    placeHolderDate: string = DateTime.now().toFormat("yyyy-MM");
    constructor(
        injector: Injector,
        public activateRouter: ActivatedRoute,
        public router: Router,
        public datepipe: DatePipe
    ) {
        super(injector);
        this.range = new FormGroup({
            start: new FormControl(
                DateTime.now().toFormat(this.languageDateFormat[this.language])
            ),
            end: new FormControl(
                DateTime.now().toFormat(this.languageDateFormat[this.language])
            ),
        });
        this.single = new FormGroup({
            date: new FormControl(
                DateTime.now().toFormat(this.languageDateFormat[this.language])
            ),
        });
    }

    changeDate(dateControl, datepicker: MatDatepicker<DateTime>) {
        this.singleDateChange.emit({
            date: DateTime.fromFormat(
                dateControl.value,
                this.languageDateFormat[this.language]
            ).toFormat("yyyy-MM-dd"),
        });
    }

    openDatePicker(datepicker: MatDatepicker<DateTime>) {
        if (this.datepickerFlags.firstDateSelected) {
            datepicker.open();
        }
    }

    selectedStartDate(event, datepicker: MatDatepicker<DateTime>) {
        this.changed = true;

        this.datepickerFlags.firstDateSelected = true;
        this.startingView = "year";
        this.startingYear = new Date(
            event.value.getFullYear(),
            event.value.getMonth("month")
        );
        datepicker.close();
    }

    selectedStartDateMonth(event, datepicker: MatDatepicker<DateTime>) {
        this.datepickerFlags.firstDateSelected = true;
        this.startingView = "year";
        let auxDate = DateTime.fromFormat(
            JSON.stringify(event).slice(1, 11),
            "yyyy-MM-dd"
        );
        this.startingYear = new Date(
            auxDate.get("year"),
            auxDate.get("month") - 1,
            auxDate.get("day")
        );
        this.firstMonth = JSON.stringify(event).slice(1, 8);
        datepicker.close();
    }

    changeDateRange(
        startDateControl,
        endDateControl,
        datepicker: MatDatepicker<DateTime>
    ) {
        if (!endDateControl.value) {
            datepicker.open();
        } else {
            this.datepickerFlags.firstDateSelected = false;
            this.rangeDateChange.emit({
                start: DateTime.fromFormat(
                    startDateControl.value,
                    this.languageDateFormat[this.language]
                ).toFormat("yyyy-MM-dd"),
                end: DateTime.fromFormat(
                    endDateControl.value,
                    this.languageDateFormat[this.language]
                ).toFormat("yyyy-MM-dd"),
            });
        }
    }

    setMonthAndYear(dateControl, datepicker: MatDatepicker<DateTime>) {
        this.placeHolderDate = JSON.stringify(dateControl).slice(1, 8);
        datepicker.close();
        let newDate = DateTime.fromFormat(
            JSON.stringify(dateControl).slice(1, 8),
            "yyyy-MM"
        );
        this.single.controls.date.setValue(newDate);
        this.changeDateMonth(
            JSON.stringify(dateControl).slice(1, 8),
            datepicker
        );
    }

    changeDateMonth(date, datepicker: MatDatepicker<DateTime>) {
        this.singleDateChange.emit({ date: date });
    }

    setMonthAndYearRange(event, datepicker: MatDatepicker<DateTime>) {
        if (!this.datepickerFlags.firstDateSelected) {
            this.selectedStartDateMonth(event, datepicker);
        } else {
            this.changeDateMonthRange(event, datepicker);
        }
    }

    changeDateMonthRange(event, datepicker: MatDatepicker<DateTime>) {
        this.datepickerFlags.firstDateSelected = false;
        datepicker.close();
        this.startingView = "multi-year";
        this.monthRangeLabel =
            this.firstMonth + " - " + JSON.stringify(event).slice(1, 8);
        this.rangeDateChange.emit({
            start: this.firstMonth,
            end: JSON.stringify(event).slice(1, 8),
        });
    }

    ngOnInit() {}

    ngAfterViewInit(): void {}

    ngOnChanges(change: SimpleChanges) {
        this.minDate =
            this.start != null
                ? new Date(
                      this.start.get("year"),
                      this.start.get("month") - 1,
                      this.start.get("day")
                  )
                : null;
        this.maxDate =
            this.end != null
                ? new Date(
                      this.end.get("year"),
                      this.end.get("month") - 1,
                      this.end.get("day")
                  )
                : null;

                if(this.dateStartString != null)
                {
                  this.startDateString = this.dateStartString;
                  this.startDateString = this.startDateString.replace(/-/g, '/');
                }

                if(this.dateEndString != null)
                {
                  this.endDateString = this.dateEndString;
                  this.endDateString = this.endDateString.replace(/-/g, '/');
                }

            }
}
